import React, { useContext, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { PageNumberContext } from '../PageNumberContext'
import { useParams } from 'react-router-dom'
import { Box, IconButton, Typography } from '@mui/material'
import { useEditSimulationUserMutation } from '../../../../api/simulation/simulation'
import CustomizedDialogs from '../../../../common/components/CustomDialog'
import customToastWithAlert from '../../../../common/components/CustomToastWithAlert'
import SmsWhatsappForm from '../SmsWhatsappForm'
import RefreshIcon from '@mui/icons-material/Refresh'
import ChooseTemplate from '../TemplatesForm/ChooseTemplate'
import FirstPageForm from './FirstPageForm'
import { QueryParamsTemplateContext } from '../TemplatesForm/QueryParamsTemplateContext'
import NewTemplateModal from '../TemplatesForm/NewTemplateModal'
import ExitConfirmationDialog from '../../../../common/components/ExitConfirmationDialog'

export default function EditUserSimulation({ handleClose, open, stepId, rowData = null }) {
  const [initialValues, setInitialValues] = useState({})
  const { id } = useParams()
  const [onEdit, { isError, isSuccess, isLoading, error }] = useEditSimulationUserMutation()
  const { page, setPage } = useContext(PageNumberContext)
  const [showExitConfirmation, setShowExitConfirmation] = useState(false)

  const [params, setParams] = useState({
    searchText: '',
    services: [],
    types: [],
    myTemplates: false,
    update: false,
    deletedId: null
  })
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [isSystemTemplate, setIsSystemTemplate] = useState(false)
  function paramsReset() {
    setParams({
      searchText: '',
      services: [],
      types: [],
      myTemplates: false,
      update: false,
      deletedId: null
    })
  }

  useEffect(() => {
    if (rowData) {
      setInitialValues({
        type: rowData?.simulationCampaignStepType,
        internalTemplateIds: rowData?.templateType === 'INTERNAL' ? [rowData?.templateId] : null,
        customTemplateIds: rowData?.templateType === 'CUSTOM' ? [rowData?.templateId] : null,
        metadata: {
          phoneNumber: rowData?.simulationCampaignStepMetadata?.phoneNumber,
          message: rowData?.simulationCampaignStepMetadata?.message
        },
        sendAt: rowData?.sendAt
      })
    }
  }, [rowData])

  function submitFormValues({ internalTemplateIds, customTemplateIds, ...values }) {
    const sendAtInUTC = values.sendAt ? new Date(values.sendAt).toISOString() : null
    return {
      ...values,
      sendAt: sendAtInUTC,
      templateId: internalTemplateIds?.length ? internalTemplateIds?.[0] : customTemplateIds?.[0],
      templateType: internalTemplateIds?.length ? 'INTERNAL' : customTemplateIds?.length ? 'CUSTOM' : null
    }
  }

  const onSubmit = ({ values, form }) => {
    onEdit({
      id,
      userId: rowData.userId,
      stepId: stepId,
      ...submitFormValues(values)
    })

    setParams({
      searchText: '',
      services: [],
      types: [],
      myTemplates: false
    })
    form.reset()
  }

  useEffect(() => {
    if (isSuccess) {
      setPage(1)
      customToastWithAlert({
        type: 'success',
        message: "User's step updated successfully"
      })
      handleClose()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      setPage(1)
      customToastWithAlert({
        type: 'error',
        message: isError && `${error?.data?.description}`
      })
      handleClose()
    }
  }, [isError])

  const handleCloseClick = () => {
    setShowExitConfirmation(true)
  }

  const handleCancelClose = () => {
    setShowExitConfirmation(false)
  }

  const handleConfirmClose = (values, form) => {
    setShowExitConfirmation(false)
    handleClose(values)
    setPage(1)
    setParams({
      searchText: '',
      services: [],
      types: [],
      myTemplates: false
    })
    form.reset()
  }
  return (
    <QueryParamsTemplateContext.Provider
      value={{ params, setParams, selectedTemplateId, setSelectedTemplateId, isSystemTemplate, setIsSystemTemplate }}
    >
      <Form
        onSubmit={(values, form) => onSubmit({ values, form })}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values, form, error }) => (
          <>
            <CustomizedDialogs
              isLoading={isLoading}
              headerColor={page === 'EMAIL_TEMPLATE' && '#F3F3F7'}
              denseContent={page === 'EMAIL_TEMPLATE'}
              actionDescription={
                page === 'EMAIL_TEMPLATE' && (
                  <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <Box display={'flex'} alignItems={'center'} columnGap={'8px'}>
                      <Typography fontWeight={500} fontSize={'24px'} fontFamily={'Satoshi'}>
                        {(values?.internalTemplateIds?.length || 0) + (values?.customTemplateIds?.length || 0)}
                      </Typography>
                      <Typography fontWeight={400} fontSize={'16px'} fontFamily={'Plus Jakarta Sans'}>
                        Templates Selected
                      </Typography>
                      <IconButton
                        size='small'
                        onClick={() => {
                          form.change('internalTemplateIds', [])
                          form.change('customTemplateIds', [])
                          setSelectedTemplateId(null)
                        }}
                        sx={{ ml: 1 }}
                      >
                        <RefreshIcon fontSize='small' />
                      </IconButton>
                    </Box>
                    {(values?.internalTemplateIds?.length || 0) + (values?.customTemplateIds?.length || 0) > 1 && (
                      <Typography fontWeight={400} color={'error'} fontSize={'16px'} fontFamily={'Plus Jakarta Sans'}>
                        You can select only one template
                      </Typography>
                    )}
                  </Box>
                )
              }
              actions={[
                {
                  variant: 'contained',
                  handler: () => setPage(1),
                  text: 'Back',
                  isHidden: page === 1 || page === 'NEW_EMAIL_TEMPLATE' || page === 'EMAIL_TEMPLATE'
                },
                {
                  variant: 'contained',
                  handler: () => {
                    paramsReset()
                    setPage(1)
                  },
                  text: 'Back',
                  isHidden: page !== 'EMAIL_TEMPLATE'
                },
                {
                  disabled:
                    (values.type === 'MANUAL_EMAIL' &&
                      !values.internalTemplateIds?.length &&
                      !values.customTemplateIds?.length) ||
                    ((values.type === 'SMS' || values.type === 'WHATSAPP') && !values.metadata?.phoneNumber) ||
                    (values.type === 'SLACK' && !values.metadata?.message),
                  variant: 'contained',
                  handler: handleSubmit,
                  text: 'Save',
                  isHidden:
                    page === 1 ||
                    page === 'SMS_TEMPLATE' ||
                    page === 'SLACK_TEMPLATE' ||
                    page === 'EMAIL_TEMPLATE' ||
                    page === 'NEW_EMAIL_TEMPLATE'
                },

                {
                  disabled: !values?.metadata?.phoneNumber || !values?.metadata?.message,
                  variant: 'contained',
                  handler: () => setPage(1),
                  text: 'Save',
                  isHidden: page !== 'SMS_TEMPLATE'
                },
                {
                  disabled: !values?.metadata?.message,
                  variant: 'contained',
                  handler: () => setPage(1),
                  text: 'Save',
                  isHidden: page !== 'SLACK_TEMPLATE'
                },
                {
                  disabled:
                    (!values?.internalTemplateIds?.length && !values?.customTemplateIds?.length) ||
                    (values?.internalTemplateIds?.length || 0) + (values?.customTemplateIds?.length || 0) > 1,
                  variant: 'contained',
                  handler: () => setPage(1),
                  text: 'Continue',
                  isHidden: page !== 'EMAIL_TEMPLATE'
                },
                {
                  disabled:
                    (page === 1 && !values.sendAt) ||
                    error ||
                    isLoading ||
                    (values.type === 'MANUAL_EMAIL' &&
                      !values.internalTemplateIds?.length &&
                      !values.customTemplateIds?.length) ||
                    ((values.type === 'SMS' || values.type === 'WHATSAPP') && !values.metadata?.phoneNumber) ||
                    (values.type === 'SLACK' && !values.metadata?.message),
                  variant: 'contained',
                  handler: handleSubmit,
                  text: 'Save',
                  isHidden: page !== 1
                }
              ]}
              open={open}
              handleClose={() => handleCloseClick()}
              modalWidth={page === 'EMAIL_TEMPLATE' ? '95vw' : '880px'}
              title={pageTitleMapper[page] || 'Edit'}
            >
              {page === 1 && <Field name='type' component={FirstPageForm} />}
              {page === 'SMS_TEMPLATE' && <SmsWhatsappForm hideTypeCard={true} />}
              {page === 'SLACK_TEMPLATE' && <SmsWhatsappForm hideTypeCard={true} isSlack={true} />}
              {page === 'EMAIL_TEMPLATE' && <ChooseTemplate />}
              {page === 'NEW_EMAIL_TEMPLATE' && <NewTemplateModal />}
            </CustomizedDialogs>
            {showExitConfirmation && (
              <ExitConfirmationDialog
                open={showExitConfirmation}
                onCancel={handleCancelClose}
                onConfirm={handleConfirmClose}
                values={values}
                form={form}
              />
            )}
          </>
        )}
      />
    </QueryParamsTemplateContext.Provider>
  )
}
const pageTitleMapper = {
  NEW_EMAIL_TEMPLATE: 'Create New Template'
}
