import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
//mui
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material'

import useFileUpload from 'react-use-file-upload'

//local
import DataGrid from 'src/common/components/DataGrid'
// import DeleteIcon from 'src/common/icons/deleteIcon'
import UserView from 'src/views/UserView'
import DownloadIconV2 from 'src/common/icons/downloadIconV2'

import './styles.css'
import AddUserIcon from 'src/common/icons/addUser'

import userService from 'src/services/user.service'
import authHeader from 'src/services/auth-header'
import { Link, useNavigate } from 'react-router-dom'
import DeleteDialog from 'src/common/components/DeleteDialog'

import CustomCheckbox from 'src/common/components/CustomCheckbox'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { useContext } from 'react'
import { OnboardingContext, SettingsContext, SnackBarContext } from 'src/App'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
import { ManualAddUserPopup } from './components/manualAddUserPopup'
import DownloadIcon from 'src/common/icons/downloadIcon'
import Spacer from 'src/common/components/Spacer'
import DeleteIcon from 'src/common/icons/deleteIcon'
import { columns } from './constants'
import customToastWithAlert from '../../common/components/CustomToastWithAlert'

export const sendInviteStatuses = {
  notSent: 'notSent',
  loading: 'loading',
  sent: 'sent'
}

const Actions = ({ allActive, onSelectAllActive }) => {
  return (
    <div className='row row-center'>
      <CustomCheckbox onClick={onSelectAllActive} checked={allActive} />
      <p
        style={{
          marginRight: '20px',
          color: '#7e7e7e',
          fontSize: '14px',
          fontWeight: 600
        }}
      >
        Show active only
      </p>
    </div>
  )
}
const BulkActions = ({
  idsToInvite = [],
  onActivate = () => { },
  onDeactivate = () => { },
  onDelete = () => { },
  onSendInvite = () => { }
}) => {
  const [sendingInviteStatus, setSendingInviteStatus] = useState(sendInviteStatuses.notSent)

  const handleInviteSelected = () => {
    setSendingInviteStatus(sendInviteStatuses.loading)
    userService.sendInvites(idsToInvite).then(() => {
      setSendingInviteStatus(sendInviteStatuses.sent)
      onSendInvite()
    })
  }

  return (
    <div className='row'>
      <Button
        variant={'outlined'}
        startIcon={<DeleteIcon />}
        onClick={onDelete}
        sx={{
          borderColor: '#E7E7EE',
          borderRadius: '12px',
          backgroundColor: '#fff',
          color: '#B40020',
          textTransform: 'none',
          width: 200,
          mr: '20px'
        }}
      >
        Delete selected
      </Button>
      <Button
        variant={'outlined'}
        onClick={onActivate}
        sx={{
          borderColor: '#E7E7EE',
          borderRadius: '12px',
          backgroundColor: '#fff',
          color: '#6123FF',
          textTransform: 'none',
          // width: 200,
          mr: '20px'
        }}
      >
        Activate
      </Button>
      <Button
        variant={'outlined'}
        onClick={onDeactivate}
        sx={{
          borderColor: '#E7E7EE',
          borderRadius: '12px',
          backgroundColor: '#fff',
          color: '#6123FF',
          textTransform: 'none',
          // width: 200,
          mr: '20px'
        }}
      >
        Deactivate
      </Button>
      <Button
        variant={'outlined'}
        disabled={sendingInviteStatus == sendInviteStatuses.loading || !idsToInvite.length}
        onClick={handleInviteSelected}
        sx={{
          borderColor: '#E7E7EE',
          borderRadius: '12px',
          backgroundColor: '#fff',
          color: '#6123FF',
          textTransform: 'none',
          width: 180,
          mr: '20px'
        }}
      >
        Send welcome email
      </Button>
    </div>
  )
}

const EmptyUsers = () => (
  <>
    <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M105 33.75H72.4999C71.6886 33.75 70.8991 33.4868 70.25 33L57.25 23.25C56.6009 22.7632 55.8114 22.5 55.0001 22.5H33.75C33.2575 22.5 32.7699 22.597 32.3149 22.7854C31.86 22.9739 31.4466 23.2501 31.0983 23.5983C30.7501 23.9466 30.4739 24.36 30.2854 24.8149C30.097 25.2699 30 25.7575 30 26.25V37.5H40.0001C40.8114 37.5 41.6009 37.7632 42.25 38.25L55.25 48C55.8991 48.4868 56.6886 48.75 57.4999 48.75H90C90.4925 48.75 90.9801 48.847 91.4351 49.0354C91.89 49.2239 92.3034 49.5001 92.6517 49.8483C92.9999 50.1966 93.2761 50.61 93.4646 51.0649C93.653 51.5199 93.75 52.0075 93.75 52.5V82.5H105.417C105.854 82.5 106.288 82.4138 106.692 82.2463C107.097 82.0788 107.464 81.8333 107.774 81.5237C108.083 81.2142 108.329 80.8467 108.496 80.4423C108.664 80.0379 108.75 79.6044 108.75 79.1667V37.5C108.75 37.0075 108.653 36.5199 108.465 36.0649C108.276 35.61 108 35.1966 107.652 34.8483C107.303 34.5001 106.89 34.2239 106.435 34.0354C105.98 33.847 105.492 33.75 105 33.75Z'
        fill='#E7E7EE'
      />
      <path
        d='M93.75 52.5V94.1667C93.75 95.0507 93.3988 95.8986 92.7737 96.5237C92.1486 97.1488 91.3007 97.5 90.4167 97.5H18.75C17.7554 97.5 16.8016 97.1049 16.0984 96.4017C15.3951 95.6984 15 94.7446 15 93.75V41.25C15 40.2554 15.3951 39.3016 16.0984 38.5984C16.8016 37.8951 17.7554 37.5 18.75 37.5H40C40.8114 37.5 41.6009 37.7632 42.25 38.25L55.25 48C55.8991 48.4868 56.6886 48.75 57.5 48.75H90C90.9946 48.75 91.9484 49.1451 92.6517 49.8484C93.3549 50.5516 93.75 51.5054 93.75 52.5Z'
        stroke='#E7E7EE'
        strokeWidth='5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 37.5V26.25C30 25.2554 30.3951 24.3016 31.0984 23.5984C31.8016 22.8951 32.7554 22.5 33.75 22.5H55C55.8114 22.5 56.6009 22.7632 57.25 23.25L70.25 33C70.8991 33.4868 71.6886 33.75 72.5 33.75H105C105.995 33.75 106.948 34.1451 107.652 34.8484C108.355 35.5516 108.75 36.5054 108.75 37.5V79.1667C108.75 79.6044 108.664 80.0379 108.496 80.4423C108.329 80.8467 108.083 81.2142 107.774 81.5237C107.464 81.8332 107.097 82.0787 106.692 82.2463C106.288 82.4138 105.854 82.5 105.417 82.5H93.75'
        stroke='#E7E7EE'
        strokeWidth='5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

    <p style={{ textAlign: 'center', color: '#76767F' }}>No users found</p>
  </>
)

const Team = () => {
  const navigate = useNavigate()
  const defaultFilters = {
    page: 0,
    size: 50
  }

  const anchorRef = useRef(null)

  const [totalCount, setTotalCount] = useState(null)
  const [users, setUsers] = useState([])
  const [dragOver, setDragOver] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openImportPopup, setOpenImportPopup] = useState(false)
  const [openManualImportPopup, setOpenManualImportPopup] = useState(false)
  const [openImportMenu, setOpenImportMenu] = useState(false)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showActivateDialog, setShowActivateDialog] = useState(false)
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false)

  const [selectedUserId, setSelectedUserId] = useState(null)
  const [filters, setFilters] = useState(defaultFilters)
  const [loading, setLoading] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])

  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [allActive, setAllActive] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [fileUploadingDone, setFileUploadingDone] = useState(false)
  const [fileUploadingPartial, setFileUploadingPartial] = useState(false)
  const [fileUploadingResult, setFileUploadingResult] = useState(null)
  const [fileUploadingResultFileName, setFileUploadingResultFilename] = useState(null)

  const [onboarding, updateOnboarding] = useContext(OnboardingContext)
  const { setWarningMessage } = useContext(SnackBarContext)
  const [settings] = useContext(SettingsContext)

  const handleFilter = param => {
    const newParam = param?.status
      ? { status: param.status.toUpperCase() }
      : param?.sort
        ? { sort: param.sort.replace('name', 'firstName') }
        : param
    setFilters({ ...filters, ...newParam })
  }

  const handleAllActive = () => {
    setAllActive(!allActive)
    handleFilter({ status: allActive ? '' : 'ACTIVE' })
  }

  const handleSelect = ids => {
    setSelectedIds(ids)
    // setIdsToInvite(ids)
  }

  const openElement = id => {
    setSelectedUserId(id)
    setOpenDrawer(!openDrawer)
  }
  const { files, handleDragDropEvent, setFiles, clearAllFiles } = useFileUpload()
  const inputRef = useRef()

  useEffect(() => {
    if (filters.status == 'ACTIVE') {
      !allActive && setAllActive(true)
    } else {
      allActive && setAllActive(false)
    }
  }, [filters])

  useEffect(() => {
    setLoading(true)
    userService.getUsers(filters).then(
      resp => {
        setLoading(false)
        setTotalCount(parseInt(resp.headers['x-total-count']))
        setUsers(resp.data)
      },
      err => {
        if (err.response.status == 401) {
          navigate('/login')
        }
      }
    )
  }, [])

  useEffect(() => {
    setLoading(true)
    userService.getUsers(filters).then(resp => {
      setLoading(false)
      setTotalCount(parseInt(resp.headers['x-total-count']))
      setUsers(resp.data)
    })
  }, [filters])

  const updateUsers = () => {
    userService.getUsers(filters).then(data => {
      setTotalCount(parseInt(data.headers['x-total-count']))
      setUsers(data.data)
    })
  }

  useEffect(() => {
    if (files.length) {
      const formData = new FormData()
      formData.append('csvUpload', files[0])
      setErrorMessage(null)
      try {
        setFileUploading(true)
        axios
          .post(window._env_.REACT_APP_API_HOST + '/v1/users/import', formData, {
            'content-type': 'multipart/form-data',
            headers: authHeader(),
            responseType: 'blob',
          })
          .then(resp => {
            setFileUploading(false)
            clearAllFiles()

            if (resp.status == 200) {
              setSuccessMessage('All users imported successfully.')
              if (!onboarding.done) {
                updateOnboarding({
                  ...onboarding,
                  showImport: false,
                  importUsersDone: true
                })
              }
              updateUsers()
              setFileUploadingDone(true)
            }
            if (resp.status == 206) {
              setWarningMessage('Partial import completed. Some users might not have been imported successfully. ')
              setFileUploadingPartial(true)
              setFileUploadingResult(resp.data)
              setFileUploadingResultFilename(resp.headers['content-disposition'].match(/filename="([^"]+)"/)[1])
            }
          })
          .catch(async (error) => {
            clearAllFiles()
            const text = await error?.response?.data.text();
            const errorData = JSON.parse(text);
            setErrorMessage(errorData.description || 'Something went wrong')
            setFileUploading(false)
          })
      } catch (error) {
        console.error('Failed to submit files.')
      }
    }
  }, [files])

  useEffect(() => {
    if (successMessage || errorMessage) {
      customToastWithAlert({
        message: successMessage || errorMessage,
        type: successMessage ? 'success' : 'error'
      })
    }
  }, [successMessage, errorMessage])

  const onSendInvite = () => {
    setSuccessMessage('Welcome emails were successfully sent!')
    setSelectedIds([])
  }

  const handleDelete = () => {
    userService.deleteMany(selectedIds).then(
      () => {
        setShowDeleteDialog(false)
        setTimeout(() => {
          setSuccessMessage('User successfully deleted')
          updateUsers()
        }, 1000)
        setSelectedIds([])
      },
      err => {
        console.error(err.message)
        setShowDeleteDialog(false)
        setErrorMessage('')
      }
    )
  }

  const activateSelected = () => {
    userService.bulkActivate(selectedIds).then(
      () => {
        setShowActivateDialog(false)
        setTimeout(() => {
          setSuccessMessage('Users successfully activated')
          updateUsers()
        }, 1000)
        setSelectedIds([])
      },
      err => {
        console.error(err.message)
        setShowActivateDialog(false)
        setErrorMessage('')
      }
    )
  }

  const deactivateSelected = () => {
    userService.bulkDeactivate(selectedIds).then(
      () => {
        setShowDeactivateDialog(false)
        setTimeout(() => {
          setSuccessMessage('Users successfully deactivated')
          updateUsers()
        }, 1000)
        setSelectedIds([])
      },
      err => {
        console.error(err.message)
        setShowDeactivateDialog(false)
        setErrorMessage('')
      }
    )
  }

  const handleDownloadDetails = () => {
    const blob = new Blob([fileUploadingResult])
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    if (link.download !== undefined) {
      link.setAttribute('href', url)
      link.setAttribute('download', fileUploadingResultFileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url);
    }
  }

  const handleDownloadImport = (type) => {
    userService.getImportExample(type).then(response => {
      const contentType = response.headers['content-type'];
      const fileExtension = contentType.includes('csv') ? 'csv' : 'xls';

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `users_import.${fileExtension}`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    }).catch(e => console.error(e))
  }

  const usersColumns = columns(settings?.countries)

  return (
    <div style={{ padding: '24px' }}>
      <div className='row row-center' style={{ marginBottom: 24 }}>
        <h1 style={{ margin: 0, fontSize: 24 }}>Employees</h1>
        <div className='spacer'></div>
        <Button
          ref={anchorRef}
          variant={'contained'}
          sx={{
            background: '#6123FF',
            color: '#fff',
            textTransform: 'none',
            width: 180,
            borderRadius: '12px'
          }}
          startIcon={<AddUserIcon />}
          onClick={() => setOpenImportMenu(true)}
        >
          Add User
        </Button>

        <Popper
          sx={{
            zIndex: 1000
          }}
          open={openImportMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpenImportMenu(false)}>
                  <MenuList sx={{ width: '180px' }} id='split-button-menu' autoFocusItem>
                    <MenuItem
                      onClick={() => {
                        setOpenImportMenu(false)
                        setOpenImportPopup(true)
                      }}
                    >
                      Import
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setOpenImportMenu(false)
                        setOpenManualImportPopup(true)
                      }}
                    >
                      Add manually
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <DataGrid
        loading={loading}
        // disableSelect={true}
        total={totalCount}
        filters={filters}
        onFilter={handleFilter}
        onOpenElement={openElement}
        bulkActions={
          <BulkActions
            idsToInvite={selectedIds}
            onDelete={() => setShowDeleteDialog(true)}
            onActivate={() => setShowActivateDialog(true)}
            onDeactivate={() => setShowDeactivateDialog(true)}
            onSendInvite={onSendInvite}
            ids={selectedIds}
          />
        }
        actions={<Actions allActive={allActive} onSelectAllActive={handleAllActive} />}
        selectedIds={selectedIds}
        onSelect={handleSelect}
        rows={users}
        columns={usersColumns}
        emptyListElement={<EmptyUsers />}
      />

      <DeleteDialog
        mainAction='Delete'
        description='Are you sure you want to delete these users? This action cannot be undone'
        onClose={() => setShowDeleteDialog(false)}
        onAction={handleDelete}
        str={selectedIds.length + (selectedIds.length == 1 ? ' User' : ' Users')}
        open={showDeleteDialog}
      />

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={showActivateDialog}
        onClose={() => setShowActivateDialog(false)}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Activate selected users
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '80px',
              lineHeight: '22px'
            }}
          ></p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={() => setShowActivateDialog(false)}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              onClick={activateSelected}
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                background: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
            >
              Activate
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={showDeactivateDialog}
        onClose={() => setShowDeactivateDialog(false)}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Deactivate selected users
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '80px',
              lineHeight: '22px'
            }}
          ></p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={() => setShowDeactivateDialog(false)}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              onClick={deactivateSelected}
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                background: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
            >
              Deactivate
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
          updateUsers()
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '1032px',
            cursor: 'pointer'
          }}
          onClick={() => setOpenDrawer(false)}
        >
          <CloseDrawer />
        </div>
        <UserView
          onDelete={() => {
            setOpenDrawer(false)
            updateUsers()
          }}
          onActivate={() => {
            updateUsers()
            setOpenDrawer(false)
          }}
          onUpdate={() => {
            updateUsers()
          }}
          userID={selectedUserId}
        />
      </Drawer>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={openImportPopup || onboarding.showImport}
        onClose={() => {
          setOpenImportPopup(false)
          setFileUploadingDone(false)
          setFileUploadingPartial(false)
          if (!onboarding.done) {
            updateOnboarding({
              ...onboarding,
              showImport: false,
              importUsersDone: true
            })
          }
        }}
      >
        <div className='row row-center' style={{ padding: '20px 20px 0px 20px' }}>
          <h2
            style={{
              fontFamily: 'Satoshi',
              fontStyle: 'normal',
              fontWeight: '900',
              fontSize: '35px',
              lineHeight: '110%',
              color: '#1B1B26'
            }}
          >
            Add Users
          </h2>
          <Spacer />
          <svg
            onClick={() => {
              setOpenImportPopup(false)
              setFileUploadingDone(false)
              setFileUploadingPartial(false)
              if (!onboarding.done) {
                updateOnboarding({
                  ...onboarding,
                  showImport: false,
                  importUsersDone: true
                })
              }
            }}
            style={{ cursor: 'pointer' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18'
              stroke='#1B1B26'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>

        <DialogContent>
          {fileUploadingDone && (
            <div>
              <p
                style={{
                  fontSize: 30,
                  marginBottom: 20,
                  fontWeight: 900
                }}
              >
                <svg
                  style={{ marginRight: '10px' }}
                  width='24'
                  height='24'
                  viewBox='0 0 112 112'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.16'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M56 112C86.9279 112 112 86.9279 112 56C112 25.0721 86.9279 0 56 0C25.0721 0 0 25.0721 0 56C0 86.9279 25.0721 112 56 112Z'
                    fill='#00BC62'
                  />
                  <g filter='url(#filter0_d_360_35055)'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M79.7744 38.82C78.648 37.4334 76.6041 37.2221 75.2205 38.351L47.5584 60.8834L36.3493 54.4157C35.1674 53.7316 33.6784 53.8707 32.6407 54.753C31.2812 55.9098 31.1129 57.9619 32.2652 59.3263L39.3125 67.6496L39.2977 67.6626L44.7821 74.1174L44.9929 74.3435C45.6218 74.9478 46.432 75.25 47.2403 75.25C48.0893 75.25 48.9346 74.9182 49.5691 74.2638L79.5895 43.1207C80.7289 41.9417 80.8084 40.0936 79.7744 38.82Z'
                      fill='url(#paint0_linear_360_35055)'
                    />
                  </g>
                  <defs>
                    <filter
                      id='filter0_d_360_35055'
                      x='31.5'
                      y='37.625'
                      width='49'
                      height='39.125'
                      filterUnits='userSpaceOnUse'
                      colorInterpolationFilters='sRGB'
                    >
                      <feFlood floodOpacity='0' result='BackgroundImageFix' />
                      <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                      />
                      <feOffset dy='1.5' />
                      <feComposite in2='hardAlpha' operator='out' />
                      <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0.0032804 0 0 0 0 0.61672 0 0 0 0 0.347725 0 0 0 1 0'
                      />
                      <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_360_35055' />
                      <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_360_35055' result='shape' />
                    </filter>
                    <linearGradient
                      id='paint0_linear_360_35055'
                      x1='74.1597'
                      y1='5.09505'
                      x2='38.0323'
                      y2='9.27992'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#00BA97' />
                      <stop offset='1' stopColor='#00BC62' />
                    </linearGradient>
                  </defs>
                </svg>
                Import completed
              </p>
              <p style={{ marginBottom: 30 }}>
                All users imported successfully. You can download the response file for details.
              </p>
              <div className='row'>
                <Button
                  onClick={handleDownloadDetails}
                  variant={'contained'}
                  sx={{
                    background: '#6123FF',
                    color: '#fff',
                    textTransform: 'none',
                    width: 180
                  }}
                  startIcon={<DownloadIcon color={'#fff'} />}
                >
                  Download details
                </Button>
                <Spacer />
              </div>
            </div>
          )}

          {fileUploadingPartial && (
            <div className='column'>
              <p
                style={{
                  fontSize: 30,
                  marginBottom: 20,
                  fontWeight: 900
                }}
              >
                <svg
                  style={{ marginRight: 10 }}
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.2'
                    d='M10.6996 3.74857L2.45179 17.9978C2.31982 18.2259 2.25022 18.4846 2.25 18.748C2.24978 19.0115 2.31895 19.2703 2.45055 19.4986C2.58215 19.7268 2.77153 19.9163 2.99964 20.0481C3.22776 20.1799 3.48656 20.2493 3.75 20.2493H20.2456C20.509 20.2493 20.7678 20.1799 20.9959 20.0481C21.224 19.9163 21.4134 19.7268 21.545 19.4986C21.6766 19.2703 21.7458 19.0115 21.7456 18.748C21.7453 18.4846 21.6757 18.2258 21.5438 17.9978L13.296 3.74857C13.1642 3.52093 12.9749 3.33193 12.7471 3.20055C12.5192 3.06916 12.2608 3 11.9978 3C11.7348 3 11.4764 3.06916 11.2485 3.20055C11.0206 3.33193 10.8313 3.52093 10.6996 3.74857Z'
                    fill='#E3A202'
                  />
                  <path d='M12 10.5V13.5' stroke='#E3A202' strokeLinecap='round' strokeLinejoin='round' />
                  <path
                    d='M10.6996 3.74857L2.45179 17.9978C2.31982 18.2258 2.25022 18.4846 2.25 18.748C2.24978 19.0115 2.31895 19.2703 2.45055 19.4986C2.58215 19.7268 2.77153 19.9163 2.99964 20.0481C3.22776 20.1799 3.48656 20.2493 3.75 20.2493H20.2455C20.509 20.2493 20.7678 20.1799 20.9959 20.0481C21.224 19.9163 21.4134 19.7268 21.545 19.4986C21.6766 19.2703 21.7458 19.0115 21.7456 18.748C21.7453 18.4846 21.6757 18.2258 21.5438 17.9978L13.296 3.74857C13.1642 3.52093 12.9749 3.33193 12.7471 3.20055C12.5192 3.06916 12.2608 3 11.9978 3C11.7348 3 11.4764 3.06916 11.2485 3.20055C11.0206 3.33193 10.8313 3.52093 10.6996 3.74857V3.74857Z'
                    stroke='#E3A202'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z'
                    fill='#E3A202'
                  />
                </svg>
                Partial import completed
              </p>
              <p style={{ marginBottom: 20 }}>
                Some users might not have been imported successfully. Please download the response file for detailed
                statuses.
              </p>

              <div className='row'>
                <Button
                  onClick={handleDownloadDetails}
                  variant={'contained'}
                  sx={{
                    background: '#6123FF',
                    color: '#fff',
                    textTransform: 'none',
                    width: 180
                  }}
                  startIcon={<DownloadIcon color={'#fff'} />}
                >
                  Download details
                </Button>
              </div>
            </div>
          )}

          {!fileUploadingDone && !fileUploadingPartial && (
            <>
              <p
                style={{
                  fontSize: '14px',
                  marginBottom: '32px'
                }}
              >
                Please attach a .CSV or .XLS file with user data. You can download an example the file below
              </p>

              <div
                className={`import-drop-zone column ${dragOver && 'import-drop-zone-active'}`}
                onDragEnter={e => {
                  handleDragDropEvent(e)
                  setDragOver(true)
                }}
                onDragExit={e => {
                  handleDragDropEvent(e)
                  setDragOver(false)
                }}
                onDragOver={e => {
                  handleDragDropEvent(e)
                }}
                onDrop={e => {
                  handleDragDropEvent(e)
                  setFiles(e, 'a')
                }}
              >
                <input
                  ref={inputRef}
                  type='file'
                  accept='.csv, .xls, xlsx'
                  style={{ display: 'none' }}
                  onChange={e => {
                    setFiles(e, 'a')
                    inputRef.current.value = null
                  }}
                />
                {fileUploading ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <svg width='38' height='48' viewBox='0 0 38 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <g opacity='0.1'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M23.75 0.000489576V8.63695C23.75 10.6531 23.75 11.6612 24.1383 12.4313C24.4798 13.1086 25.0248 13.6594 25.6951 14.0045C26.4572 14.3969 27.4547 14.3969 29.4499 14.3969H37.9961L37.9992 14.4V36.48C37.9992 40.5124 37.9992 42.5286 37.2227 44.0687C36.5396 45.4235 35.4496 46.525 34.109 47.2152C32.5849 48 30.5898 48 26.5995 48H11.3998C7.40947 48 5.41433 48 3.89024 47.2152C2.54961 46.525 1.45965 45.4235 0.776563 44.0687C0 42.5286 0 40.5124 0 36.48V11.52C0 7.48762 0 5.47143 0.776563 3.93127C1.45965 2.5765 2.54961 1.47504 3.89024 0.784753C5.41433 0 7.40947 0 11.3998 0H23.7495L23.75 0.000489576Z'
                          fill='#6123FF'
                        />
                        <path
                          d='M26.125 8.80004V0.539056V0L37.9998 11.9999L37.4665 12H29.2916C28.1832 12 27.629 12 27.2056 11.782C26.8332 11.5903 26.5305 11.2843 26.3407 10.908C26.125 10.4802 26.125 9.92013 26.125 8.80004Z'
                          fill='#6123FF'
                        />
                      </g>
                      <path
                        opacity='0.3'
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M31.2272 38.7528L19.0002 26.3969L6.77326 38.7528C6.02341 39.5105 6.56018 40.7969 7.62623 40.7969H14.2539V47.9968H23.7537V40.7969H30.3742C31.4403 40.7969 31.9771 39.5105 31.2272 38.7528Z'
                        fill='#6123FF'
                      />
                    </svg>
                    <p className='upload-link'>
                      <span onClick={() => inputRef.current.click()}>Click to upload</span> or drag and drop file here
                    </p>
                    <p className='upload-filesize-msg'>CSV or XLS (5MB max)</p>
                  </>
                )}
              </div>
              <div
                style={{
                  border: '1px solid #E7E7EE',
                  borderRadius: '12px',
                  padding: '14px 34px',
                  marginBottom: '10px'
                }}
                className='row row-center'
              >
                <svg
                  style={{ marginRight: '10px' }}
                  xmlns='http://www.w3.org/2000/svg'
                  height='28'
                  viewBox='0 0 56 64'
                  width='30'
                >
                  <path
                    clipRule='evenodd'
                    d='m5.106 0c-2.802 0-5.073 2.272-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.903-20.31h-31.945z'
                    fill='#45b058'
                    fillRule='evenodd'
                  />
                  <path
                    d='m20.306 43.197c.126.144.198.324.198.522 0 .378-.306.72-.703.72-.18 0-.378-.072-.504-.234-.702-.846-1.891-1.387-3.007-1.387-2.629 0-4.627 2.017-4.627 4.88 0 2.845 1.999 4.879 4.627 4.879 1.134 0 2.25-.486 3.007-1.369.125-.144.324-.233.504-.233.415 0 .703.359.703.738 0 .18-.072.36-.198.504-.937.972-2.215 1.693-4.015 1.693-3.457 0-6.176-2.521-6.176-6.212s2.719-6.212 6.176-6.212c1.8.001 3.096.721 4.015 1.711zm6.802 10.714c-1.782 0-3.187-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.361.27-.757.702-.757.144 0 .306.036.432.144.828.739 1.98 1.314 3.367 1.314 2.143 0 2.827-1.152 2.827-2.071 0-3.097-7.112-1.386-7.112-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.467 3.853 1.278.162.144.252.342.252.54 0 .36-.306.72-.703.72-.144 0-.306-.054-.432-.162-.882-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636.001 1.748-1.187 3.531-4.428 3.531zm16.994-11.254-4.159 10.335c-.198.486-.685.81-1.188.81h-.036c-.522 0-1.008-.324-1.207-.81l-4.142-10.335c-.036-.09-.054-.18-.054-.288 0-.36.323-.793.81-.793.306 0 .594.18.72.486l3.889 9.992 3.889-9.992c.108-.288.396-.486.72-.486.468 0 .81.378.81.793.001.09-.017.198-.052.288z'
                    fill='#fff'
                  />
                  <g clipRule='evenodd' fillRule='evenodd'>
                    <path
                      d='m56.001 20.357v1h-12.8s-6.312-1.26-6.128-6.707c0 0 .208 5.707 6.003 5.707z'
                      fill='#349c42'
                    />
                    <path
                      d='m37.098.006v14.561c0 1.656 1.104 5.791 6.104 5.791h12.8l-18.904-20.352z'
                      fill='#fff'
                      opacity='.5'
                    />
                  </g>
                </svg>

                <p>Add users example (users_import.csv) </p>
                <div className='spacer'></div>
                <div
                  className='row row-center'
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#6123FF',
                    transition: '200ms',
                    '&:hover': { opacity: '0.8' }
                  }}
                  onClick={() => handleDownloadImport('CSV')}
                >
                  <DownloadIconV2 /> <span style={{ marginLeft: '10px' }}>Download File</span>
                </div>
              </div>
              <div
                style={{
                  border: '1px solid #E7E7EE',
                  borderRadius: '12px',
                  padding: '14px 34px'
                }}
                className='row row-center'
              >
                <svg style={{ marginRight: '10px' }} width="30" height="30" viewBox="0 0 201 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path opacity="0.3" d="M140.1 49.25C134.633 49.2447 129.391 47.0704 125.525 43.2046C121.66 39.3387 119.485 34.097 119.48 28.6299V0H27.3401C20.2031 0 13.3584 2.83523 8.31177 7.88184C3.26516 12.9284 0.429932 19.7729 0.429932 26.9099V189.13C0.429932 196.267 3.26516 203.112 8.31177 208.158C13.3584 213.205 20.2031 216.04 27.3401 216.04H137.94C145.077 216.04 151.922 213.205 156.968 208.158C162.015 203.112 164.85 196.267 164.85 189.13V49.25H140.1Z" fill="#00C650" />
                  </g>
                  <path d="M164.85 49.25H140.1C134.633 49.2447 129.391 47.0704 125.525 43.2046C121.66 39.3387 119.485 34.097 119.48 28.6299V0L164.85 49.25Z" fill="#00C650" />
                  <path d="M184.37 170.61H59.3301C50.4604 170.61 43.27 177.8 43.27 186.67V223.95C43.27 232.82 50.4604 240.01 59.3301 240.01H184.37C193.24 240.01 200.43 232.82 200.43 223.95V186.67C200.43 177.8 193.24 170.61 184.37 170.61Z" fill="#00C650" />
                  <path d="M87.8601 213.38V220.65H80.3301V213.38H87.8601Z" fill="white" />
                  <path d="M109.89 220.65L103.56 211.29L98.1101 220.65H89.78L99.3899 205.17L89.48 190.58H98.1101L104.28 199.64L109.6 190.58H117.89L108.41 205.72L118.53 220.65H109.89Z" fill="white" />
                  <path d="M163.299 216.379C162.436 217.76 161.197 218.866 159.729 219.569C157.941 220.406 155.982 220.813 154.009 220.759C151.032 220.883 148.098 220.014 145.669 218.289C144.594 217.482 143.715 216.444 143.097 215.251C142.479 214.059 142.138 212.742 142.099 211.399H149.839C149.894 212.412 150.325 213.367 151.049 214.079C151.769 214.75 152.725 215.109 153.709 215.079C154.109 215.102 154.511 215.046 154.89 214.914C155.27 214.782 155.619 214.576 155.919 214.309C156.185 214.05 156.395 213.739 156.535 213.394C156.674 213.05 156.74 212.68 156.729 212.309C156.738 211.956 156.677 211.605 156.548 211.277C156.42 210.949 156.226 210.65 155.979 210.399C155.449 209.863 154.821 209.435 154.129 209.139C153.402 208.819 152.382 208.429 151.069 207.969C149.475 207.443 147.918 206.812 146.409 206.079C145.168 205.44 144.104 204.506 143.309 203.359C142.386 201.97 141.931 200.324 142.009 198.659C141.972 197.009 142.448 195.388 143.369 194.019C144.315 192.681 145.622 191.64 147.139 191.019C148.886 190.308 150.763 189.967 152.649 190.019C155.512 189.861 158.341 190.706 160.649 192.409C161.631 193.208 162.435 194.203 163.011 195.331C163.586 196.459 163.919 197.694 163.989 198.959H156.119C156.031 198.074 155.651 197.244 155.039 196.599C154.726 196.299 154.355 196.067 153.949 195.915C153.544 195.764 153.111 195.697 152.679 195.719C151.929 195.685 151.192 195.927 150.609 196.399C150.339 196.654 150.13 196.966 149.995 197.311C149.86 197.657 149.803 198.029 149.829 198.399C149.813 199.071 150.065 199.722 150.529 200.209C151.026 200.728 151.625 201.14 152.289 201.419C152.999 201.729 154.019 202.129 155.349 202.609C156.971 203.137 158.552 203.786 160.079 204.549C161.325 205.221 162.4 206.173 163.219 207.329C164.152 208.739 164.614 210.409 164.539 212.099C164.525 213.612 164.096 215.093 163.299 216.379Z" fill="white" />
                  <path d="M129.26 215.08H139V220.65H122V190.58H129.28L129.26 215.08Z" fill="white" />
                  <path d="M119.32 150.84H46C39.65 150.84 34.48 144.52 34.48 136.74V83.55C34.48 75.78 39.65 69.46 46 69.46H119.35C125.69 69.46 130.86 75.78 130.86 83.55V136.74C130.83 144.52 125.66 150.84 119.32 150.84ZM46 76.6599C42.89 76.6599 40.3701 79.75 40.3701 83.55V136.74C40.3701 140.54 42.89 143.63 46 143.63H119.35C122.45 143.63 124.97 140.54 124.97 136.74V83.55C124.97 79.75 122.45 76.6599 119.35 76.6599H46Z" fill="#00C650" />
                  <path d="M127.89 118.81H37.3999V126.02H127.89V118.81Z" fill="#00C650" />
                  <path d="M127.89 94.09H37.3999V101.3H127.89V94.09Z" fill="#00C650" />
                  <path d="M104.71 73.06H97.5V147.23H104.71V73.06Z" fill="#00C650" />
                  <path d="M67.78 73.06H60.5701V147.23H67.78V73.06Z" fill="#00C650" />
                </svg>

                <p>Add users example (users_import.xls) </p>
                <div className='spacer'></div>
                <div
                  className='row row-center'
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#6123FF',
                    transition: '200ms',
                    '&:hover': { opacity: '0.8' }
                  }}
                  onClick={() => handleDownloadImport('XLS')}
                >
                  <DownloadIconV2 /> <span style={{ marginLeft: '10px' }}>Download File</span>
                </div>
              </div>
              <div className='row row-center' style={{ margin: '30px 0px 14px' }}>
                <div
                  style={{
                    flex: 1,
                    height: 1,
                    background: '#E7E7EE'
                  }}
                ></div>
                <p
                  style={{
                    color: '#76767F',
                    fontSize: '14px',
                    margin: '0px 8px'
                  }}
                >
                  Or
                </p>
                <div
                  style={{
                    flex: 1,
                    height: 1,
                    background: '#E7E7EE'
                  }}
                ></div>
              </div>
              <p
                style={{
                  textAlign: 'center',
                  marginBottom: 20
                }}
              >
                <Link
                  className='upload-link'
                  style={{
                    textDecoration: 'none',
                    marginRight: -4
                  }}
                  to='/settings/integrations'
                >
                  <span>Connect</span>
                </Link>{' '}
                Cyberlift to your external workforce platform. Users and groups will then sync automatically
              </p>
            </>
          )}
        </DialogContent>
      </Dialog>

      <ManualAddUserPopup
        open={openManualImportPopup}
        onClose={(wasAdded = false) => {
          setOpenManualImportPopup(false)
          if (wasAdded) {
            updateUsers()
          }
        }}
        onSuccess={() => {
          setSuccessMessage('User successfully added')
        }}
      />
    </div>
  )
}

export default Team
