import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'

export default function TemplateNameColumn({ row, handleOpenTemplate }) {
  return (
    <Box display={'flex'} flexDirection={'column'} width={450}>
      <div className='column'>
        <Tooltip
          title={getTemplateTitle(row?.templateType || row?.simulationCampaignStepType, row?.templateSubject)}
          placement={'top-start'}
        >
          <Typography
            onClick={() => handleOpenTemplate(row)}
            noWrap
            fontFamily={'Plus Jakarta Sans'}
            fontWeight={600}
            fontSize={14}
            color={'#1B1B26'}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.75,
                fontWeight: 600
              }
            }}
          >
            {getTemplateTitle(row?.templateType || row?.simulationCampaignStepType, row?.templateSubject)}
          </Typography>
        </Tooltip>
        <Tooltip title={row?.templateName || ''} placement={'top-start'}>
          <Typography
            onClick={() => handleOpenTemplate(row)}
            noWrap
            fontFamily={'Plus Jakarta Sans'}
            fontWeight={600}
            fontSize={13}
            color={'#1B1B26a0'}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.75,
                fontWeight: 600
              }
            }}
          >
            {row?.templateName || ''}
          </Typography>
        </Tooltip>
      </div>
    </Box>
  )
}

function getTemplateTitle(type, name) {
  if (type === 'INTERNAL' || type === 'CUSTOM') {
    return name
  }
  if (type === 'SMS') {
    return 'Personalized SMS'
  }
  if (type === 'SLACK') {
    return 'Personalized Slack message'
  }
  if (type === 'WHATSAPP') {
    return 'Personalized WhatsApp message'
  }
}
