import { apiSlice } from './apiSlice'
export const integrationsAPI = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getIntegration: builder.query({
      query: () => `/v1/integrations`
    })
  })
})

export const { useGetIntegrationQuery } = integrationsAPI
