import React, { useContext, useEffect, useState } from 'react'
import {
  useArchiveSimulationMutation,
  useCloneSimulationMutation,
  useDeleteSimulationMutation,
  useLazyGetSimulationsQuery,
  useUnarchiveSimulationMutation
} from '../../../api/simulation/simulation'
import StatusComponent from './StatusComponent'
import ProjectNameColumn from './ProjectNameColumn'
import ActionButtons from '../ActionButtons'
import ViewIcon from '../../../common/icons/viewIcon'
import EditIcon from '../../../common/icons/editIcon'
import Archive from '../../../common/icons/archive'
import PercentageComponent from './PercentageComponent'
import { QueryParamsContext } from '../QueryParamsContext'
import { useNavigate } from 'react-router-dom'
import TrainingIcon from 'src/common/icons/training'
import { SnackBarContext } from 'src/App'
import customToastWithAlert from '../../../common/components/CustomToastWithAlert'
import DeleteIcon from 'src/common/icons/deleteIcon'

export default function useSimulationTable() {
  const [isEditShowing, setIsEditShowing] = useState(false)
  const [isDeleteShowing, setIsDeleteShowing] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [editId, setEditId] = useState(null)

  const [getSimulations, { data, error, isLoading }] = useLazyGetSimulationsQuery()
  const [archiveSimulation, { isLoading: archiveLoading, isSuccess, isError }] = useArchiveSimulationMutation()
  const [cloneSimulation, { isLoading: cloneLoading }] = useCloneSimulationMutation()
  const [deleteSimulation, { isLoading: deleteLoading }] = useDeleteSimulationMutation()
  const [unarchiveSimulation, { isLoading: unarchiveLoading, isSuccess: isUnarchived, isError: isNotUnarchived }] =
    useUnarchiveSimulationMutation()

  const { setSuccessMessage } = useContext(SnackBarContext)

  const { params } = useContext(QueryParamsContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess || isError) {
      customToastWithAlert({
        message: isError ? 'Failed to archive training package' : 'Training package archived successfully',
        type: isError ? 'error' : 'success'
      })
    }
  }, [isError, isSuccess])

  useEffect(() => {
    if (isUnarchived || isNotUnarchived) {
      customToastWithAlert({
        message: isNotUnarchived ? 'Failed to unarchive training package' : 'Training package unarchived successfully',
        type: isNotUnarchived ? 'error' : 'success'
      })
    }
  }, [isNotUnarchived, isUnarchived])

  function handleOpen(id) {
    setIsEditShowing(true)
    setEditId(id)
  }

  function handleClose() {
    setIsEditShowing(false)
    setEditId(null)
  }
  function handleDeleteCampaign() {
    deleteSimulation(selectedItem.id).then(() => {
      setSuccessMessage('Simulation campaign successfully deleted')
      setIsDeleteShowing(false)
    })
  }
  function setShowDeleteDialog(row) {
    setIsDeleteShowing(true)
    setSelectedItem(row)
  }
  function handleCloseDeleteDialog() {
    setIsDeleteShowing(false)
    setSelectedItem(null)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      stickyLeft: true,
      width: 250,
      align: 'left',
      renderComponent: (value, row) => <ProjectNameColumn key={row.id} row={row} />
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      align: 'left',
      minWidth: 250,
      renderComponent: (value, row) => <StatusComponent key={row.id} status={row.status} />
    },
    {
      field: 'simulationsFailRate',
      headerName: 'Compromise Rate (%)',
      sortable: true,
      align: 'left',
      maxWidth: 150,
      renderComponent: (value, row) => <PercentageComponent percentage={row.simulationsFailRate} />
    },
    { field: 'usersCount', headerName: 'Employees', sortable: true, align: 'left', maxWidth: 120 },
    { field: 'simulationsSentCount', headerName: 'Sent', sortable: true, align: 'left', maxWidth: 120 },
    { field: 'simulationsOpenedCount', headerName: 'Opened', sortable: true, align: 'left', maxWidth: 120 },
    {
      field: 'simulationsLinkClickedCount',
      headerName: 'Links Clicked',
      sortable: true,
      align: 'left',
      maxWidth: 140
    },
    {
      field: 'simulationsAttachmentOpenedCount',
      headerName: 'Attachments Opened',
      sortable: true,
      align: 'left',
      maxWidth: 160
    },
    { field: 'simulationsQrLinkClickedCount', headerName: 'QR Scanned', sortable: true, align: 'left', maxWidth: 120 },
    {
      field: 'simulationsCredentialsProvidedCount',
      headerName: 'Creds Provided',
      sortable: true,
      align: 'left',
      maxWidth: 140
    },
    { field: 'simulationsRepliedCount', headerName: 'Replied', sortable: true, align: 'left', maxWidth: 100 },
    {
      field: 'actions',
      headerName: '',
      stickyRight: true,
      maxWidth: '46px',
      align: 'left',
      renderComponent: (value, row) => (
        <ActionButtons
          config={[
            {
              name: 'View Campaign',
              icon: <ViewIcon color={'#76767F'} />,
              onClick: () => {
                return navigate(`/simulation-campaign/${row.id}`)
              }
            },
            {
              name: 'Edit',
              icon: <EditIcon color={'#76767F'} />,
              onClick: () => handleOpen(row.id),
              isHidden: row.status === 'ARCHIVED' || row.status === 'COMPLETED'
            },
            {
              name: 'Clone',
              icon: <TrainingIcon color='#76767F' />,
              onClick: () =>
                cloneSimulation(row.id).then(() => {
                  setSuccessMessage('Simulation campaign successfully cloned')
                })
            },
            {
              name: 'Archive',
              icon: <Archive />,
              onClick: () => archiveSimulation(row.id),
              isHidden: row.status !== 'IN_PROGRESS'
            },
            {
              name: 'Unarchive',
              icon: <Archive />,
              onClick: () => unarchiveSimulation(row.id),
              isHidden: row.status !== 'ARCHIVED'
            },
            {
              name: 'Delete',
              icon: <DeleteIcon color={'#76767F'} />,
              onClick: () => setShowDeleteDialog(row)

            }
          ]}
        />
      )
    }
  ]
  function fetchData({ page, size, sort, params }) {
    getSimulations({ page, size, sort, ...params })
  }

  const simulations = data?.data
  const totalCount = data?.totalCount
  return {
    columns,
    fetchData,
    simulations,
    error,
    totalCount,
    params,
    isEditShowing,
    handleClose,
    editId,
    isDeleteShowing,
    selectedItem,
    handleDeleteCampaign,
    handleCloseDeleteDialog,
    isLoading: isLoading || archiveLoading || unarchiveLoading || cloneLoading || deleteLoading
  }
}
