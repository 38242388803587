import React, { useContext, useCallback, useMemo, useState } from 'react'
import NewCampaignButton from './NewCampaign/NewCampaignButton'
import { Box, TextField, Typography } from '@mui/material'
import FilterPopper from './Filter/FilterPopper'
import { ReactComponent as SearchIcon } from '../../common/icons/search.svg'
import { QueryParamsContext } from './QueryParamsContext'
import { debounce } from './utils'

export default function ManualSimulationTopBar() {
  const { params, setParams } = useContext(QueryParamsContext)
  const [searchText, setSearchText] = useState(params.searchText)

  const debouncedSetParams = useMemo(
    () =>
      debounce(searchText => {
        setParams(prevParams => ({ ...prevParams, searchText }))
      }, 500),
    [setParams]
  )

  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value
      setSearchText(value)
      debouncedSetParams(value)
    },
    [debouncedSetParams]
  )

  return (
    <div style={{ marginBottom: '32px', justifyContent: 'space-between' }} className='row row-center'>
      <Box display={'flex'} columnGap={'16px'} alignItems={'center'}>
        <Typography fontSize={'24px'} fontWeight={900} fontFamily={'Satoshi'}>
          Simulation Campaigns
        </Typography>
        <FilterPopper />
      </Box>
      <Box display={'flex'} columnGap={'32px'}>
        <Box width='280px'>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                border: '1px solid #E7E7EE',
                background: '#fff',
                width: '100%'
              }
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onChange={handleSearchChange}
            value={searchText}
            size='small'
            placeholder='Search Campaigns'
          />
        </Box>
        <NewCampaignButton />
      </Box>
    </div>
  )
}
