import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import userService from 'src/services/user.service'
import useDebounce from 'src/hooks'

const defaultFilters = {
  page: 0,
  size: 25,
  status: 'ACTIVE'
}

export function SelectManager({ managerUserId, onSelect, inputStyle, labelStyle }) {
  const [selectedUser, setSelectedUser] = useState(null)

  const [search, setSearch] = useState('')
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [users, setUsers] = useState([])

  const [filters, setFilters] = useState(defaultFilters)
  const debouncedSearchTerm = useDebounce(search, 500)

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleFilter({ searchText: search })
    } else {
      handleFilter({ searchText: '' })
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (managerUserId) {
      userService.getUser(managerUserId).then(resp => {
        setSelectedUser(resp.data)
      })
    }
  }, [managerUserId])

  useEffect(() => {
    setLoadingUsers(true)
    userService.getUsers(filters).then(resp => {
      setUsers(resp.data)
    }).finally(() => {
      setLoadingUsers(false)
    })
  }, [filters])

  const handleFilter = param => {
    setFilters({ ...filters, ...param })
  }

  const handleSelectUser = (e, val, reason) => {
    if (reason == 'selectOption') {
      setSelectedUser(users.find(el => el.id == val.id))
      onSelect(val.id)
    }
    if (reason == 'clear') {
      setSelectedUser(null)
      onSelect(null)
    }
  }

  return (
    <>
      <Autocomplete
        id="country-select-demo"
        sx={{
          marginBottom: '20px',
          '& .MuiInputBase-root': {
            borderRadius: '20px',
            ...inputStyle
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '20px',
            border: '1px solid #E7E7EE',
          },
          '& .MuiFormLabel-root': {
            ...labelStyle
          },
          '& .MuiFormLabel-root.Mui-focused': {
            top: 0,
            left: 4
          }
        }}
        fullWidth
        options={users}
        value={selectedUser}
        autoHighlight
        loading={loadingUsers}
        onChange={handleSelectUser}
        onBlur={() => setSearch('')}
        getOptionLabel={(option) => (option.firstName + ' ' + option.lastName)}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              className='column'
              key={option.email}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...optionProps}
            >
              {option.firstName} {option.lastName}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Enter a name'
            sx={{ borderRadius: '20px' }}
            label="Manager"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            slotProps={{
              htmlInput: {
                ...params.inputProps,
                autoComplete: 'new-password',
              },
            }}
          />
        )}
      />

    </>
  )
}
