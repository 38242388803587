import DataTableContainer from '../../../common/components/DataTable/DataTableContainer'
import React from 'react'

import useSimulationTable from './useSimulationTable'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import { Box } from '@mui/material'
import { useGetSimulationsQuery } from '../../../api/simulation/simulation'
import EmptyPage from '../EmptyPage'
import NewCampaignModal from '../NewCampaign/NewCampaignModal'
import DeleteDialog from 'src/common/components/DeleteDialog'

export default function ManualSimulationTable() {
  const { data, isLoading: isSimulationLoading } = useGetSimulationsQuery({})
  const {
    columns,
    isEditShowing,
    editId,
    handleClose,
    fetchData,
    params,
    totalCount,
    simulations,
    isDeleteShowing,
    selectedItem,
    handleDeleteCampaign,
    handleCloseDeleteDialog,
    isLoading
  } = useSimulationTable()

  if (+data?.totalCount === 0) {
    return <EmptyPage />
  }

  if (isLoading || isSimulationLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }
  return (
    <>
      <DataTableContainer
        onRequestData={fetchData}
        filter={params}
        config={{
          columns,
          stickyPagination: true,
          stickyHeader: true,
          height: 'calc(100vh - 155px)'
        }}
        emptyText={'No simulation campaigns found'}
        data={simulations}
        totalCount={totalCount}
      />
      {isDeleteShowing && (
        <DeleteDialog
          str={selectedItem?.name}
          open={isDeleteShowing}
          onAction={handleDeleteCampaign}
          onClose={handleCloseDeleteDialog}
        />
      )}
      {isEditShowing && <NewCampaignModal editId={editId} open={isEditShowing} handleClose={handleClose} />}
    </>
  )
}
