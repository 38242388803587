import React, { useContext, useEffect, useState } from 'react'

import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'

import { UserViewContext } from 'src/App'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import UserAvatar from 'src/common/components/UserAvatar'
import userService from 'src/services/user.service'
import Spacer from 'src/common/components/Spacer'

function MembersList({ open, onClose, ids, title, description }) {
  const [page, setPage] = useState(1)
  const [members, setMembers] = useState([])
  const [membersLoading, setMembersLoading] = useState([])
  const [totalPages, setTotalPages] = useState(1)

  const perPage = 25

  const [userViewId, setUserViewId] = useContext(UserViewContext)

  useEffect(() => {
    if (open) {
      setMembersLoading(true)
      userService
        .getUsersByIds(ids, perPage, page - 1)
        .then(resp => {
          setTotalPages(Math.ceil(parseInt(resp.headers['x-total-count']) / perPage))
          setMembers(resp.data)
          setMembersLoading(false)
        })
        .catch(err => {
          console.error(err)
          setMembersLoading(false)
        })
    }
  }, [open, page])

  const handlePageChange = pageNum => {
    setPage(pageNum)
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '22px',
          maxWidth: 'unset',
          width: '900px'
        }
      }}
      open={open}
      onClose={onClose}
    >
      <div className='row row-center' style={{ padding: '24px 32px 0px 32px' }}>
        <h2
          style={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          {title}
        </h2>
        <Spacer />
        <svg
          onClick={onClose}
          style={{ cursor: 'pointer' }}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18'
            stroke='#1B1B26'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>

      <DialogContent sx={{ padding: '24px 32px' }}>
        <p style={{ fontSize: '14px', marginBottom: '32px' }}>{description}</p>

        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {membersLoading && <LoadingIndicator />}
          {members?.map(row => (
            <div
              key={row.id}
              style={{
                height: '80px',
                borderBottom: '1px solid #F1F1F5'
              }}
              className='row row-center'
            >
              <div className='row' style={{ alignItems: 'center', width: '35%' }}>
                <UserAvatar id={row?.id} firstName={row?.firstName} lastName={row?.lastName} />

                <div
                  onClick={() => {
                    setUserViewId(row.id)
                  }}
                  style={{ cursor: 'pointer' }}
                  className='column'
                >
                  <p
                    className='employee-name'
                    style={{
                      fontWeight: '600',
                      fontSize: '16px',
                      marginBottom: '8px'
                    }}
                  >
                    {(row?.firstName || 'unknown') + ' ' + (row?.lastName || 'unknown')}
                  </p>
                  <p
                    style={{
                      fontSize: '13px',
                      color: '#76767F'
                    }}
                  >
                    {row.email}
                  </p>
                </div>
              </div>

              <div style={{ width: '20%' }}>
                <p>{row.country}</p>
              </div>
              <div style={{ width: '28%' }}>
                <p>{row.department}</p>
              </div>

              <div style={{ width: '17%' }} className='row row-center'>
                <svg
                  style={{ marginRight: '10px' }}
                  width='18'
                  height='19'
                  viewBox='0 0 18 19'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.2'
                    d='M2.8125 8V3.875C2.8125 3.72582 2.87176 3.58274 2.97725 3.47725C3.08274 3.37176 3.22582 3.3125 3.375 3.3125H14.625C14.7742 3.3125 14.9173 3.37176 15.0227 3.47725C15.1282 3.58274 15.1875 3.72582 15.1875 3.875V8C15.1875 13.9073 10.1738 15.8645 9.1727 16.1964C9.06079 16.2349 8.93921 16.2349 8.8273 16.1964C7.8262 15.8645 2.8125 13.9073 2.8125 8Z'
                    fill='#B40020'
                  />
                  <path
                    d='M2.8125 8V3.875C2.8125 3.72582 2.87176 3.58274 2.97725 3.47725C3.08274 3.37176 3.22582 3.3125 3.375 3.3125H14.625C14.7742 3.3125 14.9173 3.37176 15.0227 3.47725C15.1282 3.58274 15.1875 3.72582 15.1875 3.875V8C15.1875 13.9073 10.1738 15.8645 9.1727 16.1964C9.06079 16.2349 8.93921 16.2349 8.8273 16.1964C7.8262 15.8645 2.8125 13.9073 2.8125 8Z'
                    stroke='#B40020'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>

                <p
                  style={{
                    marginRight: '30px',
                    fontWeight: 500,
                    fontSize: '24px',
                    lineHeight: '120%'
                  }}
                >
                  {' '}
                  {Math.round(row.failRate * 100)}%
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className='row row-center'>
          <p>
            Page <strong>{page}</strong> of <strong>{totalPages}</strong>
          </p>
          <Spacer />
          <Button
            onClick={() => handlePageChange(page - 1)}
            disabled={page == 1}
            variant='outlined'
            sx={{
              border: '1px solid #D0D0D8',
              color: '#1B1B26',
              textTransform: 'unset',
              mt: '18px',
              mb: '24px',
              mr: '24px',
              borderRadius: '12px',
              width: '148px'
            }}
          >
            Previous
          </Button>
          <Button
            onClick={() => handlePageChange(page + 1)}
            variant='outlined'
            disabled={page == totalPages || !totalPages}
            sx={{
              border: '1px solid #D0D0D8',
              color: '#1B1B26',
              textTransform: 'unset',
              mt: '18px',
              mb: '24px',
              borderRadius: '12px',
              width: '148px'
            }}
          >
            Next
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MembersList
