import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough'
import CodeIcon from '@mui/icons-material/Code'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import { emojify } from 'node-emoji'
import styled from 'styled-components'
import { Box } from '@mui/material'

export const variablesOptions = [
  { name: 'First name', value: '[firstName]' },
  { name: 'Last name', value: '[lastName]' },
  { name: 'Email', value: '[email]' },
  { name: 'Company short name', value: '[companyShortName]' },
  { name: 'Company name', value: '[companyName]' },
  { name: 'Phishing link', value: '[phishingLink]' }
]

export const formatActions = [
  { icon: FormatBoldIcon, title: 'Bold', marker: '*', type: 'inline' },
  { icon: FormatItalicIcon, title: 'Italic', marker: '_', type: 'inline' },
  { icon: FormatStrikethroughIcon, title: 'Strikethrough', marker: '~', type: 'inline' },
  { icon: CodeIcon, title: 'Inline Code', marker: '`', type: 'inline' },
  { type: 'divider' },
  { icon: FormatListBulletedIcon, title: 'Bulleted List', marker: '• ', type: 'list' },
  { icon: FormatListNumberedIcon, title: 'Numbered List', marker: '1. ', type: 'list' },
  { icon: FormatQuoteIcon, title: 'Quote', marker: '>', type: 'block' },
  { type: 'divider' },
  {
    icon: InsertLinkIcon,
    title: 'Insert Link',
    marker: '<https://|',
    closeMarker: '>',
    type: 'link'
  },
  { type: 'divider' },
  {
    icon: IntegrationInstructionsIcon,
    title: 'Code Block',
    marker: '```\n',
    closeMarker: '\n```',
    type: 'multiline'
  }
]

export const getCurrentListFormat = (text, position) => {
  const lastNewLine = text.lastIndexOf('\n', position - 1)
  const lineStart = lastNewLine === -1 ? 0 : lastNewLine + 1
  const currentLine = text.substring(lineStart, position)

  const numberedMatch = currentLine.match(/^(\d+)\.\s/)
  if (numberedMatch) {
    return {
      type: 'numbered',
      number: parseInt(numberedMatch[1]),
      marker: `${parseInt(numberedMatch[1]) + 1}. `
    }
  }
  const bulletMatch = currentLine.match(/^[•]\s/)
  if (bulletMatch) {
    return {
      type: 'bulleted',
      marker: '• '
    }
  }

  return null
}
export const parseSlackMessage = text => {
  if (!text) return ''

  let parsed = emojify(text)

  parsed = parsed.replace(/\*(.*?)\*/g, '<strong>$1</strong>')
  parsed = parsed.replace(/_(.*?)_/g, '<em>$1</em>')
  parsed = parsed.replace(/~(.*?)~/g, '<del>$1</del>')
  parsed = parsed.replace(/```([\s\S]*?)```/g, (match, codeContent) => {
    const escapedCode = codeContent.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    return `<pre class="slack-pre"><code>${escapedCode}</code></pre>`
  })
  parsed = parsed.replace(/`(.*?)`/g, (match, code) => {
    const escapedCode = code.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    return `<code class="slack-code">${escapedCode}</code>`
  })
  parsed = parsed.replace(/^(>\s.+\n?)+/gm, match => {
    const content = match
      .split('\n')
      .filter(line => line.trim())
      .map(line => line.replace(/^>\s/, ''))
      .join('<br/>')
    return `<blockquote class="slack-quote">${content}</blockquote>`
  })
  parsed = parsed.replace(/^>\s(.*)$/gm, '<blockquote class="slack-quote">$1</blockquote>')
  parsed = parsed.replace(/<(https?:\/\/.*?)\|(.*?)>/g, '<a href="$1" class="slack-link">$2</a>')
  parsed = parsed.replace(/<(https?:\/\/.*?)>/g, '<a href="$1" class="slack-link">$1</a>')
  parsed = parsed.replace(/^•\s(.*)$/gm, '<li>$1</li>')
  parsed = parsed.replace(/^\d+\.\s(.*)$/gm, '<li>$1</li>')
  parsed = parsed.replace(/\n/g, '<br/>')

  return parsed
}
export const SlackFrame = styled(Box)({
  border: '1px solid #ddd',
  borderRadius: '4px',
  overflow: 'hidden',
  width: '100%',
  boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1)',
  marginTop: '16px'
})

export const SlackHeader = styled(Box)({
  backgroundColor: '#350d36',
  color: 'white',
  padding: '10px 15px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
})
