import React, { useContext, useRef, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useLazyGetCustomTemplatesListQuery } from '../../../../api/simulation/simulation'
import TemplateCard from './TemplateCard'

import { QueryParamsTemplateContext } from './QueryParamsTemplateContext'
import { useInfiniteScrollerMyTemplates } from '../../useInfiniteScrollerMyTemplates'

export default function CustomTemplateCardList({ input }) {
  const { value, onChange } = input
  const { params } = useContext(QueryParamsTemplateContext)
  const isUserInteraction = useRef(false)
  const [templateList, setTemplateList] = useState(value || [])

  function handleTemplateClick(templateId) {
    isUserInteraction.current = true
    setTemplateList(prevState => {
      if (prevState.includes(templateId)) {
        return prevState.filter(id => id !== templateId)
      }
      return [...prevState, templateId]
    })
  }
  useEffect(() => {
    setTemplateList(value || [])
  }, [value])

  useEffect(() => {
    if (isUserInteraction.current) {
      onChange(templateList)
      isUserInteraction.current = false
    }
  }, [templateList, onChange])
  const { InfiniteItems: MyTemplatesItem } = useInfiniteScrollerMyTemplates({
    apiHook: useLazyGetCustomTemplatesListQuery,
    options: {
      page: 0,
      size: 55
    },
    params,
    renderItem: template => (
      <TemplateCard
        key={template.id}
        templateList={templateList}
        card={template}
        onChange={handleTemplateClick}
        isChosenDefault={templateList.includes(template?.id)}
      />
    )
  })

  return (
    <>
      {params?.myTemplates && (
        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 16, fontWeight: 500, color: '#76767F' }}>
          My templates
        </Typography>
      )}
      {MyTemplatesItem()}
    </>
  )
}
