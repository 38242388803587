import React from 'react'

import DoneIcon from '../../../../common/icons/done'
import { ReactComponent as CancelIcon } from '../../../../common/icons/inActiveIcon.svg'
import { ReactComponent as SpinnerIcon } from '../../../../common/icons/hourglassIcon.svg'
import { Box, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

export default function StatusComponent({ status, reason }) {
  const Icon = iconMapper[status]

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <div
        style={{
          display: 'flex',
          color: status === 'COMPLETED' ? '#00BA97' : status === 'CANCELLED' ? '#F16A82' : '#1B1B26',
          fontFamily: 'Plus Jakarta Sans',
          fontSize: 14,
          fontWeight: 600
        }}
      >
        <Box display='flex' alignItems='center' gap={0.5}>
          {Icon}
          {statusMapper[status]}
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'} pl={'8px'} pb={'12px'}>
          {reason && (
            <Tooltip
              title={reason}
              placement='top'
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'rgba(97, 97, 97, 0.92)',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    fontSize: '12px',
                    maxWidth: '220px',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)'
                  }
                }
              }}
            >
              <InfoOutlined
                fontSize='small'
                sx={{
                  color: '#0088BA',
                  fontSize: '14px',
                  cursor: 'pointer',

                  '&:hover': { opacity: 0.8 }
                }}
              />
            </Tooltip>
          )}
        </Box>
      </div>
    </Box>
  )
}

const statusMapper = {
  CREATED: 'Planned',
  PLANNED: 'Planned',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled'
}

const iconMapper = {
  COMPLETED: <DoneIcon />,
  PLANNED: <SpinnerIcon />,
  CANCELLED: <CancelIcon stroke={'#F16A82'} />
}
