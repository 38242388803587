import React from "react";
import {emojify} from "node-emoji";
import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import {Letter} from "react-letter";
import { ReactComponent as Slack } from '../../../../common/icons/slackColorful.svg'



export default function SlackMessagePreview ({message}) {
  return (
    <SlackFrame>
      <SlackHeader>Slack Message</SlackHeader>
      <Box sx={{ padding: '16px', backgroundColor: '#ffffff' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Box
            sx={{
              width: 36,
              height: 36,
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Slack style={{ width: 36, height: 36 }} />
          </Box>
        </Box>
        <Box sx={{ pl: '46px' }}>
          <div className='slack-message'>
            <Letter html={parseSlackMessage(message)} />
          </div>
        </Box>
      </Box>
    </SlackFrame>)
}


const parseSlackMessage = text => {
  if (!text) return ''

  let parsed = emojify(text)

  parsed = parsed.replace(/\*(.*?)\*/g, '<strong>$1</strong>')

  parsed = parsed.replace(/_(.*?)_/g, '<em>$1</em>')

  parsed = parsed.replace(/~(.*?)~/g, '<del>$1</del>')

  parsed = parsed.replace(/```([\s\S]*?)```/g, (match, codeContent) => {
    const escapedCode = codeContent.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    return `<pre class="slack-pre"><code>${escapedCode}</code></pre>`
  })

  parsed = parsed.replace(/`(.*?)`/g, (match, code) => {
    const escapedCode = code.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    return `<code class="slack-code">${escapedCode}</code>`
  })

  parsed = parsed.replace(/^(>\s.+\n?)+/gm, match => {
    const content = match
      .split('\n')
      .filter(line => line.trim())
      .map(line => line.replace(/^>\s/, ''))
      .join('<br/>')
    return `<blockquote class="slack-quote">${content}</blockquote>`
  })

  parsed = parsed.replace(/^>\s(.*)$/gm, '<blockquote class="slack-quote">$1</blockquote>')

  parsed = parsed.replace(/<(https?:\/\/.*?)\|(.*?)>/g, '<a href="$1" class="slack-link">$2</a>')

  parsed = parsed.replace(/<(https?:\/\/.*?)>/g, '<a href="$1" class="slack-link">$1</a>')

  parsed = parsed.replace(/^•\s(.*)$/gm, '<li>$1</li>')
  parsed = parsed.replace(/^\d+\.\s(.*)$/gm, '<li>$1</li>')

  parsed = parsed.replace(/\n/g, '<br/>')

  return parsed
}
const SlackFrame = styled(Box)({
  border: '1px solid #ddd',
  borderRadius: '4px',
  overflow: 'hidden',
  width: '560px',
  boxShadow: '0 1px 8px rgba(0, 0, 0, 0.1)'
})

const SlackHeader = styled(Box)({
  backgroundColor: '#350d36',
  color: 'white',
  padding: '10px 15px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
})


