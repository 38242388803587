import React from 'react'
import EmptyOverview from './EmptyOverview'
import OverviewList from './OverviewList'
import { Box } from '@mui/material'
import Dashboard from './Dashboard'

export default function Overview({ data }) {
  if (!data?.steps.length) {
    return <EmptyOverview status={data?.status} />
  }
  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={'32px'}>
      <Dashboard />
      <OverviewList data={data} />
    </Box>
  )
}
