import React, { useEffect, useState } from 'react'
import userService from 'src/services/user.service'

export function ManagerById({ managerUserId }) {
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    if (managerUserId) {
      userService.getUser(managerUserId).then(resp => {
        setSelectedUser(resp.data)
      })
    }

  }, [managerUserId])

  return (
    <>
      {selectedUser
        ? <span> {(selectedUser?.firstName || 'unknown')} {(selectedUser?.lastName || 'unknown')} </span>
        : <span>-</span>
      }
    </>
  )
}
