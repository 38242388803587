import React from 'react'
import { ReactComponent as SMS } from '../../../../common/icons/SMSIcon.svg'
import { ReactComponent as WhatsApp } from '../../../../common/icons/whatsUpIcon.svg'
import { styled } from '@mui/material/styles'
import { Box, Paper, Typography } from '@mui/material'

export default function SmsWhatsAppPreview({ type, metadata }) {
  return (
    <PhoneFrame>
      <PhoneHeader messageType={type}>
        {type === 'SMS' ? <SMS style={{ width: 20, height: 20 }} /> : <WhatsApp style={{ width: 20, height: 20 }} />}
        <Typography>{metadata?.phoneNumber || (type === 'SMS' ? 'SMS' : 'WhatsApp')}</Typography>
      </PhoneHeader>
      <Box
        sx={{
          padding: '16px',
          backgroundColor: type === 'WHATSAPP' ? '#e5ddd5' : '#ffffff',
          backgroundImage:
            type === 'WHATSAPP'
              ? 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABlBMVEUAAAD///+l2Z/dAAAAAnRSTlMAvy1NYyAAAAA9SURBVCjPdcexDcAgDEVRmID9B8aKI0WKRLlnsKRc/ASo0N7U6bpkdTua7bFDKXj0Qwwxo+hGcT53keL3AZHzBZfHSVVyAAAAAElFTkSuQmCC")'
              : 'none',
          backgroundRepeat: 'repeat',
          height: '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}
      >
        <MessageBubble messageType={type}>
          <Typography>{metadata?.message}</Typography>
        </MessageBubble>
      </Box>
    </PhoneFrame>
  )
}

const MessageBubble = styled(Paper)(({ theme, messageType }) => ({
  padding: '10px 14px',
  borderRadius: messageType === 'WHATSAPP' ? '8px 8px 0 8px' : '16px',
  maxWidth: '85%',
  width: 'fit-content',
  marginBottom: '8px',
  boxShadow: messageType === 'WHATSAPP' ? '0 1px 0.5px rgba(0, 0, 0, 0.13)' : 'none',
  backgroundColor: messageType === 'WHATSAPP' ? '#e7ffdb' : '#f5f5f5',

  '& a': {
    color: messageType === 'SLACK' ? '#0576b9' : '#0366d6',
    textDecoration: 'none'
  },

  '& code.slack-code': {
    backgroundColor: '#f7f7f9',
    padding: '2px 4px',
    borderRadius: '3px',
    fontFamily: 'monospace'
  },

  '& pre.slack-pre': {
    backgroundColor: '#f7f7f9',
    padding: '8px',
    borderRadius: '3px',
    overflowX: 'auto',
    fontFamily: 'monospace',
    margin: '4px 0'
  },

  '& blockquote.slack-quote': {
    borderLeft: '4px solid #ddd',
    paddingLeft: '8px',
    color: '#555',
    margin: '4px 0'
  },

  '& li': {
    marginLeft: '20px'
  }
}))

const PhoneHeader = styled(Box)(({ theme, messageType }) => ({
  backgroundColor: messageType === 'WHATSAPP' ? '#075e54' : '#f7f7f7',
  color: messageType === 'WHATSAPP' ? 'white' : '#444',
  padding: '10px 15px',
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
}))

const PhoneFrame = styled(Box)({
  border: '1px solid #ddd',
  borderRadius: '12px',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '360px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
})
