import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import AddStepButton from '../AddStepButton'

export default function EmptyOverview({ status }) {
  return (
    <Container
      maxWidth={'md'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <Box sx={{}} width={'100%'} display={'flex'} flexDirection={'column'} rowGap={1} pb={7} alignItems={'center'}>
        <Typography
          sx={{ fontSize: '1.3rem', opacity: 0.7 }}
          variant='body1'
          fontWeight={700}
          fontFamily={'Plus Jakarta Sans'}
        >
          Elevate your security with phishing simulation campaigns
        </Typography>
        <Typography
          sx={{ opacity: 0.7 }}
          textAlign={'center'}
          variant='body1'
          fontWeight={500}
          mb={4}
          fontFamily={'Plus Jakarta Sans'}
        >
          Harness our Phishing AI for detailed simulation campaigns that boost team vigilance, assess security, and
          strengthen defenses against cyber threats
        </Typography>
        {status !== 'ARCHIVED' && (
          <AddStepButton
            sx={{
              background: '#6123FF',
              borderRadius: '12px',
              fontFamily: 'Plus Jakarta Sans',
              color: '#fff',
              textTransform: 'none',
              border: 'none',
              width: 260,
              mb: 5
            }}
            size={'large'}
            variant={'contained'}
          />
        )}
      </Box>
    </Container>
  )
}
