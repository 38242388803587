import React from 'react'
import StatusChip from 'src/common/components/StatusChip'
import Highlighter from 'react-highlight-words'
import { FailRateChip } from './components/failRateChip'
import { StarsChip } from './components/starsChip'
import UserAvatar from 'src/common/components/UserAvatar'
import { Tooltip } from '@mui/material'

export const columns = countries => {
  return [
    {
      headerName: 'User',
      width: 300,
      total: true,
      field: 'name',
      sortField: 'firstName',
      renderCell: ({ row, action, search }) => (
        <div className='row' style={{ alignItems: 'center' }}>
          <div
            style={{
              width: '40px',
              height: '40px',
              display: 'flex',
              borderRadius: '20px',
              overflow: 'hidden',
              backgroundColor: '#F4F5F9',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0
            }}
          >
            {row.img ? (
              <img width={'100%'} src={row.img} alt='' />
            ) : (
              <UserAvatar id={row.id} style={{ marginRight: 0 }} firstName={row.firstName} lastName={row.lastName} />
            )}
          </div>
          <div onClick={() => action(row.id)} style={{ marginLeft: '20px', cursor: 'pointer' }} className='column'>
            <p
              style={{
                fontWeight: '600',
                fontSize: '16px',
                marginBottom: '8px'
              }}
            >
              <Highlighter
                highlightClassName='highlight-text'
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={(row.firstName || 'unknown') + ' ' + (row.lastName || 'unknown')}
              />
            </p>
            <Tooltip arrow title={row.email}>
              <p style={{ fontSize: '13px', color: '#76767F' }} className='text-shrink-1'>
                <Highlighter
                  highlightClassName='highlight-text'
                  searchWords={[search]}
                  autoEscape={true}
                  textToHighlight={row.email || ''}
                />
              </p>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 230,
      editable: true,
      renderCell: ({ row }) => <StatusChip style={{ padding: 0 }} value={row.status} />,
      filterOptions: ['Active', 'Inactive']
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 110,
      editable: true,
      filterOptions: countries || []
    },
    {
      field: 'department',
      headerName: 'Department',
      renderCell: ({ row, search }) => (
        <>
          {/* {search && row.department.includes(search.toLowerCase())
        ? highlightSearch(row.department, search.toLowerCase())
        : row.department} */}
          <Highlighter
            highlightClassName='highlight-text'
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={row.department || ''}
          />
        </>
      ),
      width: 200,
      editable: true,
      filterOptions: [
        'Administration',
        'Business Development',
        'Customer Service',
        'Finance',
        'Human Resources (HR)',
        'Information Technology (IT)',
        'Legal',
        'Marketing',
        'Operations',
        'Sales'
      ]
    },
    {
      headerName: 'Stars',
      field: 'stars',
      width: 110,
      editable: true,
      renderCell: ({ row }) => <StarsChip value={row.stars} />
    },
    {
      headerName: 'Failrate',
      field: 'failRate',
      width: 135,
      editable: true,
      renderCell: ({ row }) => <FailRateChip value={row.failRate} />,
      isLast: true
    }
  ]
}
