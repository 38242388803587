import React from 'react'
import { useParams } from 'react-router-dom'
import CustomizedDialogs from '../../../../common/components/CustomDialog'
import { useGetUserSimulationInfoQuery } from '../../../../api/simulation/simulation'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'
import { Box, Tooltip, Typography } from '@mui/material'
import StatusLabel from './StatusLabel'
import CompromiseRateLabel from './CompromiseRateLabel'
import ActivityList from './ActivityModalCardList'
import EmptyActivityDetails from './EmptyActivityDetails'

export default function ViewActivityModal({ handleClose, open, userId }) {
  const { id } = useParams()

  const { data, isLoading } = useGetUserSimulationInfoQuery({ id, userId })

  return (
    <CustomizedDialogs headerColor={'#fff'} open={open} handleClose={handleClose} title={'User activity details'}>
      <ModalHeader data={data} />
      {isLoading && <LoadingIndicator />}
      {!isLoading && !!data?.activity?.length && (
        <div>
          <ActivityList data={data.activity} />
        </div>
      )}
      {!isLoading && !data?.activity?.length && <EmptyActivityDetails />}
    </CustomizedDialogs>
  )
}

function ModalHeader({ data }) {
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      pt={'8px'}
      pl={'12px'}
      pr={'24px'}
    >
      <Box display={'flex'} flexDirection={'column'} rowGap={'8px'}>
        <Typography fontSize={'18px'} fontWeight={500} fontFamily={'Satoshi'} color={'#1B1B26'}>
          {data?.firstName || 'unknown'} {data?.lastName || 'unknown'}
        </Typography>
        <Typography
          sx={{ maxWidth: 350 }}
          fontSize={'14px'}
          fontWeight={500}
          fontFamily={'Plus Jakarta Sans'}
          color={'#76767F'}
        >
          <Tooltip arrow title={data?.email}>
            <span className='text-shrink-1'>{data?.email}</span>
          </Tooltip>
        </Typography>
      </Box>
      <Box display={'flex'} columnGap={1}>
        <StatusLabel
          status={data?.status}
          stepsCompletedCount={data?.stepsCompletedCount}
          stepsTotalCount={data?.stepsTotalCount}
        />
        <CompromiseRateLabel simulationsFailRate={data?.simulationsFailRate} />
      </Box>
    </Box>
  )
}
