import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { ReactComponent as ArchiveIcon } from '../../../common/icons/archiveBoxIcon.svg'

import customToastWithAlert from '../../../common/components/CustomToastWithAlert'
import { useArchiveSimulationMutation } from '../../../api/simulation/simulation'
import ArchiveDialog from './ArchiveDialog'
import { useParams } from 'react-router-dom'

export default function ArchiveButton({ name, isHidden }) {
  const { id } = useParams()
  const [isShowing, setIsShowing] = useState(false)
  const [archiveSimulation, { isSuccess, isError }] = useArchiveSimulationMutation()
  function handleOpen() {
    setIsShowing(true)
  }
  function handleClose() {
    setIsShowing(false)
  }
  function handleArchive() {
    archiveSimulation(id)
  }
  useEffect(() => {
    if (isSuccess || isError) {
      customToastWithAlert({
        message: isError ? 'Failed to archive simulation campaign' : 'Simulation campaign archived successfully',
        type: isError ? 'error' : 'success'
      })
    }
  }, [isError, isSuccess])
  if (isHidden) {
    return null
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        variant={'outlined'}
        startIcon={<ArchiveIcon />}
        sx={{
          height: '40px',
          borderRadius: '12px',
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          bgcolor: '#fff',
          border: '1px solid #E7E7EE',
          color: '#1B1B26',
          '&:hover': {
            border: 'none',
            bgcolor: '#F0E7F9'
          }
        }}
      >
        Archive
      </Button>
      {isShowing && (
        <ArchiveDialog
          isArchived={isSuccess}
          isNotArchived={isError}
          name={name}
          onArchive={handleArchive}
          onClose={handleClose}
          open={isShowing}
        />
      )}
    </>
  )
}
