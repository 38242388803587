import React from 'react'
import { Box, Paper, Typography, Button } from '@mui/material'

export default function ExitConfirmationDialog({ open, onCancel, onConfirm, values, form }) {
  if (!open) return null

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        borderRadius: '24px'
      }}
    >
      <Paper
        sx={{
          p: 3,
          width: '400px',
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography variant='h6' fontFamily='Satoshi' fontWeight={500}>
          Discard changes?
        </Typography>
        <Typography fontFamily='Plus Jakarta Sans'>
          Are you sure you want to exit? Any unsaved changes will be lost.
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap={1} mt={1}>
          <Button
            onClick={onCancel}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              color: '#6123FF',
              textTransform: 'none',
              border: '1px solid #E7E7E7',
              borderRadius: '12px'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onConfirm(values, form)}
            sx={{
              background: '#6123FF',
              borderRadius: '12px',
              fontFamily: 'Plus Jakarta Sans',
              color: '#fff',
              textTransform: 'none',
              '&:hover': {
                background: '#1565c0'
              }
            }}
          >
            Discard
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}
