import { type } from '@testing-library/user-event/dist/type'
import React from 'react'

export const DocIcon = () => (
  <svg width='20' height='24' viewBox='0 0 201 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.3'>
      <path
        opacity='0.3'
        d='M140.38 49.25C134.913 49.2447 129.671 47.0704 125.805 43.2046C121.939 39.3387 119.765 34.097 119.76 28.6299V1.85854e-06H27.63C24.0953 -0.00131167 20.5949 0.693646 17.3289 2.04541C14.0628 3.39718 11.0952 5.37922 8.59534 7.87818C6.09545 10.3771 4.11238 13.3441 2.7594 16.6096C1.40642 19.8751 0.709961 23.3752 0.709961 26.9099V189.13C0.709961 192.665 1.40642 196.165 2.7594 199.43C4.11238 202.696 6.09545 205.663 8.59534 208.162C11.0952 210.661 14.0628 212.643 17.3289 213.994C20.5949 215.346 24.0953 216.041 27.63 216.04H138.23C141.764 216.04 145.263 215.344 148.528 213.992C151.793 212.639 154.759 210.657 157.258 208.158C159.757 205.659 161.739 202.693 163.092 199.428C164.444 196.163 165.14 192.664 165.14 189.13V49.25H140.38Z'
        fill='#0072FF'
      />
    </g>
    <path
      d='M165.14 49.25H140.38C134.913 49.2447 129.671 47.0704 125.805 43.2046C121.939 39.3387 119.765 34.097 119.76 28.6299V0L165.14 49.25Z'
      fill='#0072FF'
    />
    <path
      d='M131.31 83.73H28.07C27.078 83.73 26.1268 83.3359 25.4254 82.6345C24.724 81.9331 24.33 80.9819 24.33 79.99C24.3287 79.498 24.4245 79.0106 24.6118 78.5557C24.7992 78.1008 25.0745 77.6872 25.4219 77.3389C25.7693 76.9905 26.1821 76.7142 26.6365 76.5256C27.0909 76.3371 27.578 76.24 28.07 76.24H131.31C132.305 76.24 133.258 76.6351 133.962 77.3384C134.665 78.0416 135.06 78.9954 135.06 79.99C135.057 80.9828 134.661 81.9342 133.958 82.6353C133.255 83.3364 132.303 83.73 131.31 83.73Z'
      fill='#0072FF'
    />
    <path
      d='M131.31 106.1H28.07C27.078 106.1 26.1268 105.706 25.4254 105.005C24.724 104.303 24.33 103.352 24.33 102.36C24.3287 101.868 24.4245 101.381 24.6118 100.926C24.7992 100.471 25.0745 100.057 25.4219 99.709C25.7693 99.3606 26.1821 99.0843 26.6365 98.8958C27.0909 98.7072 27.578 98.6101 28.07 98.6101H131.31C132.305 98.6101 133.258 99.005 133.962 99.7083C134.665 100.412 135.06 101.366 135.06 102.36C135.057 103.353 134.661 104.304 133.958 105.005C133.255 105.706 132.303 106.1 131.31 106.1Z'
      fill='#0072FF'
    />
    <path
      d='M131.31 128.47H28.07C27.578 128.47 27.0909 128.373 26.6365 128.184C26.1821 127.996 25.7693 127.719 25.4219 127.371C25.0745 127.023 24.7992 126.609 24.6118 126.154C24.4245 125.699 24.3287 125.212 24.33 124.72C24.33 123.728 24.724 122.777 25.4254 122.075C26.1268 121.374 27.078 120.98 28.07 120.98H131.31C132.303 120.98 133.255 121.374 133.958 122.075C134.661 122.776 135.057 123.727 135.06 124.72C135.06 125.715 134.665 126.668 133.962 127.372C133.258 128.075 132.305 128.47 131.31 128.47Z'
      fill='#0072FF'
    />
    <path
      d='M90.59 150.84H28.07C27.578 150.84 27.0909 150.743 26.6365 150.554C26.1821 150.366 25.7693 150.089 25.4219 149.741C25.0745 149.393 24.7992 148.979 24.6118 148.524C24.4245 148.07 24.3287 147.582 24.33 147.09C24.33 146.098 24.724 145.147 25.4254 144.445C26.1268 143.744 27.078 143.35 28.07 143.35H90.59C91.5819 143.35 92.5332 143.744 93.2346 144.445C93.936 145.147 94.33 146.098 94.33 147.09C94.3313 147.582 94.2356 148.07 94.0482 148.524C93.8609 148.979 93.5856 149.393 93.2382 149.741C92.8908 150.089 92.478 150.366 92.0236 150.554C91.5692 150.743 91.082 150.84 90.59 150.84Z'
      fill='#0072FF'
    />
    <path
      d='M184.66 170.61H59.62C50.7503 170.61 43.5601 177.8 43.5601 186.67V223.95C43.5601 232.82 50.7503 240.01 59.62 240.01H184.66C193.53 240.01 200.72 232.82 200.72 223.95V186.67C200.72 177.8 193.53 170.61 184.66 170.61Z'
      fill='#0072FF'
    />
    <path d='M76.9301 213.38V220.65H69.4V213.38H76.9301Z' fill='white' />
    <path
      d='M105.87 213.4C104.618 215.674 102.727 217.53 100.43 218.74C97.8455 220.062 94.9721 220.718 92.0699 220.65H80.71V190.58H92.0699C94.9742 190.508 97.8517 191.15 100.45 192.45C102.737 193.638 104.623 195.474 105.87 197.73C107.163 200.13 107.814 202.824 107.76 205.55C107.811 208.285 107.161 210.988 105.87 213.4ZM98.05 211.89C98.863 211.056 99.4927 210.061 99.899 208.969C100.305 207.877 100.48 206.713 100.41 205.55C100.48 204.389 100.306 203.225 99.8997 202.135C99.4932 201.045 98.8632 200.052 98.05 199.22C96.2281 197.623 93.8485 196.81 91.4301 196.96H88V214.14H91.4399C93.853 214.286 96.2269 213.478 98.05 211.89Z'
      fill='white'
    />
    <path
      d='M133.9 192.09C136.188 193.389 138.079 195.287 139.37 197.58C140.723 200.001 141.413 202.737 141.37 205.51C141.416 208.29 140.726 211.033 139.37 213.46C138.075 215.761 136.177 217.666 133.88 218.97C131.542 220.305 128.892 220.995 126.2 220.97C123.508 220.994 120.858 220.304 118.52 218.97C116.224 217.669 114.329 215.763 113.04 213.46C111.68 211.034 110.989 208.29 111.04 205.51C110.988 202.736 111.679 199.998 113.04 197.58C114.335 195.287 116.229 193.389 118.52 192.09C120.858 190.756 123.508 190.066 126.2 190.09C128.898 190.065 131.555 190.755 133.9 192.09ZM120.5 199.19C119.025 200.955 118.274 203.213 118.4 205.51C118.274 207.797 119.025 210.047 120.5 211.8C121.219 212.581 122.099 213.195 123.081 213.599C124.062 214.004 125.12 214.188 126.18 214.14C127.237 214.185 128.291 213.999 129.268 213.595C130.246 213.191 131.124 212.578 131.84 211.8C133.322 210.05 134.081 207.8 133.96 205.51C134.08 203.223 133.33 200.976 131.86 199.22C131.142 198.438 130.261 197.824 129.28 197.42C128.298 197.015 127.24 196.831 126.18 196.88C125.123 196.831 124.067 197.013 123.086 197.412C122.106 197.81 121.223 198.417 120.5 199.19Z'
      fill='white'
    />
    <path
      d='M168.69 193.3C171.226 195.364 172.951 198.257 173.56 201.47H165.86C165.34 200.139 164.431 198.995 163.25 198.19C162.005 197.38 160.545 196.965 159.06 197C158.08 196.969 157.105 197.163 156.212 197.569C155.319 197.975 154.531 198.581 153.91 199.34C152.517 201.121 151.808 203.342 151.91 205.6C151.808 207.849 152.518 210.06 153.91 211.83C154.536 212.581 155.326 213.18 156.218 213.58C157.11 213.981 158.082 214.172 159.06 214.14C160.545 214.18 162.007 213.764 163.25 212.95C164.424 212.159 165.333 211.034 165.86 209.72H173.56C172.941 212.923 171.218 215.808 168.69 217.87C165.97 219.947 162.609 221.008 159.19 220.87C156.496 220.927 153.836 220.257 151.49 218.93C149.295 217.654 147.514 215.772 146.36 213.51C145.12 211.029 144.503 208.283 144.56 205.51C144.504 202.737 145.121 199.992 146.36 197.51C147.514 195.248 149.295 193.366 151.49 192.09C153.836 190.763 156.496 190.093 159.19 190.15C162.627 190.059 165.988 191.173 168.69 193.3Z'
      fill='white'
    />
  </svg>
)
export const DocxIcon = () => (
  <svg width='20' height='24' viewBox='0 0 201 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.3'>
      <path
        opacity='0.3'
        d='M139.81 49.25C134.343 49.2447 129.101 47.0704 125.235 43.2046C121.369 39.3387 119.195 34.097 119.19 28.6299V1.85854e-06H27.0601C23.5253 -0.00131167 20.0249 0.693646 16.7589 2.04541C13.4929 3.39718 10.5252 5.37922 8.02527 7.87818C5.52538 10.3771 3.54231 13.3441 2.18933 16.6096C0.836352 19.8751 0.140014 23.3752 0.140015 26.9099V189.13C0.140014 192.665 0.836352 196.165 2.18933 199.43C3.54231 202.696 5.52538 205.663 8.02527 208.162C10.5252 210.661 13.4929 212.643 16.7589 213.994C20.0249 215.346 23.5253 216.041 27.0601 216.04H137.66C144.797 216.04 151.642 213.205 156.688 208.158C161.735 203.112 164.57 196.267 164.57 189.13V49.25H139.81Z'
        fill='#0072FF'
      />
    </g>
    <path
      d='M164.57 49.25H139.81C134.343 49.2447 129.101 47.0704 125.235 43.2046C121.369 39.3387 119.195 34.097 119.19 28.6299V0L164.57 49.25Z'
      fill='#0072FF'
    />
    <path
      d='M130.74 83.73H27.5C26.5081 83.73 25.5569 83.3359 24.8555 82.6345C24.1541 81.9331 23.76 80.9819 23.76 79.99C23.7587 79.498 23.8545 79.0106 24.0419 78.5557C24.2292 78.1008 24.5045 77.6872 24.8519 77.3389C25.1994 76.9905 25.612 76.7142 26.0664 76.5256C26.5208 76.3371 27.008 76.24 27.5 76.24H130.74C131.735 76.24 132.688 76.6351 133.392 77.3384C134.095 78.0416 134.49 78.9954 134.49 79.99C134.487 80.9828 134.091 81.9342 133.388 82.6353C132.685 83.3364 131.733 83.73 130.74 83.73Z'
      fill='#0072FF'
    />
    <path
      d='M130.74 106.1H27.5C26.5081 106.1 25.5569 105.706 24.8555 105.005C24.1541 104.303 23.76 103.352 23.76 102.36C23.7587 101.868 23.8545 101.381 24.0419 100.926C24.2292 100.471 24.5045 100.057 24.8519 99.709C25.1994 99.3606 25.612 99.0843 26.0664 98.8958C26.5208 98.7072 27.008 98.6101 27.5 98.6101H130.74C131.735 98.6101 132.688 99.005 133.392 99.7083C134.095 100.412 134.49 101.366 134.49 102.36C134.487 103.353 134.091 104.304 133.388 105.005C132.685 105.706 131.733 106.1 130.74 106.1Z'
      fill='#0072FF'
    />
    <path
      d='M130.74 128.47H27.5C27.008 128.47 26.5208 128.373 26.0664 128.184C25.612 127.996 25.1994 127.719 24.8519 127.371C24.5045 127.023 24.2292 126.609 24.0419 126.154C23.8545 125.699 23.7587 125.212 23.76 124.72C23.76 123.728 24.1541 122.777 24.8555 122.075C25.5569 121.374 26.5081 120.98 27.5 120.98H130.74C131.733 120.98 132.685 121.374 133.388 122.075C134.091 122.776 134.487 123.727 134.49 124.72C134.49 125.715 134.095 126.668 133.392 127.372C132.688 128.075 131.735 128.47 130.74 128.47Z'
      fill='#0072FF'
    />
    <path
      d='M90 150.84H27.5C27.008 150.84 26.5208 150.743 26.0664 150.554C25.612 150.366 25.1994 150.089 24.8519 149.741C24.5045 149.393 24.2292 148.979 24.0419 148.524C23.8545 148.07 23.7587 147.582 23.76 147.09C23.76 146.098 24.1541 145.147 24.8555 144.445C25.5569 143.744 26.5081 143.35 27.5 143.35H90C90.9919 143.35 91.9432 143.744 92.6445 144.445C93.3459 145.147 93.74 146.098 93.74 147.09C93.7413 147.582 93.6455 148.07 93.4581 148.524C93.2708 148.979 92.9955 149.393 92.6481 149.741C92.3007 150.089 91.888 150.366 91.4336 150.554C90.9792 150.743 90.492 150.84 90 150.84Z'
      fill='#0072FF'
    />
    <path
      d='M184.09 170.61H59.05C50.1803 170.61 42.99 177.8 42.99 186.67V223.95C42.99 232.82 50.1803 240.01 59.05 240.01H184.09C192.96 240.01 200.15 232.82 200.15 223.95V186.67C200.15 177.8 192.96 170.61 184.09 170.61Z'
      fill='#0072FF'
    />
    <path d='M61.3199 213.38V220.65H53.79V213.38H61.3199Z' fill='white' />
    <path
      d='M90.26 213.4C89.0051 215.671 87.1144 217.527 84.8199 218.74C82.2354 220.062 79.3621 220.718 76.46 220.65H65.1V190.58H76.46C79.3641 190.509 82.2413 191.151 84.84 192.45C87.1274 193.637 89.013 195.474 90.26 197.73C91.5529 200.13 92.204 202.824 92.15 205.55C92.2015 208.285 91.5508 210.988 90.26 213.4ZM82.4399 211.89C83.2528 211.055 83.8827 210.061 84.2891 208.969C84.6954 207.877 84.8695 206.713 84.8 205.55C84.87 204.389 84.6961 203.225 84.2897 202.135C83.8832 201.045 83.2531 200.052 82.4399 199.22C80.618 197.622 78.2384 196.81 75.8199 196.96H72.38V214.14H75.8199C78.2364 214.288 80.6143 213.48 82.4399 211.89Z'
      fill='white'
    />
    <path
      d='M118.29 192.09C120.578 193.389 122.469 195.287 123.76 197.58C125.113 200.001 125.803 202.737 125.76 205.51C125.811 208.29 125.12 211.034 123.76 213.46C122.472 215.763 120.576 217.669 118.28 218.97C115.942 220.305 113.292 220.995 110.6 220.97C107.908 220.995 105.258 220.305 102.92 218.97C100.624 217.669 98.7284 215.763 97.44 213.46C96.0797 211.034 95.3894 208.29 95.44 205.51C95.3881 202.736 96.0786 199.998 97.44 197.58C98.735 195.287 100.629 193.389 102.92 192.09C105.258 190.755 107.908 190.065 110.6 190.09C113.295 190.066 115.948 190.756 118.29 192.09ZM104.89 199.19C103.415 200.955 102.664 203.213 102.79 205.51C102.664 207.797 103.415 210.047 104.89 211.8C105.609 212.581 106.489 213.195 107.471 213.599C108.452 214.004 109.51 214.188 110.57 214.14C111.627 214.184 112.68 213.997 113.658 213.593C114.635 213.189 115.513 212.577 116.23 211.8C117.712 210.05 118.471 207.8 118.35 205.51C118.47 203.223 117.72 200.976 116.25 199.22C115.532 198.438 114.651 197.824 113.67 197.42C112.688 197.015 111.63 196.831 110.57 196.88C109.512 196.831 108.457 197.013 107.476 197.412C106.496 197.81 105.613 198.417 104.89 199.19Z'
      fill='white'
    />
    <path
      d='M153.08 193.3C155.616 195.364 157.341 198.257 157.95 201.47H150.25C149.731 200.139 148.821 198.995 147.64 198.19C146.395 197.38 144.935 196.965 143.45 197C142.47 196.969 141.495 197.163 140.602 197.569C139.709 197.975 138.921 198.581 138.3 199.34C136.908 201.122 136.199 203.342 136.3 205.6C136.198 207.849 136.908 210.06 138.3 211.83C138.926 212.581 139.716 213.18 140.608 213.58C141.501 213.981 142.472 214.172 143.45 214.14C144.935 214.18 146.397 213.764 147.64 212.95C148.814 212.159 149.724 211.034 150.25 209.72H157.95C157.331 212.923 155.608 215.808 153.08 217.87C150.36 219.947 147 221.008 143.58 220.87C140.886 220.927 138.226 220.257 135.88 218.93C133.685 217.654 131.904 215.772 130.75 213.51C129.507 211.029 128.886 208.284 128.94 205.51C128.887 202.736 129.508 199.991 130.75 197.51C131.904 195.248 133.685 193.366 135.88 192.09C138.226 190.763 140.886 190.093 143.58 190.15C147.017 190.059 150.378 191.173 153.08 193.3Z'
      fill='white'
    />
    <path
      d='M180.28 220.65L173.94 211.29L168.5 220.65H160.16L169.78 205.17L159.87 190.58H168.5L174.67 199.64L179.98 190.58H188.28L178.79 205.72L188.91 220.65H180.28Z'
      fill='white'
    />
  </svg>
)
export const PdfIcon = () => (
  <svg width='20' height='24' viewBox='0 0 201 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.3'>
      <path
        opacity='0.3'
        d='M140.24 49.25C134.773 49.2447 129.531 47.0704 125.665 43.2046C121.799 39.3387 119.625 34.097 119.62 28.6299V0H27.48C20.343 0 13.4984 2.83523 8.45178 7.88184C3.40518 12.9284 0.570007 19.7729 0.570007 26.9099V189.13C0.580606 196.26 3.42043 203.094 8.46588 208.132C13.5113 213.17 20.3499 216 27.48 216H138.09C145.22 216 152.059 213.17 157.104 208.132C162.15 203.094 164.989 196.26 165 189.13V49.25H140.24Z'
        fill='#FF3E4C'
      />
    </g>
    <path
      d='M165 49.25H140.24C134.773 49.2447 129.531 47.0704 125.665 43.2046C121.799 39.3387 119.625 34.097 119.62 28.6299V0L165 49.25Z'
      fill='#FF3E4C'
    />
    <path
      d='M131.17 150.84H27.93C26.9354 150.84 25.9816 150.445 25.2783 149.742C24.5751 149.038 24.18 148.085 24.18 147.09C24.1826 146.097 24.5789 145.146 25.2819 144.445C25.9848 143.744 26.9372 143.35 27.93 143.35H131.17C132.162 143.35 133.113 143.744 133.815 144.445C134.516 145.147 134.91 146.098 134.91 147.09C134.911 147.582 134.816 148.07 134.628 148.524C134.441 148.979 134.166 149.393 133.818 149.741C133.471 150.089 133.058 150.366 132.604 150.554C132.149 150.743 131.662 150.84 131.17 150.84Z'
      fill='#FF3E4C'
    />
    <path
      d='M131.17 108.6H27.93C26.9372 108.6 25.9848 108.206 25.2819 107.505C24.5789 106.804 24.1826 105.853 24.18 104.86C24.18 103.866 24.5751 102.912 25.2783 102.208C25.9816 101.505 26.9354 101.11 27.93 101.11H131.17C131.662 101.11 132.149 101.207 132.604 101.396C133.058 101.584 133.471 101.861 133.818 102.209C134.166 102.557 134.441 102.971 134.628 103.426C134.816 103.881 134.911 104.368 134.91 104.86C134.91 105.852 134.516 106.803 133.815 107.505C133.113 108.206 132.162 108.6 131.17 108.6Z'
      fill='#FF3E4C'
    />
    <path
      d='M131.17 129.72H27.93C26.9354 129.72 25.9816 129.325 25.2783 128.622C24.5751 127.918 24.18 126.965 24.18 125.97C24.1826 124.977 24.5789 124.026 25.2819 123.325C25.9848 122.624 26.9372 122.23 27.93 122.23H131.17C132.162 122.23 133.113 122.624 133.815 123.325C134.516 124.027 134.91 124.978 134.91 125.97C134.911 126.462 134.816 126.949 134.628 127.404C134.441 127.859 134.166 128.273 133.818 128.621C133.471 128.969 133.058 129.246 132.604 129.434C132.149 129.623 131.662 129.72 131.17 129.72Z'
      fill='#FF3E4C'
    />
    <path
      d='M90.45 87.48H27.93C26.9372 87.48 25.9848 87.0864 25.2819 86.3853C24.5789 85.6842 24.1826 84.7328 24.18 83.74C24.18 82.7454 24.5751 81.7916 25.2783 81.0884C25.9816 80.3851 26.9354 79.99 27.93 79.99H90.45C91.4428 79.9926 92.3941 80.3888 93.0952 81.0918C93.7963 81.7948 94.19 82.7472 94.19 83.74C94.1874 84.7311 93.7925 85.6808 93.0917 86.3816C92.3909 87.0824 91.4411 87.4773 90.45 87.48Z'
      fill='#FF3E4C'
    />
    <path
      d='M90.45 66.3601H27.93C26.9372 66.3601 25.9848 65.9662 25.2819 65.2651C24.5789 64.564 24.1826 63.6129 24.18 62.6201C24.18 61.6256 24.5751 60.6715 25.2783 59.9683C25.9816 59.265 26.9354 58.8701 27.93 58.8701H90.45C91.4428 58.8728 92.3941 59.2689 93.0952 59.9719C93.7963 60.6749 94.19 61.6273 94.19 62.6201C94.1874 63.6112 93.7925 64.5609 93.0917 65.2617C92.3909 65.9625 91.4411 66.3575 90.45 66.3601Z'
      fill='#FF3E4C'
    />
    <path
      d='M184.51 170.61H59.47C50.6003 170.61 43.41 177.8 43.41 186.67V223.95C43.41 232.82 50.6003 240.01 59.47 240.01H184.51C193.38 240.01 200.57 232.82 200.57 223.95V186.67C200.57 177.8 193.38 170.61 184.51 170.61Z'
      fill='#FF3E4C'
    />
    <path d='M84.43 213.38V220.65H76.91V213.38H84.43Z' fill='white' />
    <path
      d='M95.49 210.1V220.65H88.22V190.58H100C103.57 190.58 106.3 191.47 108.17 193.24C109.116 194.17 109.855 195.289 110.338 196.525C110.82 197.761 111.036 199.085 110.97 200.41C111.006 202.158 110.575 203.884 109.72 205.41C108.847 206.901 107.553 208.102 106 208.86C104.127 209.745 102.071 210.174 100 210.11L95.49 210.1ZM103.57 200.41C103.57 197.743 102.11 196.41 99.19 196.41H95.49V204.24H99.19C102.11 204.267 103.57 202.99 103.57 200.41Z'
      fill='white'
    />
    <path
      d='M139.7 213.4C138.448 215.674 136.557 217.53 134.26 218.74C131.676 220.063 128.802 220.719 125.9 220.65H114.55V190.58H125.9C128.804 190.507 131.682 191.149 134.28 192.45C136.57 193.634 138.457 195.471 139.7 197.73C141.001 200.128 141.656 202.823 141.6 205.55C141.654 208.287 141 210.991 139.7 213.4ZM131.88 211.89C132.693 211.056 133.323 210.061 133.729 208.969C134.135 207.877 134.309 206.713 134.24 205.55C134.31 204.389 134.136 203.225 133.73 202.135C133.323 201.045 132.693 200.052 131.88 199.22C130.061 197.624 127.685 196.812 125.27 196.96H121.82V214.14H125.27C127.683 214.287 130.057 213.478 131.88 211.89Z'
      fill='white'
    />
    <path d='M165.33 190.58V196.37H153V202.96H162.52V208.49H153V220.65H145.72V190.58H165.33Z' fill='white' />
    <path
      d='M129.34 58.87H111.66C108.578 58.87 106.08 61.3682 106.08 64.45V82.13C106.08 85.2117 108.578 87.71 111.66 87.71H129.34C132.422 87.71 134.92 85.2117 134.92 82.13V64.45C134.92 61.3682 132.422 58.87 129.34 58.87Z'
      fill='#FF3E4C'
    />
  </svg>
)
export const TxtIcon = () => (
  <svg width='20' height='24' viewBox='0 0 201 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.3'>
      <path
        opacity='0.3'
        d='M140.53 49.25C135.062 49.2447 129.819 47.0709 125.952 43.2053C122.084 39.3398 119.908 34.0979 119.9 28.6299V0H27.77C20.633 0 13.7883 2.83523 8.7417 7.88184C3.6951 12.9284 0.860107 19.7729 0.860107 26.9099V189.13C0.860107 196.267 3.6951 203.112 8.7417 208.158C13.7883 213.205 20.633 216.04 27.77 216.04H138.37C145.507 216.04 152.352 213.205 157.398 208.158C162.445 203.112 165.28 196.267 165.28 189.13V49.25H140.53Z'
        fill='#0072FF'
      />
    </g>
    <path
      d='M165.28 49.25H140.53C135.062 49.2447 129.819 47.0709 125.952 43.2053C122.084 39.3398 119.908 34.0979 119.9 28.6299V0L165.28 49.25Z'
      fill='#0072FF'
    />
    <path
      d='M131.46 83.73H28.21C27.2189 83.7273 26.2692 83.3324 25.5684 82.6316C24.8675 81.9308 24.4726 80.9811 24.47 79.99C24.47 78.9972 24.8636 78.0448 25.5647 77.3418C26.2658 76.6388 27.2171 76.2426 28.21 76.24H131.46C132.453 76.2426 133.404 76.6388 134.105 77.3418C134.806 78.0448 135.2 78.9972 135.2 79.99C135.197 80.9811 134.802 81.9308 134.102 82.6316C133.401 83.3324 132.451 83.7273 131.46 83.73Z'
      fill='#0072FF'
    />
    <path
      d='M131.46 106.1H28.21C27.2189 106.097 26.2692 105.703 25.5684 105.002C24.8675 104.301 24.4726 103.351 24.47 102.36C24.47 101.367 24.8636 100.415 25.5647 99.7119C26.2658 99.0089 27.2171 98.6128 28.21 98.6101H131.46C132.453 98.6128 133.404 99.0089 134.105 99.7119C134.806 100.415 135.2 101.367 135.2 102.36C135.197 103.351 134.802 104.301 134.102 105.002C133.401 105.703 132.451 106.097 131.46 106.1Z'
      fill='#0072FF'
    />
    <path
      d='M131.46 128.47H28.21C27.2171 128.467 26.2658 128.071 25.5647 127.368C24.8636 126.665 24.47 125.713 24.47 124.72C24.4726 123.729 24.8675 122.779 25.5684 122.078C26.2692 121.378 27.2189 120.983 28.21 120.98H131.46C132.451 120.983 133.401 121.378 134.102 122.078C134.802 122.779 135.197 123.729 135.2 124.72C135.2 125.713 134.806 126.665 134.105 127.368C133.404 128.071 132.453 128.467 131.46 128.47Z'
      fill='#0072FF'
    />
    <path
      d='M131.46 150.83H28.21C27.2181 150.83 26.2668 150.436 25.5654 149.735C24.864 149.033 24.47 148.082 24.47 147.09C24.47 146.097 24.8636 145.145 25.5647 144.442C26.2658 143.739 27.2171 143.343 28.21 143.34H131.46C132.453 143.343 133.404 143.739 134.105 144.442C134.806 145.145 135.2 146.097 135.2 147.09C135.2 148.082 134.806 149.033 134.104 149.735C133.403 150.436 132.452 150.83 131.46 150.83Z'
      fill='#0072FF'
    />
    <path
      d='M184.8 170.61H59.76C50.8903 170.61 43.7 177.8 43.7 186.67V223.95C43.7 232.82 50.8903 240.01 59.76 240.01H184.8C193.67 240.01 200.86 232.82 200.86 223.95V186.67C200.86 177.8 193.67 170.61 184.8 170.61Z'
      fill='#0072FF'
    />
    <path d='M86.4199 213.38V220.65H78.8899V213.38H86.4199Z' fill='white' />
    <path d='M111.81 190.58V196.37H103.64V220.65H96.3301V196.37H88.24V190.58H111.81Z' fill='white' />
    <path
      d='M133.12 220.65L126.78 211.29L121.33 220.65H113L122.61 205.17L112.7 190.58H121.33L127.5 199.64L132.82 190.58H141.11L131.63 205.72L141.75 220.65H133.12Z'
      fill='white'
    />
    <path d='M166.16 190.58V196.37H158V220.65H150.68V196.37H142.6V190.58H166.16Z' fill='white' />
  </svg>
)

export const FileIcon = ({ type }) => {
  switch (type) {
    case 'application/pdf':
      return <PdfIcon />
    case 'application/msword':
      return <DocIcon />
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <DocxIcon />
    case 'text/plain':
      return <TxtIcon />

    default:
      return <></>
  }
}
