import React from 'react'
import { Box, Typography } from '@mui/material'
import { convertDateToUserTimezone, convertToUserTimezone } from '../utils'

export default function DateTimeColumn({ row }) {
  return (
    <Box width={'100%'}>
      <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={600} fontSize={14} color={'#1B1B26'}>
        {row?.sendAt ? convertToUserTimezone(row?.sendAt) : 'Pending'}
      </Typography>
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        {row?.sendAt && (
          <Typography noWrap fontFamily={'Plus Jakarta Sans'} fontWeight={400} fontSize={13} color={'#1B1B26'}>
            {convertDateToUserTimezone(row.sendAt)}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
