import authHeader from './auth-header'
import { compactObject } from 'src/heplers'
import api from './api.service'

const API_URL = window._env_.REACT_APP_API_HOST

class CompanyService {
  getCompany(id) {
    return api.get(API_URL + '/v1/companies/' + id, {
      headers: authHeader()
    })
  }
  getClildCompanies(filters) {
    const params = new URLSearchParams(compactObject(filters))
    return api.get(API_URL + '/v1/companies/child/stats', {
      headers: authHeader(),
      params
    })
  }
  getCompanyConfigs() {
    return api.get(API_URL + '/v1/company-configurations', {
      headers: authHeader()
    })
  }
  getCompanyConfigsSim() {
    return api.get(API_URL + '/v1/company-configurations/simulations', {
      headers: authHeader()
    })
  }
  updateCompanyConfigsSim(state) {
    return api.put(API_URL + '/v1/company-configurations/simulations', state, { headers: authHeader() })
  }
  updateCompanyConfigs(payload) {
    return api.put(API_URL + '/v1/company-configurations', payload, {
      headers: authHeader()
    })
  }

  getCompanyConfigsThreats() {
    return api.get(API_URL + '/v1/company-configurations/threats', {
      headers: authHeader()
    })
  }
  updateCompanyConfigsThreats(email) {
    return api.put(
      API_URL + '/v1/company-configurations/threats',
      { notificationEmails: email },
      { headers: authHeader() }
    )
  }

  getCompanys(filters) {
    const params = new URLSearchParams(compactObject(filters))
    return api.get(API_URL + '/v1/companies', {
      headers: authHeader(),
      params
    })
  }
  delete(id) {
    return api.delete(API_URL + '/v1/companies/' + id, {
      headers: authHeader()
    })
  }
  deleteMany(ids) {
    return api.delete(API_URL + '/v1/companies/', { data: ids }, { headers: authHeader() })
  }
  update(id, payload) {
    return api.put(API_URL + '/v1/companies/current', payload, {
      headers: authHeader()
    })
  }
  updateSelectedServices(id, payload) {
    return api.put(API_URL + '/v1/companies/current/used-products', payload, { headers: authHeader() })
  }
  getLanguages() {
    return api.get(API_URL + '/v1/languages', { headers: authHeader() })
  }
  registerCompany(data) {
    return api.post(API_URL + '/v1/companies/register', data, {})
  }
  validateDomain(email) {
    return api.post(
      API_URL + '/v1/companies/register/validate-domain',
      { email: email, sourceUrl: window.location.hostname },
      {}
    )
  }
}

export default new CompanyService()
