import React from 'react'
import DoneIcon from '../../../common/icons/done'
import SpinnerIcon from '../../../common/icons/spiner'
import Archive from '../../../common/icons/archive'
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence'
import { ReactComponent as PlannedIcon } from '../../../common/icons/hourglassIcon.svg'
import { ReactComponent as AssignIcon } from '../../../common/icons/assignIcon.svg'
import { ReactComponent as ExpiredIcon } from '../../../common/icons/expiredIcon.svg'
import { Box } from '@mui/material'

export default function StatusColumn({ row }) {
  const Icon = () => iconMapper[row.status]
  return (
    <Box display={'flex'} width={'100%'} alignItems={'flex-start'} rowGap={1}>
      <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} rowGap={1}>
        <div
          style={{
            display: 'flex',
            columnGap: 8,
            alignItems: 'flex-start',
            color:
              row.status === 'PASSED'
                ? '#00BA97'
                : row.status === 'IN_PROGRESS'
                  ? '#1B1B26'
                  : row.status === 'EXPIRED'
                    ? '#df4040d9'
                    : '#76767F',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: 14,
            fontWeight: 600
          }}
        >
          <Icon style={{ height: '24px', width: '24px' }} />
          {isUnknownStatus(row.status) ? 'Unknown' : statusNameMapper[row.status]}
        </div>
      </Box>
    </Box>
  )
}

function isUnknownStatus({ status }) {
  return ['FAILED', 'PASSED', 'STARTED', 'PLANNED', 'CREATED', 'EXPIRED'].includes(status)
}

export const statusNameMapper = {
  FAILED: 'Failed',
  PASSED: 'Completed',
  STARTED: 'In Progress',
  PLANNED: 'Planned',
  CREATED: 'Assigned',
  EXPIRED: 'Expired'
}

export const iconMapper = {
  PASSED: <DoneIcon />,
  STARTED: <SpinnerIcon />,
  ARCHIVED: <Archive />,
  PLANNED: <PlannedIcon />,
  CREATED: <AssignIcon />,
  EXPIRED: <ExpiredIcon />,
  FAILED: <DoNotDisturbOnTotalSilenceIcon width={24} />
}
