import React, { useContext, useState } from 'react'
import { Box, Chip, Drawer, Link, Tooltip, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'

import { Letter } from 'react-letter'
import ViewIcon from '../../../../common/icons/viewIcon'
import { CloseDrawer } from '../../../../common/icons/closeDrawer'
import TemplateView from '../../../../views/TemplateView'
import CustomTemplatePreview from '../../../manual-simulation/components/templatePreview'
import EditIcon from '../../../../common/icons/editIcon'
import DeleteIcon from '../../../../common/icons/deleteIcon'
import ActionButtons from '../../ActionButtons'
import { QueryParamsTemplateContext } from './QueryParamsTemplateContext'
import { PageNumberContext } from '../PageNumberContext'
import { useRemoveCustomTemplateMutation } from '../../../../api/simulation/simulation'
import DeleteDialog from '../../../../common/components/DeleteDialog'
import { LinkPageMapper, LoginPageNameMap } from '../TemplateEditor/utils'

export default function TemplateCard({ card, templateList, onChange }) {
  const [isViewShowing, setIsViewShowing] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isDeleteShowing, setIsDeleteShowing] = useState(false)
  const { setPage } = useContext(PageNumberContext)
  const { setSelectedTemplateId, setIsSystemTemplate, setParams } = useContext(QueryParamsTemplateContext)
  const [removeCustomTemplate] = useRemoveCustomTemplateMutation()
  const isChosen = templateList.includes(card.id)

  function handleCardClick() {
    onChange(card.id)
  }

  function handleRemoveTemplate() {
    removeCustomTemplate(card.id)
    setParams(prev => ({ ...prev, deletedId: card.id }))
    setIsDeleteShowing(false)
  }

  function setShowDeleteDialog(data) {
    setIsDeleteShowing(true)
    setSelectedItem(data)
  }
  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          position: 'relative',
          width: 'calc((100% - 48px) / 5)',

          border: '0.36px solid #DBDBE6',
          borderRadius: '14px',
          background: isChosen ? 'linear-gradient(90deg, rgba(0,229,160,0.1) 100%, rgba(2,158,113,0.1) 100%)' : '#fff',
          cursor: 'pointer'
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            width: '100%',
            height: '220px',
            padding: '12px'
          }}
        >
          {isChosen && (
            <Box
              sx={{
                position: 'absolute',
                top: 18,
                right: 35,
                zIndex: 1
              }}
            >
              <IsChosenChip />
            </Box>
          )}
          {
            <Box
              sx={{
                position: 'absolute',
                top: '10px',
                right: 0,
                display: 'flex',
                zIndex: 3,
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <ActionButtons
                config={[
                  {
                    name: 'View',
                    icon: <ViewIcon color={'#76767F'} />,
                    onClick: () => {
                      setIsViewShowing(true)
                    }
                  },
                  {
                    name: 'Edit',
                    icon: <EditIcon color={'#76767F'} />,
                    onClick: () => {
                      setSelectedTemplateId(card.id)
                      card.companyId ? setIsSystemTemplate(true) : setIsSystemTemplate(false)
                      setPage('NEW_EMAIL_TEMPLATE')
                    }
                  },
                  {
                    name: 'Delete',
                    icon: <DeleteIcon color={'#76767F'} />,
                    onClick: () => setShowDeleteDialog(card),
                    isHidden: true
                  }
                ]}
              />
            </Box>
          }

          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={handleCardClick}
            sx={{
              width: '100%',
              height: '250px',
              borderRadius: '12px',
              overflow: 'hidden',
              cursor: 'pointer',
              background: '#fff'
            }}
          >
            <Box
              sx={{
                width: '600px',
                transform: 'scale(0.3)',
                textAlign: 'center'
              }}
            >
              {card?.loginPage && (
                <Box
                  onClick={e => {
                    e.preventDefault()
                  }}
                  display={'flex'}
                  pb={'16px'}
                  width={'100%'}
                  alignItems={'center'}
                >
                  <Link underline='hover' href={LinkPageMapper[card?.loginPage]} target='_blank'>
                    {LoginPageNameMap[card?.loginPage]}
                  </Link>
                </Box>
              )}
              <Letter html={parseEmailBody(card?.body)} allowedSchemas={['http', 'https', 'mailto', 'data']} />
            </Box>
          </Box>
          <Box height={'75px'} pt={'24px'}>
            <Tooltip title={card?.subject}>
              <Typography noWrap fontWeight={500} fontSize={'14px'} fontFamily={'Plus Jakarta Sans'}>
                {card?.subject}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Drawer
        anchor='right'
        open={isViewShowing}
        onClose={() => {
          setIsViewShowing(false)
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '1182px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setIsViewShowing(false)
          }}
        >
          <CloseDrawer />
        </div>
        {!card.companyId && <CustomTemplatePreview id={card.id} />}
        {card.companyId && <TemplateView id={card.id} />}
      </Drawer>
      {isDeleteShowing && (
        <DeleteDialog
          str={`${selectedItem?.subject}`}
          open={isDeleteShowing}
          onAction={handleRemoveTemplate}
          onClose={() => setIsDeleteShowing(false)}
        />
      )}
    </>
  )
}

function IsChosenChip() {
  return (
    <Chip
      icon={<DoneIcon />}
      label='Chosen'
      sx={{
        background: 'linear-gradient(90deg, rgba(0,229,160,1) 50%, rgba(2,158,113,1) 100%)',
        color: '#000000',
        fontSize: '12px',
        height: '22px',
        '& .MuiChip-icon': {
          color: '#000000',
          fontSize: '12px'
        }
      }}
      variant='outlined'
    />
  )
}
const parseEmailBody = body => {
  return body?.replaceAll(
    'alt="image" src="cid:[qrCodeImage]"',
    'width="60px" alt="image" src="https://admin.cyberlift.io/images/qr-in-template.jpg"'
  )
}
