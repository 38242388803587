import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, DialogTitle, } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useContext, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { SnackBarContext } from 'src/App'
import Spacer from 'src/common/components/Spacer'
import { toggleElement } from 'src/heplers'
import settingsService from 'src/services/settings.service'

const ConnectorIcon = ({ type }) => {
  if (type == 'VANTA') {
    return <img style={{ width: 32 }} src="/images/vanta-logo.jpg" alt="" />
  }

}

const connectorsLocal = [
  {
    title: 'Vanta',
    connectorType: 'VANTA',
    availableToUpdate: false,
    helpcenterLink: 'helpcenter.cyberlift.ai/content'
  }
]

export const ComplianceSettings = () => {
  dayjs.extend(utc)
  const [fetchingSync, setFetchingSync] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedConnector, setSelectedConnector] = useState(null)
  const [connectorsSecond, setConnectorsSecond] = useState([])

  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)
  const { query, handleConnectTo } =
    useOutletContext()

  const updateConnectors = () => {
    settingsService.getComplianceConnectors().then(resp => {
      setConnectorsSecond(resp.data)
    }
    ).then().catch(e => {
      console.error(e);
    })
  }

  const onClose = () => {
    setOpen(false)
  }

  const onAction = () => {
    settingsService
      .disconnectCompliance(selectedConnector)
      .then(() => {
        updateConnectors()
        setOpen(false)
        setSuccessMessage('Provider disconnected')
      })
      .catch(err => {
        console.error(err)
        setErrorMessage('Something went wrong')
      })
  }



  useEffect(() => {
    updateConnectors()

    if (query.get('continueConfig') && query.get('selectedConnector')) {
      setSelectedConnector(query.get('selectedConnector'))
    }

    if (query.get('errorSync')) {
      setErrorMessage(query.get('errorSync'))
    }
  }, [])

  const resyncConnector = type => {
    setFetchingSync(toggleElement(fetchingSync, type))
    settingsService
      .syncVanta(type)
      .then(() => {
        setFetchingSync((prev) => toggleElement(prev, type))
        setSuccessMessage('Resync successfully completed')
      })
      .catch(() => {
        setFetchingSync((prev) => toggleElement(prev, type))
        setErrorMessage('Something went wrong')
      })
  }


  return (
    <div className='settings-section'>
      <h2 id='integrations-section'>Compliance Settings</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px' }}>Connect Cyberlift to your external platform</p>
      <div
        style={{
          marginTop: '20px',
          flexWrap: 'wrap',
          width: '100%',
          marginBottom: 20
        }}
        className='row'
      >
        {connectorsLocal?.map(el => {
          return (
            <div
              className='column'
              key={el.connectorType}
              style={{
                alignItems: 'flex-start',
                border:
                  query.get('errorSync') && query.get('errorSyncType') == el.connectorType
                    ? '1px solid #B40020'
                    : '1px solid #E7E7EE',
                padding: '17px 21px',
                width: 322,
                borderRadius: 14,
                marginRight: 14,
                marginBottom: 16,
                height: 180
              }}
            >
              <div className='row row-center' style={{ marginBottom: 7, width: '100%' }}>
                <ConnectorIcon type={el.connectorType} />
                <Spacer />
                {el.helpcenterLink && (
                  <Button
                    onClick={() =>
                      window.open(
                        el.helpcenterLink,
                        '_blank'
                      )
                    }
                    variant='outlined'
                    sx={{
                      padding: '0px 8px',
                      fontSize: '13px',
                      borderRadius: '6px',
                      color: '#6123FF',
                      borderColor: '#6123FF',
                      textTransform: 'none'
                    }}
                  >
                    How to use
                  </Button>
                )}

              </div>

              <p style={{ fontWeight: 600 }}>{el.title}</p>

              {connectorsSecond?.map(connector => connector.provider).includes(el.connectorType) && (
                <>
                  <div
                    style={{
                      color: '#00BC62',
                      marginTop: 10
                    }}
                    key={el.connectorType}
                    className='row row-center'
                  >
                    <p>Synced</p>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M13.5 4.5L6.5 11.4997L3 8'
                        stroke='#00BC62'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                </>
              )}
              {query.get('errorSync') && query.get('errorSyncType') == el.connectorType && (
                <p className='error-text' style={{ marginTop: 20, marginLeft: 0 }}>
                  {query.get('errorSync')}
                </p>
              )}
              <Spacer />
              {connectorsSecond?.map(connector => connector.provider).includes(el.connectorType) ? (
                <div key={el.connectorType + 'btn'} className='row' style={{ width: '100%' }}>
                  <LoadingButton
                    loading={fetchingSync.includes(el.connectorType)}
                    variant={'contained'}
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: '15px',
                      background: '#6123FF',
                      color: '#fff',
                      textTransform: 'none',
                      marginRight: '20px',
                      padding: '0px 10px'
                    }}
                    onClick={() => resyncConnector(el.connectorType)}
                  >
                    Sync Now
                  </LoadingButton>
                  <Button
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: '15px',
                      color: '#6123FF',
                      textTransform: 'none',
                      padding: 0
                    }}
                    onClick={() => {
                      setOpen(true)
                      setSelectedConnector(el.connectorType)
                    }}
                  >
                    Disconnect
                  </Button>
                </div>
              ) : (
                <Button
                  key={el.connectorType + 'btn'}
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '15px',
                    color: '#6123FF',
                    textTransform: 'none',
                    padding: 0
                  }}
                  onClick={() => handleConnectTo(el.connectorType)}
                >
                  Connect
                </Button>
              )}
            </div>
          )
        })}
      </div>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '22px',
            paddingTop: '8px',
            maxWidth: 'unset',
            width: '684px'
          }
        }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Disconnect?
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '80px',
              lineHeight: '22px'
            }}
          >
            Are you sure you want to disconnect this provider?
          </p>
          <div className='row'>
            <Button
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                borderColor: '#D0D0D8',
                color: '#1B1B26'
              }}
              variant={'outlined'}
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
            <div style={{ width: '20px' }}></div>
            <Button
              onClick={onAction}
              sx={{
                padding: '10px 0px',
                fontFamily: 'Plus Jakarta Sans',
                textTransform: 'none',
                borderRadius: '8px',
                background: '#6123FF'
              }}
              variant={'contained'}
              fullWidth
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
