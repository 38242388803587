import authHeader from './auth-header'
import api from './api.service'

const API_URL = window._env_.REACT_APP_API_HOST

class SettingsService {
  getlocations() {
    return api.get(API_URL + '/v1/countries', { headers: authHeader() })
  }
  getlanguages() {
    return api.get(API_URL + '/v1/languages', { headers: authHeader() })
  }
  getdepartments() {
    return api.get(API_URL + '/v1/departments', { headers: authHeader() })
  }

  getConnectors() {
    return api.get(API_URL + '/v1/connectors', { headers: authHeader() })
  }
  getComplianceConnectors() {
    return api.get(API_URL + '/v1/compliance/connectors', { headers: authHeader() })
  }
  disconnect(provider) {
    return api.post(API_URL + '/v1/connectors/' + provider + '/disconnect', {}, { headers: authHeader() })
  }
  disconnectCompliance() {
    return api.post(API_URL + '/v1/compliance/connectors/disconnect', {}, { headers: authHeader() })
  }

  getGoogleAuthUrl() {
    return api.get(API_URL + `/v1/google/authorization-url`, {
      headers: authHeader()
    })
  }
  getSlackAuthUrl() {
    return api.get(API_URL + `/v1/slack/authorization-url?redirectUrl=${window.location.hostname}`, {
      headers: authHeader()
    })
  }
  getTeamsAuthUrl() {
    return api.get(API_URL + `/v1/teams/authorization-url?redirectUrl=${window.location.hostname}`, {
      headers: authHeader()
    })
  }
  getSlackSimAuthUrl() {
    return api.get(API_URL + `/v1/slack/simulation/authorization-url?redirectUrl=${window.location.hostname}`, {
      headers: authHeader()
    })
  }
  getVantaSimAuthUrl() {
    return api.get(API_URL + `/v1/compliance/vanta/authorization-url`, {
      headers: authHeader()
    })
  }
  getGoogleAuthSsoUrl() {
    return api.get(
      API_URL + `/v1/google/sso/authorization-url?redirect=ADMIN&redirectUrl=${window.location.hostname}`,
      { headers: {} }
    )
  }
  getOutlookAuthUrl() {
    return api.get(API_URL + '/v1/outlook/authorization-url', {
      headers: authHeader()
    })
  }
  getOutlookAuthSsoUrl() {
    return api.get(
      API_URL + `/v1/outlook/sso/authorization-url?redirect=ADMIN&redirectUrl=${window.location.hostname}`,
      { headers: {} }
    )
  }
  resyncOutlook() {
    return api.post(API_URL + '/v1/outlook/sync', {}, { headers: authHeader() })
  }
  connectOkta(domain, clientId, secretId, group) {
    return api.post(
      API_URL + `/v1/okta/connect`,
      { clientDomain: domain, clientId, clientSecret: secretId, group },
      { headers: authHeader() }
    )
  }
  connectOktaSso(domain, clientId, secretId) {
    return api.post(
      API_URL + `/v1/okta/sso/connect`,
      { clientDomain: domain, clientId, clientSecret: secretId },
      { headers: authHeader() }
    )
  }
  getOktaAuthUrl(domain, clientId, secretId) {
    return api.get(
      API_URL + `/v1/okta/authorization-url?redirect=ADMIN&domain=${domain}&clientId=${clientId}&secretId=${secretId}`,
      { headers: authHeader() }
    )
  }
  getOktaAuthSsoUrl(email = '') {
    return api.get(
      API_URL + `/v1/okta/sso/authorization-url?redirect=ADMIN&redirectUrl=${window.location.hostname}&email=${email}`,
      { headers: {} }
    )
  }
  getADHealth() {
    return api.get(API_URL + '/v1/ad/health', { headers: authHeader() })
  }
  getADInfo() {
    return api.get(API_URL + '/v1/ad/update-info', { headers: authHeader() })
  }
  getInfo(type) {
    return api.get(API_URL + `/v1/${type.toLowerCase()}/update-info`, { headers: authHeader() })
  }
  getGroups(type) {
    return api.get(API_URL + `/v1/${type.toLowerCase()}/groups`, { headers: authHeader() })
  }
  sync(type) {
    return api.post(API_URL + `/v1/${type.toLowerCase()}/sync`, {}, { headers: authHeader() })
  }
  syncVanta() {
    return api.post(API_URL + `/v1/compliance/vanta/sync`, {}, { headers: authHeader() })
  }
  setSyncUsersConfig(config) {
    return api.post(API_URL + `/v1/connectors/config`, config, { headers: authHeader() })
  }
  getOutlookWorkflowsAuthUrl() {
    return api.get(API_URL + '/v1/microsoft-alerts/authorization', {
      headers: authHeader()
    })
  }

  getOnboardingStatus() {
    return api.get(API_URL + '/v1/company-configurations/self-onboarding', { headers: authHeader() })
  }
  setOnboardingStatus(status) {
    return api.put(API_URL + '/v1/company-configurations/self-onboarding', status, { headers: authHeader() })
  }

  getPluginStatus() {
    return api.get(API_URL + '/v1/company-configurations/plugin', {
      headers: authHeader()
    })
  }
  getAssistantStaus() {
    return api.get(API_URL + '/v1/company-configurations/security-assistant', {
      headers: authHeader()
    })
  }
  getAssistantDocuments() {
    return api.get(API_URL + '/v1/documents/security-assistant', {
      headers: authHeader()
    })
  }
  addAssistantDocument(data) {
    return api.post(API_URL + '/v1/documents/security-assistant', data, {
      'content-type': 'multipart/form-data',
      headers: authHeader()
    })
  }
  deleteAssistantDocument(id) {
    return api.delete(API_URL + '/v1/documents/' + id, {
      headers: authHeader()
    })
  }
  setAssistantStaus(data) {
    return api.put(API_URL + '/v1/company-configurations/security-assistant', data, {
      headers: authHeader()
    })
  }
  setPluginStatus(status) {
    return api.put(
      API_URL + '/v1/company-configurations/plugin',
      { copilotEnabled: status },
      { headers: authHeader() }
    )
  }
  getIntegrations() {
    return api.get(API_URL + '/v1/integrations', {
      headers: authHeader()
    })
  }
  disconnectSlack(type = 'SLACK') {
    return api.delete(API_URL + '/v1/integrations?type=' + type, {
      headers: authHeader()
    })
  }
  getWorkTime() {
    return api.get(API_URL + '/v1/company-configurations/work-time', {
      headers: authHeader()
    })
  }
  setWorkTime(newWt) {
    return api.put(API_URL + '/v1/company-configurations/work-time', newWt, { headers: authHeader() })
  }
  resyncOkta() {
    return api.post(API_URL + '/v1/okta/sync?provider=OKTA', {}, { headers: authHeader() })
  }
  getConnectorsSecond() {
    return api.get(API_URL + '/v1/sso-support', { headers: authHeader() })
  }
  setConnectorsSecond(provider, status) {
    return api.post(API_URL + `/v1/sso-support/${provider}/status?enable=${status}`, {}, { headers: authHeader() })
  }
  getBekConfig() {
    return api.get(API_URL + '/v1/company-configurations/bec', { headers: authHeader() })
  }
  setBekConfig(body) {
    return api.put(API_URL + '/v1/company-configurations/bec', body, { headers: authHeader() })
  }

  getWelcomeEmailSyncStatus() {
    return api.get(API_URL + '/v1/company-configurations/welcome-emails', {
      headers: authHeader()
    })
  }
  setWelcomeEmailSyncStatus(enabled) {
    return api.put(API_URL + '/v1/company-configurations/welcome-emails', {
      shouldSendWelcomeEmail: enabled
    }, { headers: authHeader() })
  }
}

export default new SettingsService()
