import React, { useState } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

export default function ActionButtons({ config, isHidden }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  if (isHidden) return null
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <IconButton disableElevation onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#1B1B26',
            color: '#ffffff',
            width: '200px',
            borderRadius: '12px'
          },
          '& li': {
            opacity: '0.7',
            fontFamily: 'Plus Jakarta Sans',
            paddingLeft: '24px'
          },
          '& li:hover': {
            opacity: '1'
          }
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {config.map((item, index) => {
          if (item?.isHidden) return null
          return (
            <MenuItem
              disabled={item?.disabled}
              key={index}
              onClick={() => {
                item.onClick()
                handleClose()
              }}
            >
              <div
                style={{
                  display: 'flex',
                  columnGap: 8,
                  alignItems: 'center'
                }}
              >
                {' '}
                {item.icon}
                {item.name}
              </div>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}
