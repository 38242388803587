import React from 'react'

export default function PercentageComponent({ percentage }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colorBasedOnPercentage(percentage),
        fontFamily: 'Satoshi',
        fontSize: 18,
        width: 66,
        fontWeight: 500,
        borderRadius: 8,
        padding: '1px 0'
      }}
    >
      {percentage}%
    </div>
  )
}

function colorBasedOnPercentage(percentage) {
  if (percentage >= 0 && percentage <= 10) {
    return '#a8e6a8'
  }
  if (percentage > 10 && percentage <= 30) {
    return '#e6e6a8'
  }
  if (percentage > 30 && percentage <= 50) {
    return '#fffacd'
  }

  if (percentage > 50 && percentage <= 70) {
    return '#ffdab9'
  }

  if (percentage > 70 && percentage <= 100) {
    return '#ffcccb'
  }
}
