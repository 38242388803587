import React, { useContext, useRef, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useLazyGetTemplatesListQuery } from '../../../../api/simulation/simulation'
import TemplateCard from './TemplateCard'
import { useInfiniteScroller } from '../../useInfiniteScroller'
import { QueryParamsTemplateContext } from './QueryParamsTemplateContext'

export default function TemplateCardList({ input }) {
  const { value, onChange } = input
  const { params } = useContext(QueryParamsTemplateContext)
  const isUserInteraction = useRef(false)
  const [templateList, setTemplateList] = useState(value || [])

  function handleTemplateClick(templateId) {
    isUserInteraction.current = true
    setTemplateList(prevState => {
      if (prevState.includes(templateId)) {
        return prevState.filter(id => id !== templateId)
      }
      return [...prevState, templateId]
    })
  }

  useEffect(() => {
    setTemplateList(value || [])
  }, [value])

  useEffect(() => {
    if (isUserInteraction.current) {
      onChange(templateList)
      isUserInteraction.current = false
    }
  }, [templateList, onChange])

  const { InfiniteItems } = useInfiniteScroller({
    apiHook: useLazyGetTemplatesListQuery,
    options: {
      page: 0,
      size: 16,
      active: true,
      includeBody: true
    },
    params,
    renderItem: template => (
      <TemplateCard
        key={template.id}
        card={template}
        onChange={handleTemplateClick}
        templateList={templateList}
        isChosenDefault={templateList.includes(template?.id)}
      />
    )
  })

  return (
    <>
      <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 16, fontWeight: 500, color: '#76767F' }}>
        Cyberlift templates
      </Typography>
      {InfiniteItems()}
    </>
  )
}
