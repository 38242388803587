import React from 'react'
import { Box, Typography, Divider } from '@mui/material'

import SlackMessagePreview from './SlackPreview'
import SmsWhatsAppPreview from './SmsWhatsAppPreview'

const MessagePreview = ({ type, metadata }) => {
  const renderMessagePreview = () => {
    switch (type) {
      case 'SLACK':
        return <SlackMessagePreview message={metadata?.message} />

      case 'SMS':
        return <SmsWhatsAppPreview metadata={metadata} type={type} />

      case 'WHATSAPP':
        return <SmsWhatsAppPreview metadata={metadata} type={type} />

      default:
        return (
          <Box sx={{ padding: '16px' }}>
            <Typography>{metadata?.message}</Typography>
          </Box>
        )
    }
  }

  return (
    <div className='row template-view' style={{ width: '1150px' }}>
      <div className='template-container'>
        <div className='row row-center template-container-header'>
          <p>Template preview</p>
        </div>
        <div className='row template-preview'>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>{renderMessagePreview()}</Box>
        </div>
      </div>
      <div className='template-details'>
        <p className='template-details-title'>{typeMapping[type]} Message</p>
        <Divider sx={{ mb: '24px' }} />

        <div className='column template-details-group'>
          <p className='template-details-group-title'>Sender</p>
          <p>{metadata?.phoneNumber || metadata?.sender || typeMapping[type]}</p>
        </div>
      </div>
    </div>
  )
}

export default MessagePreview

const typeMapping = {
  SMS: 'SMS',
  WHATSAPP: 'WhatsApp',
  SLACK: 'Slack'
}
