import React, { useState } from 'react'
import { useLazyGetStepSimulationStatisticsQuery } from '../../../../api/simulation/simulation'

import NameEmailColumn from './NameEmailColumn'
import DateTimeColumn from './DateTimeColumn'
import TemplateNameColumn from './TemplateNameColumn'
import ActionButtons from '../../ActionButtons'
import EditIcon from '../../../../common/icons/editIcon'
import { ReactComponent as CancelIcon } from '../../../../common/icons/inActiveIcon.svg'
import SendIcon from '../../../../common/icons/SendIcon'
import StatusComponent from './StatusComponent'

export default function useUsersHistoryTable({ id, stepId, simulationData }) {
  const [getSimulations, { data, error, isLoading }] = useLazyGetStepSimulationStatisticsQuery()
  const [isViewShowing, setIsViewShowing] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [isSentNowShowing, setIsSentNowShowing] = useState(false)
  const [isCancelShowing, setIsCancelShowing] = useState(false)
  const [isEditShowing, setIsEditShowing] = useState(false)

  function handleViewClose() {
    setIsViewShowing(false)
    setSelectedTemplate(null)
  }

  function handleSentNowShow(row) {
    setIsSentNowShowing(true)
    setSelectedRow(row)
  }

  function handleSentNowHide() {
    setIsSentNowShowing(false)
    setSelectedRow(null)
  }
  function handleCancelShow(row) {
    setIsCancelShowing(true)
    setSelectedRow(row)
  }
  function handleCancelHide() {
    setIsCancelShowing(false)
    setSelectedRow(null)
  }
  function handleEditShow(row) {
    setSelectedRow(row)
    setIsEditShowing(true)
  }
  function handleEditHide() {
    setSelectedRow(null)
    setIsEditShowing(false)
  }
  function handleViewOpen(row) {
    if (
      row?.simulationCampaignStepType === 'WHATSAPP' ||
      row?.simulationCampaignStepType === 'SMS' ||
      row?.simulationCampaignStepType === 'SLACK'
    ) {
      setSelectedTemplate({
        type: row?.simulationCampaignStepType,
        metadata: row?.simulationCampaignStepMetadata
      })
    }

    if (row?.templateType === 'CUSTOM' || row?.templateType === 'INTERNAL') {
      setSelectedTemplate({
        templateId: row?.templateId,
        type: row?.templateType
      })
    }
    setIsViewShowing(true)
  }

  const columns = [
    {
      field: 'sendAt',
      headerName: 'Sent at',
      sortable: true,
      minWidth: 110,
      width: 130,
      align: 'left',
      renderComponent: (value, row) => <DateTimeColumn row={row} />
    },

    {
      field: 'userFirstName',
      headerName: 'User',
      sortable: true,
      align: 'left',
      maxWidth: '60%',
      renderComponent: (value, row) => <NameEmailColumn row={row} />
    },
    {
      field: 'templateName',
      headerName: 'Template',
      sortable: true,
      align: 'left',
      maxWidth: '60%',
      renderComponent: (value, row) => <TemplateNameColumn row={row} handleOpenTemplate={handleViewOpen} />
    },
    {
      field: 'simulationCampaignStepStatus',
      headerName: 'Step Status',
      sortable: true,
      align: 'left',
      minWidth: '20%',
      renderComponent: (value, row) => (
        <StatusComponent
          status={row?.simulationCampaignStepStatus}
          reason={row?.simulationCampaignStepStatusChangeReason}
        />
      )
    },
    {
      field: 'actions',
      headerName: '',
      stickyRight: true,
      maxWidth: '46px',
      align: 'left',
      renderComponent: (value, row) => (
        <ActionButtons
          config={[
            {
              disabled: row?.simulationCampaignStepStatus !== 'PLANNED' || simulationData.status === 'ARCHIVED',
              name: 'Send Now',
              icon: <SendIcon color='#76767F' />,
              onClick: () => {
                handleSentNowShow(row)
              }
            },
            {
              disabled: row?.simulationCampaignStepStatus !== 'PLANNED' || simulationData.status === 'ARCHIVED',
              name: 'Edit',
              icon: <EditIcon color={'#76767F'} />,
              onClick: () => {
                handleEditShow(row)
              }
            },
            {
              disabled: row?.simulationCampaignStepStatus !== 'PLANNED' || simulationData.status === 'ARCHIVED',
              name: 'Cancel',
              icon: <CancelIcon stroke='#76767F' />,
              onClick: () => {
                handleCancelShow(row)
              }
            }
          ]}
        />
      )
    }
  ]
  function fetchData({ page, size, sort, params }) {
    getSimulations({ id, stepId, page, sort, size, ...params })
  }

  const statistics = data?.data
  const totalCount = data?.totalCount
  return {
    isViewShowing,
    handleViewClose,
    template: selectedTemplate,
    columns,
    fetchData,
    statistics,
    selectedRow,
    setSelectedRow,
    isSentNowShowing,
    handleSentNowHide,
    handleEditHide,
    isEditShowing,
    isCancelShowing,
    handleCancelHide,
    error,
    totalCount,
    isLoading: isLoading
  }
}
