import React, { useEffect, useState } from 'react'
import userService from 'src/services/user.service'
import { useInView } from './hooks';

const UserAvatar = ({ id, firstName, lastName, style, onClick = () => { } }) => {
  const [img, setImg] = useState(null)
  const [ref, inView] = useInView({ threshold: 0.1 });
  const CACHE_EXPIRATION_TIME = 30 * 60 * 1000;

  const getCachedImage = (id) => {
    const cachedData = localStorage.getItem(`userAvatar_${id}`);
    if (cachedData) {
      const { url, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
        return url;
      }
    }
    return undefined;
  };

  const setCachedImage = (id, url) => {
    const cacheData = {
      url,
      timestamp: Date.now(),
    };
    localStorage.setItem(`userAvatar_${id}`, JSON.stringify(cacheData));
  };

  useEffect(() => {
    if (inView && id) {
      const cachedImageUrl = getCachedImage(id);
      if (cachedImageUrl || cachedImageUrl === null) {
        setImg(cachedImageUrl);
      } else {
        userService.getUsersImage(id).then(resp => {
          setImg(resp.data.url);
          setCachedImage(id, resp.data.url);
        });
      }
    }
  }, [inView, id]);

  return (
    <div
      ref={ref}
      onClick={onClick}
      style={{
        width: '40px',
        height: '40px',
        display: 'flex',
        borderRadius: '20px',
        overflow: 'hidden',
        backgroundColor: style?.background ? style?.background : '#F4F5F9',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px',
        flexShrink: 0,
        ...style
      }}
    >
      {img ? (
        <img width={'100%'} src={img} alt='' />
      ) : (
        <div
          style={{
            fontWeight: '600',
            fontSize: style?.fontSize || '16px',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#5F5F68',
            cursor: 'pointer'
          }}
        >
          {firstName && lastName
            ? firstName?.slice(0, 1) + lastName?.slice(0, 1)
            : !firstName && !lastName
              ? 'N/A'
              : (firstName ? firstName?.slice(0, 1) : '') + (lastName ? lastName?.slice(0, 1) : '')}
        </div>
      )}
    </div>
  )
}

export default UserAvatar
