import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import NewCampaignModal from '../NewCampaign/NewCampaignModal'
import EditIcon from 'src/common/icons/editIcon'

export default function EditSimulationButton({ isHidden }) {
  const { id } = useParams()
  const [isEditShowing, setIsEditShowing] = useState(false)
  function handleClose() {
    setIsEditShowing(false)
  }

  if (isHidden) {
    return null
  }
  return (
    <>
      <Button
        sx={{
          height: '40px',
          borderRadius: '12px',
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          bgcolor: '#fff',
          border: '1px solid #E7E7EE',
          color: '#6123FF',
          '&:hover': {
            border: 'none',
            bgcolor: '#F0E7F9'
          }
        }}
        onClick={() => setIsEditShowing(true)}
        variant={'outlined'}
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
      {isEditShowing && <NewCampaignModal editId={id} open={isEditShowing} handleClose={handleClose} />}
    </>
  )
}
