import { IconButton, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CyberSwitchV2 from 'src/common/components/SwitchV2'
import PlusIcon from 'src/common/icons/plusIcon'

import './style.css'
import useFileUpload from 'react-use-file-upload'
import settingsService from 'src/services/settings.service'
import { SnackBarContext } from 'src/App'
import { humanFileSize } from 'src/heplers'
import Spacer from 'src/common/components/Spacer'
import DeleteIcon from 'src/common/icons/deleteIcon'
import { FileIcon } from './fileIcons'
import { LoadingButton } from '@mui/lab'

const SecurityAssistant = () => {
  const [enabled, setEnabled] = useState(false)
  const [docs, setDocs] = useState(null)
  const [error, setError] = useState(null)
  const [fileUploading, setFileUploading] = useState(false)
  const { files, setFiles, clearAllFiles } = useFileUpload()
  const inputRef = useRef()

  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)

  useEffect(() => {
    Promise.all([settingsService.getAssistantStaus(), settingsService.getAssistantDocuments()])
      .then(values => {
        const [resp, docsResp] = values
        setEnabled(resp.data.enabled)
        setDocs(docsResp.data)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const updateFiles = () => {
    settingsService
      .getAssistantDocuments()
      .then(docsResp => {
        setDocs(docsResp.data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (files.length) {
      if (files[0].size > 104857600) {
        // in binary
        setError('Max file size is 100 MB')
        setErrorMessage('Max file size is 100 MB')
        clearAllFiles()
        return
      }
      const formData = new FormData()
      formData.append('fileUpload', files[0])
      try {
        setFileUploading(true)
        settingsService
          .addAssistantDocument(formData)
          .then(resp => {
            setFileUploading(false)
            clearAllFiles()
            setSuccessMessage('File uploaded successfully.')
            updateFiles()
          })
          .catch(error => {
            clearAllFiles()
            setErrorMessage(error?.response?.data?.description || 'Something went wrong')
            console.error(error)
            setFileUploading(false)
          })
      } catch (error) {
        console.error('Failed to submit files.')
      }
    }
  }, [files])

  const deleteFile = id => {
    settingsService
      .deleteAssistantDocument(id)
      .then(() => {
        setSuccessMessage('File deleted successfully')
        updateFiles()
      })
      .catch(error => {
        setErrorMessage(error?.response?.data?.description || 'Something went wrong')
      })
  }
  const enableAssistant = () => {
    setEnabled(!enabled)
    settingsService
      .setAssistantStaus({ enabled: !enabled })
      .then(() => {
        setSuccessMessage('Updated successfully')
      })
      .catch(err => {
        setErrorMessage('Something went wrong')
        setEnabled(!enabled)
        console.error(err)
      })
  }

  const onAddFileClick = () => {
    inputRef.current.click()
  }

  return (
    <div
      className='column'
      style={{
        backgroundColor: '#F8F8FB',
        height: '100vh',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      <div className='container-800' style={{ minHeight: '100vh' }}>
        <h1
          style={{
            fontSize: 35,
            marginBottom: 8
          }}
        >
          Security Assistant
        </h1>

        <p
          style={{
            lineHeight: '24px',
            marginBottom: '32px'
          }}
        >
          Enable AI-driven insights and support for your employees. With Slack and Microsoft Teams integrations,
          employees can ask the Security Assistant questions based on your organization's provided knowledge. For
          example, they can quickly find answers to questions like:
        </p>

        <div style={{ marginBottom: 56 }} className='row'>
          <p
            style={{
              fontStyle: 'italic',
              marginRight: 40
            }}
          >
            — What are the password requirements? <br /> — How do I report a phishing email? <br /> — What is the
            process for requesting access to a system?
          </p>
          <p
            style={{
              maxWidth: 314
            }}
          ></p>
        </div>

        <div className='box-r-24'>
          <div className='box-r-24-content'>
            <div style={{ marginBottom: 24 }} className='row'>
              <CyberSwitchV2 checked={enabled} onChange={enableAssistant} />
              <p
                style={{
                  marginLeft: 12,
                  textTransform: 'uppercase',
                  fontWeight: 600
                }}
              >
                Enable
              </p>
            </div>

            <p>
              This option activates our AI Security Assistant on Slack, Teams, and the User platform. When deactivated,
              the bot will no longer be active.
            </p>
          </div>
        </div>
        <div className='box-r-24'>
          <div className='box-r-24-content'>
            <svg
              style={{ marginBottom: 20 }}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                opacity='0.2'
                d='M5.75884 11.9539H10.8285C10.9947 11.9539 11.1573 11.9047 11.2956 11.8125L13.3976 10.4112C13.536 10.319 13.6985 10.2698 13.8648 10.2697H20.3466V7.74342C20.3466 7.63283 20.3249 7.52333 20.2825 7.42116C20.2402 7.31899 20.1782 7.22615 20.1 7.14796C20.0218 7.06976 19.929 7.00773 19.8268 6.96541C19.7246 6.92309 19.6151 6.90131 19.5045 6.90132H12.2063C12.0241 6.90131 11.8468 6.84221 11.701 6.73288L8.78171 4.54343C8.63596 4.4341 8.45867 4.375 8.27647 4.375H2.66242C2.55183 4.375 2.44232 4.39678 2.34015 4.43909C2.23798 4.48141 2.14515 4.54344 2.06695 4.62164C1.98876 4.69984 1.92673 4.79267 1.88441 4.89484C1.84209 4.99701 1.82031 5.10652 1.82031 5.21711V20.375L4.97697 12.4833C5.03948 12.327 5.14738 12.1931 5.28674 12.0987C5.4261 12.0044 5.59054 11.954 5.75884 11.9539Z'
                fill='#1B1B26'
              />
              <path
                d='M1.82031 20.375V5.21711C1.82031 4.99377 1.90903 4.77957 2.06696 4.62165C2.22488 4.46372 2.43908 4.375 2.66242 4.375H8.27645C8.45866 4.375 8.63595 4.4341 8.78172 4.54342L11.701 6.73289C11.8468 6.84222 12.0241 6.90132 12.2063 6.90132H19.5045C19.7279 6.90132 19.9421 6.99004 20.1 7.14796C20.2579 7.30589 20.3466 7.52008 20.3466 7.74342V10.2697'
                stroke='#1B1B26'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M1.82031 20.3748L4.97699 12.4831C5.0395 12.3268 5.14739 12.1929 5.28676 12.0985C5.42612 12.0042 5.59056 11.9537 5.75887 11.9537H10.8285C10.9948 11.9537 11.1573 11.9045 11.2956 11.8123L13.3976 10.411C13.536 10.3187 13.6985 10.2695 13.8648 10.2695H22.5467C22.6801 10.2695 22.8116 10.3012 22.9304 10.362C23.0492 10.4228 23.1518 10.511 23.2298 10.6192C23.3079 10.7275 23.359 10.8527 23.3792 10.9846C23.3993 11.1165 23.3878 11.2513 23.3456 11.3779L20.3466 20.3748H1.82031Z'
                stroke='#1B1B26'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <h2
              style={{
                fontWeight: 900,
                fontSize: 20,
                marginBottom: 8
              }}
            >
              Documents
            </h2>
            <p>
              Add up to 10 files from your organization. Securely hosted and processed on Cyberlift's private Google
              Cloud servers.
            </p>
            <div className='column file-elements'>
              {docs?.map(el => (
                <div className='row row-center file-element' key={el.id}>
                  <div style={{ marginRight: 10 }}>
                    <FileIcon type={el.contentType} />
                  </div>
                  {el.name.length > 55 ? (
                    <Tooltip arrow title={el.name}>
                      <p style={{ marginRight: 10, maxWidth: 500 }} className='text-shrink-1'>
                        {el.name}
                      </p>
                    </Tooltip>
                  ) : (
                    <p style={{ marginRight: 10, maxWidth: 500 }}>{el.name}</p>
                  )}
                  <p style={{ opacity: 0.7 }}>({humanFileSize(el.size)})</p>
                  <Spacer />

                  <IconButton disabled={false} title='Delete file' onClick={() => deleteFile(el.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
          <div className='box-r-24-divider'></div>
          <div className='box-r-24-content'>
            <div className='row row-center'>
              <input
                ref={inputRef}
                type='file'
                accept='.pdf, .doc, .docx, .txt'
                style={{ display: 'none' }}
                onChange={e => {
                  setFiles(e, 'a')
                  inputRef.current.value = null
                }}
              />
              <LoadingButton
                loading={fileUploading}
                variant='outlined'
                disabled={fileUploading || docs?.length > 9}
                sx={{
                  borderColor: '#E7E7EE',
                  borderRadius: '12px',
                  color: '#6123FF',
                  textTransform: 'none',
                  mr: '12px'
                }}
                startIcon={<PlusIcon />}
                onClick={onAddFileClick}
              >
                Add document
              </LoadingButton>

              <p
                style={{
                  color: '#76767F',
                  fontWeight: 500
                }}
              >
                pdf, doc, docx, txt (Max size: 100 MB)
              </p>
            </div>

            {error && (
              <p className='error-text' style={{ margin: '10px 0px 0px 0px' }}>
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecurityAssistant
