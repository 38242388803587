import React, { useContext, useEffect, useState } from 'react'
import { createDropdown, Separator, Toolbar, useEditorState } from 'react-simple-wysiwyg'
import { IconButton, MenuItem, Select, Tooltip } from '@mui/material'
import './editor.css'
import ImageDrawer from './ImageDrawer'

import { ReactComponent as ImageIcon } from '../../../../common/icons/image_icon.svg'
import { ReactComponent as StrikethroughSIcon } from '../../../../common/icons/text_strikeThrough_icon.svg'
import { ReactComponent as FormatClearIcon } from '../../../../common/icons/remove_styles_icon.svg'
import { ReactComponent as FormatListBulletedIcon } from '../../../../common/icons/list_bullet_icon.svg'
import { ReactComponent as FormatUnderlinedIcon } from '../../../../common/icons/text_underline_icon.svg'
import { ReactComponent as FormatItalicIcon } from '../../../../common/icons/text_italic_icon.svg'
import { ReactComponent as FormatBoldIcon } from '../../../../common/icons/text_bolder_icon.svg'
import { DialogRefContext } from '../../../../common/components/CustomDialog'

export const BtnVars = createDropdown('Variables', [
  ['First name', 'insertText', '[firstName]'],
  ['Last name', 'insertText', '[lastName]'],
  ['Email', 'insertText', '[email]'],
  ['Company short name', 'insertText', '[companyShortName]'],
  ['Company name', 'insertText', '[companyName]']
])

const defaultFontSize = '16px'

const sizeMapperInEditor = {
  '11px': '11px',
  small: '13px',
  medium: '16px',
  large: '18px',
  'x-large': '24px',
  'xx-large': '32px',
  'xxx-large': '48px'
}

export default function CustomToolbar({ setTemplateChanged }) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [fontSize, setFontSize] = useState(defaultFontSize)
  const { dialogRef } = useContext(DialogRefContext)
  const { $el, $selection } = useEditorState()
  const [fontFamily, setFontFamily] = useState('Arial')

  useEffect(() => {
    setFontSize($selection?.style?.fontSize ? sizeMapperInEditor[$selection?.style?.fontSize] : defaultFontSize)
    setFontFamily($selection?.style?.fontFamily.replaceAll('"', '') || 'Arial')
  }, [$selection])

  const handleInsertImage = (url, width) => {
    const img = `<img src="${url}" width="${width}" alt="image"/>`
    if (document.activeElement !== $el) {
      $el?.focus()
    }
    document.execCommand('insertHTML', false, img)
  }

  const handleCommand = command => {
    document.execCommand(command)
  }

  const handleFontChange = event => {
    const selectedFont = event.target.value
    setFontFamily(selectedFont)

    document.execCommand('fontName', false, selectedFont)
  }

  const handleFontSizeChange = event => {
    const selectedSize = event.target.value
    setFontSize(selectedSize)
    const sizeMapper = {
      '11px': '1',
      '13px': '2',
      '16px': '3',
      '18px': '4',
      '24px': '5',
      '32px': '6',
      '48px': '7'
    }

    const selection = window.getSelection()
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)

      if (range.collapsed) {
        return
      }

      const container = document.createElement('div')
      container.appendChild(range.cloneContents())
      const spansWithFontSize = container.querySelectorAll('span[style*="font-size"]')

      if (spansWithFontSize.length > 0) {
        document.execCommand('styleWithCSS', false, true)
        document.execCommand('removeFormat', false, null)
      }
      setTemplateChanged(true)
      document.execCommand('styleWithCSS', false, true)
      document.execCommand('fontSize', false, sizeMapper[selectedSize])
    }
  }

  return (
    <>
      <Toolbar style={{ border: 'none', background: 'transparent' }} className={'rsw-toolbar'}>
        <Select
          value={fontFamily}
          onChange={handleFontChange}
          sx={{
            background: '#fff',
            height: '36px',
            marginRight: '8px',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '14px'
          }}
        >
          <MenuItem value='Arial'>Arial</MenuItem>
          <MenuItem value='Calibri'>Calibri</MenuItem>
          <MenuItem value='Times New Roman'>Times New Roman</MenuItem>
          <MenuItem value='Courier New'>Courier New</MenuItem>
          <MenuItem value='Verdana'>Verdana</MenuItem>
        </Select>
        <Select
          value={fontSize}
          onChange={handleFontSizeChange}
          sx={{
            background: '#fff',
            height: '36px',
            marginRight: '8px',
            fontFamily: 'Plus Jakarta Sans',
            fontSize: '14px'
          }}
        >
          <MenuItem value='11px'>11px</MenuItem>
          <MenuItem value='13px'>13px</MenuItem>
          <MenuItem value='16px'>16px</MenuItem>
          <MenuItem value='18px'>18px</MenuItem>
          <MenuItem value='24px'>24px</MenuItem>
          <MenuItem value='32px'>32px</MenuItem>
          <MenuItem value='48px'>48px</MenuItem>
        </Select>
        <Tooltip
          arrow
          title={'Bold'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('bold')}>
            <FormatBoldIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Italic'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('italic')}>
            <FormatItalicIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Underline'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('underline')}>
            <FormatUnderlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Bulleted list'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('insertUnorderedList')}>
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={`Strikethrough`}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('strikeThrough')}>
            <StrikethroughSIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          arrow
          title={'Remove format'}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => handleCommand('removeFormat')}>
            <FormatClearIcon />
          </IconButton>
        </Tooltip>
        <Separator />
        <BtnVars />
        <Tooltip
          arrow
          title={`Image`}
          placement={'top'}
          componentsProps={{
            tooltip: {
              sx: {
                color: 'white',
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                backgroundColor: 'black',
                '& .MuiTooltip-arrow': {
                  color: 'black'
                }
              }
            }
          }}
        >
          <IconButton onClick={() => setDrawerOpen(true)}>
            <ImageIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <ImageDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onInsert={handleInsertImage}
        container={dialogRef?.current}
      />
    </>
  )
}
