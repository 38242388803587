import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SIMULATIONS, TRAINING_PACKAGES } from './tags'

const baseQuery = fetchBaseQuery({
  baseUrl: window._env_.REACT_APP_API_HOST,
  prepareHeaders: headers => {
    const token = JSON.parse(localStorage.getItem('user')).accessToken || null
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    headers.set('X-Custom-Header', 'value')
    return headers
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    window.location.href = '/login';
  }

  return result;
};



export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: 30,
  tagTypes: [
    SIMULATIONS.SIMULATION,
    TRAINING_PACKAGES.LIST,
    TRAINING_PACKAGES.TRAINING_PACKAGE,
    TRAINING_PACKAGES.MODULES,
    TRAINING_PACKAGES.USERS,
    TRAINING_PACKAGES.STATS
  ],
  endpoints: () => ({})
})
