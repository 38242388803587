import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { Editor, useEditorState } from 'react-simple-wysiwyg'
import './editor.css'
import AiButton from './AiButton'
import AiDrawer from './AiDrawer'
import { useForm } from 'react-final-form'
import PhishingButton from './PhishingButton'
import { ReactComponent as QRIcon } from '../../../../common/icons/qr_code_icon.svg'
import { ReactComponent as PaperClipIcon } from '../../../../common/icons/paperclip_icon.svg'
import { ReactComponent as LinkIcon } from '../../../../common/icons/link_icon.svg'
import { ReactComponent as ImageIcon } from '../../../../common/icons/image_icon.svg'
import FileAttachment from './FileAttachment'
import PageAttachment from './PageAttachment'

export default function EditorForm({
  input,
  templateData,
  pageAttachment,
  setPageAttachment,
  setTemplateChanged,
  meta,
  generateFileChunk
}) {
  const [htmlValue, setHtmlValue] = useState(input?.value || templateData?.body)
  const [fileName, setFileName] = useState(null)
  const [extension, setExtension] = useState(null)
  const [attachment, setAttachment] = useState(null)
  const [openAiBar, setOpenAiBar] = useState(false)
  const form = useForm()
  const { $el, $selection, htmlMode } = useEditorState()
  useEffect(() => {
    if (input?.value && input.value !== htmlValue) {
      setHtmlValue(input.value)
    }
  }, [input.value])
  function handleSetAttachment({ fileName, extension }) {
    setAttachment(`${fileName}.${extension}`)
    generateFileChunk({ name: fileName, extension })
  }

  function handleRemove() {
    setAttachment(null)
    setExtension(null)
    setFileName(null)
    if (templateData?.body?.includes('data-filename="')) {
      setTemplateChanged(true)
    }
    generateFileChunk({ isRemove: true })
  }

  function handleRemoveAttachment() {
    if (templateData?.loginPage !== null) {
      setTemplateChanged(true)
    }
    setPageAttachment(null)
  }

  const parseEmailFile = body => {
    if (body && body?.includes('data-filename="')) {
      const filename = body?.split('data-filename="')[1]?.split('.end_file_name')[0]

      return {
        fileName: filename.split('.')[0],
        extension: filename.split('.')[1]
      }
    }
    return null
  }

  const handlePhishingQR = () => {
    document.querySelector('.rsw-ce').focus()
    setTimeout(() => {
      document.execCommand('insertHTML', false, '<img width="60px" alt="image" src="/images/qr-in-template.jpg"/>')
    }, 100)
  }

  const handlePhishingFile = () => {
    setTemplateChanged(true)
    setFileName('filename')
    setExtension('pdf')
    generateFileChunk({ name: 'filename', extension: 'pdf' })
  }

  const handlePhishingPage = () => {
    setTemplateChanged(true)
    setPageAttachment('COMMON')
  }

  const handlePhishingLink = () => {
    if (document.activeElement !== $el) {
      $el?.focus()
    }
    const selectedText = window.getSelection()?.toString() || 'Link text'
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink')
      return
    }

    setTimeout(() => {
      const phishingLink = `<a href="[phishingLink]">${selectedText}</a>`
      document.execCommand('insertHTML', false, phishingLink)
    }, 50)
  }

  const handleAiOpen = () => {
    setOpenAiBar(true)
  }
  const handleAiClose = () => {
    setOpenAiBar(false)
  }

  function handleInsertPrompt(value) {
    if (value) {
      setHtmlValue(transformDraft(value?.content).replaceAll(/\n/g, ' <br/> '))
      form.change('subject', value?.subject)
    }
  }

  useEffect(() => {
    if (templateData?.body?.includes('data-filename="')) {
      setExtension(parseEmailFile(templateData?.body).extension)
      setFileName(parseEmailFile(templateData?.body).fileName)
      handleSetAttachment(
        `${parseEmailFile(templateData?.body).fileName}.${parseEmailFile(templateData?.body).extension}`
      )
    }
    if (templateData?.loginPage) {
      setPageAttachment(templateData?.loginPage)
    }
  }, [])

  useEffect(() => {
    input.onChange(htmlValue)
  }, [htmlValue])

  useEffect(() => {
    !!extension && handleSetAttachment({ fileName, extension })
  }, [fileName, extension])

  const config = [
    {
      name: 'Phishing Page',
      icon: <ImageIcon fill={'#76767F'} />,
      onClick: handlePhishingPage
    },
    {
      name: 'Phishing Link',
      icon: <LinkIcon color={'#76767F'} />,
      onClick: handlePhishingLink
    },

    {
      name: 'Phishing File',
      icon: <PaperClipIcon color={'#76767F'} />,
      onClick: handlePhishingFile
    },

    {
      name: 'Phishing QR',
      icon: <QRIcon color={'#76767F'} />,
      onClick: handlePhishingQR
    }
  ]

  return (
    <Box display={'flex'} flexDirection={'column'} position='relative' width='100%'>
      <Editor
        className={'rsw-editor'}
        style={{ background: '#fff', height: '180px', border: 'none' }}
        value={htmlValue}
        onChange={e => setHtmlValue(e.target.value.replaceAll('font-size: x-small;', 'font-size: 11px;'))}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : ''}
      />
      <Box display={'flex'} flexDirection={'column'} rowGap={2}>
        {attachment && (
          <FileAttachment
            fileName={fileName}
            handleRemove={handleRemove}
            setTemplateChanged={setTemplateChanged}
            setFileName={setFileName}
            extension={extension}
            setExtension={setExtension}
          />
        )}
        {pageAttachment && (
          <PageAttachment
            setPageType={setPageAttachment}
            setTemplateChanged={setTemplateChanged}
            pageType={pageAttachment}
            handleRemove={handleRemoveAttachment}
          />
        )}

        {!htmlMode && (
          <Box p={2} display={'flex'} columnGap={2}>
            <PhishingButton config={config} />
            <AiButton handleClick={handleAiOpen} />
          </Box>
        )}
      </Box>

      <AiDrawer open={openAiBar} onClose={handleAiClose} onInsert={handleInsertPrompt} />
      {meta?.touched && meta?.error && (
        <p
          style={{
            color: '#b40020',
            marginLeft: '16px',
            marginTop: '8px'
          }}
        >
          {meta?.error}
        </p>
      )}
    </Box>
  )
}

const shortCodes = ['firstName', 'lastName', 'email', 'companyShortName', 'companyName']
const transformDraft = text => {
  const regex = /\[(.*?)\]/g

  return text.replaceAll(regex, function (match, p1) {
    if (shortCodes.includes(p1)) {
      return match
    } else {
      return '<a href="[phishingLink]" target="_blank">' + p1 + '</a>'
    }
  })
}
