import React from 'react'

const SendIcon = ({ disabled, color = '#6123FF' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M19.7099 3.3641L2.24172 8.29104C2.09476 8.33249 1.96396 8.4178 1.86679 8.53558C1.76962 8.65336 1.71071 8.79799 1.69794 8.95015C1.68517 9.1023 1.71915 9.25473 1.79532 9.38706C1.8715 9.51939 1.98626 9.62531 2.12425 9.69068L10.1503 13.4925C10.3068 13.5666 10.4329 13.6927 10.507 13.8492L14.3088 21.8752C14.3742 22.0132 14.4801 22.128 14.6124 22.2041C14.7447 22.2803 14.8972 22.3143 15.0493 22.3015C15.2015 22.2888 15.3461 22.2299 15.4639 22.1327C15.5817 22.0355 15.667 21.9047 15.7084 21.7578L20.6354 4.28953C20.6716 4.16122 20.6729 4.02558 20.6392 3.89658C20.6056 3.76758 20.5381 3.64988 20.4439 3.55561C20.3496 3.46134 20.2319 3.3939 20.1029 3.36024C19.9739 3.32658 19.8383 3.32791 19.7099 3.3641Z'
      fill={disabled ? '#aaa' : color}
    />
    <path
      d='M19.7099 3.3641L2.24172 8.29104C2.09476 8.33249 1.96396 8.4178 1.86679 8.53558C1.76962 8.65336 1.71071 8.79799 1.69794 8.95015C1.68517 9.1023 1.71915 9.25473 1.79532 9.38706C1.8715 9.51939 1.98626 9.62531 2.12425 9.69068L10.1503 13.4925C10.3068 13.5666 10.4329 13.6927 10.507 13.8492L14.3088 21.8752C14.3742 22.0132 14.4801 22.128 14.6124 22.2041C14.7447 22.2803 14.8972 22.3143 15.0493 22.3015C15.2015 22.2888 15.3461 22.2299 15.4639 22.1327C15.5817 22.0355 15.667 21.9047 15.7084 21.7578L20.6354 4.28953C20.6716 4.16122 20.6729 4.02558 20.6392 3.89658C20.6056 3.76758 20.5381 3.64988 20.4439 3.55561C20.3496 3.46134 20.2319 3.3939 20.1029 3.36024C19.9739 3.32658 19.8383 3.32791 19.7099 3.3641Z'
      stroke={disabled ? '#aaa' : color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3906 13.602L14.6333 9.35938'
      stroke={disabled ? '#aaa' : color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SendIcon
