import { Button, Skeleton, Tooltip } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import Spacer from 'src/common/components/Spacer'
import ArchiveIcon from 'src/common/icons/archiveIcon'
import EditIcon from 'src/common/icons/editIcon'
import PhishingSimTab from './phishingSimTab'
import TrainingsTab from './trainingsTab'
import ReportedEmailsTab from './reportedEmailsTab'
import groupsService from 'src/services/groups.service'
import ArchiveDialog from 'src/common/components/ArchiveDialog'
import statsService from 'src/services/stats.service'
import MembersList from './membersList'
import UnArchiveIcon from 'src/common/icons/unArchiveIcon'
import { ConnectorIcon } from 'src/pages/settings/components/integrations'
import { ManagerById } from 'src/common/components/ManagerById'
import { UserViewContext } from 'src/App'

const ViewGroup = ({ item, onEdit, onArchive }) => {
  const [activeTab, setActiveTab] = useState(1)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)
  const [loading, setLoading] = useState(true)
  const [currentGroup, setCurrentGroup] = useState(null)
  const [starsStat, setStarsStat] = useState(null)
  const [totalUserCount, setTotalUserCount] = useState(null)
  const [showUsersDialog, setShowUsersDialog] = useState(false)

  const [, setUserViewId] = useContext(UserViewContext)

  useEffect(() => {
    Promise.all([
      groupsService.getGroup(item?.id),
      groupsService.getGroupsStars(item?.id),
      statsService.getCurrentCompanyStats()
    ]).then(results => {
      setLoading(false)
      const [groupResp, starsResp, companyStatsResp] = results
      setCurrentGroup(groupResp.data)
      setStarsStat(starsResp.data)
      setTotalUserCount(companyStatsResp.data)
    })
  }, [])

  const handleTabChange = name => {
    setActiveTab(tabs.find(el => el.text == name).id)
  }

  const handleArchive = () => {
    onArchive(item)
    setOpenArchiveDialog(false)
  }

  const showGroupMembers = () => {
    setShowUsersDialog(true)
  }

  const tabs = [
    { id: 1, text: 'Phishing simulations' },
    { id: 2, text: 'Trainings' },
    { id: 3, text: 'Reported emails' }
  ]
  return (
    <>
      <div style={{ width: '1200px' }} className='row'>
        <div style={{ width: '400px', height: '100vh', padding: '32px' }} className='column'>
          <div className='row row-center' style={{ marginBottom: '36px' }}>
            <Tooltip title={item?.name}>
              <p
                className='text-shrink-1'
                style={{
                  fontSize: '24px',
                  fontFamily: 'Satoshi',
                  marginRight: '20px',
                  maxWidth: '280px'
                }}
              >
                {item?.name}
              </p>
            </Tooltip>

            {item?.provider && <ConnectorIcon type={item?.provider} size={'20'} />}
          </div>
          <div
            style={{
              padding: '16px 24px',
              borderRadius: '10px',
              border: '1px solid #E7E7EE',
              marginBottom: '16px'
            }}
          >
            <p
              style={{
                fontWeight: 600,
                color: '#76767F',
                marginBottom: '12px'
              }}
            >
              Employees
            </p>
            {loading ? (
              <Skeleton sx={{ mb: '20px' }} height={38} width={280} />
            ) : (
              <div
                style={{
                  paddingBottom: '24px',
                  marginBottom: '16px',
                  borderBottom: '1px solid #E7E7EE'
                }}
                className='row row-center'
              >
                <p
                  style={{
                    fontFamily: 'Satoshi',
                    fontSize: '42px',
                    marginRight: '16px'
                  }}
                >
                  {currentGroup?.usersCount}
                </p>

                <div className='progress-hatched row'>
                  <div
                    className='progress-hatched-inner row row-center'
                    style={{
                      minWidth: '84px',
                      width: (Math.round((currentGroup?.usersCount / totalUserCount?.totalCount) * 100) || 0) + '%'
                    }}
                  >
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        opacity='0.2'
                        d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
                        fill='#1B1B26'
                      />
                      <path
                        d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
                        stroke='#1B1B26'
                        strokeMiterlimit='10'
                      />
                      <path
                        d='M14.5703 5.43271C15.2408 5.24379 15.9441 5.20075 16.6326 5.3065C17.3212 5.41224 17.9791 5.66431 18.562 6.04572C19.1449 6.42714 19.6393 6.92904 20.012 7.51763C20.3846 8.10622 20.6268 8.76783 20.7221 9.45789C20.8175 10.148 20.764 10.8504 20.565 11.5181C20.366 12.1857 20.0263 12.8029 19.5687 13.3281C19.1111 13.8534 18.5463 14.2745 17.9123 14.5631C17.2782 14.8517 16.5897 15.001 15.8931 15.0011'
                        stroke='#1B1B26'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054'
                        stroke='#1B1B26'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M15.8945 15C17.2185 14.999 18.5232 15.3171 19.6982 15.9273C20.8732 16.5375 21.8838 17.4218 22.6446 18.5054'
                        stroke='#1B1B26'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                    >
                      {Math.round((currentGroup?.usersCount / totalUserCount?.totalCount) * 100) || 0} %
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className='row row-center' style={{ borderBottom: '1px solid #E7E7EE', paddingBottom: 16, marginBottom: 16 }}>
              <p style={{ color: '#76767F', fontWeight: 600 }}>Manager:</p>
              <Spacer />
              <p className='user-details-text' onClick={() => setUserViewId(currentGroup?.managerUserId)} style={{ cursor: 'pointer' }}><ManagerById managerUserId={currentGroup?.managerUserId} /></p>
            </div>

            <div
              className='row row-center'
              style={{
                fontWeight: 600,
                marginBottom: '32px'
              }}
            >
              {currentGroup?.rules && (
                <>
                  <div
                    className='row row-center'
                    style={{
                      justifyContent: 'center',
                      background: '#DBE2FF',
                      fontSize: '14px',
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                      marginRight: '10px'
                    }}
                  >
                    <p>{currentGroup?.rules.length}</p>
                  </div>
                  <p
                    style={{
                      marginRight: '16px'
                    }}
                  >
                    Dynamic rules
                  </p>
                </>
              )}

              {currentGroup?.type == 'STATIC' && (
                <p
                  style={{
                    color: '#76767F'
                  }}
                >
                  Static group
                </p>
              )}
            </div>

            <Button
              disabled={currentGroup?.usersCount == 0}
              variant='outlined'
              onClick={showGroupMembers}
              fullWidth
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#6123FF',
                textTransform: 'none',
                flex: '1',
                mr: '12px'
              }}
            >
              View group members
            </Button>
          </div>

          <div
            style={{
              padding: '16px 24px',
              borderRadius: '10px',
              border: '1px solid #E7E7EE'
            }}
          >
            <p
              style={{
                fontWeight: 600,
                color: '#76767F',
                marginBottom: '12px'
              }}
            >
              Average earned stars
            </p>

            {loading ? (
              <Skeleton sx={{ mb: '20px' }} height={38} width={280} />
            ) : (
              <div style={{}} className='row row-center'>
                <p
                  style={{
                    fontFamily: 'Satoshi',
                    fontSize: '42px',
                    marginRight: '16px'
                  }}
                >
                  {(starsStat?.averageStars || 0) / 2 < 100 ? (starsStat?.averageStars || 0) / 2 : 100}%
                </p>

                <div
                  style={{
                    background: '#E7E7EE',
                    height: '8px',
                    flex: '1',
                    borderRadius: '8px'
                  }}
                  className='row row-center'
                >
                  <div
                    style={{
                      width:
                        (starsStat?.averageStars || 0) / 2 > 90
                          ? '100%'
                          : 10 + (starsStat?.averageStars || 0) / 2 + '%',
                      background: '#FBD360',
                      height: '8px',
                      borderRadius: '8px',
                      position: 'relative'
                    }}
                  >
                    <div
                      style={{
                        background: 'url("/images/yellow-star.png")',
                        backgroundSize: '100%',
                        width: '42px',
                        height: '40px',
                        fontWeight: '600',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '-18px',
                        right: '-20px'
                      }}
                    >
                      {starsStat?.averageStars || 0}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Spacer />
          <div style={{ marginTop: '55px' }} className='row'>
            <Button
              variant='outlined'
              disabled={item?.status == 'ARCHIVED'}
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#6123FF',
                textTransform: 'none',
                flex: '1',
                mr: '12px'
              }}
              startIcon={<EditIcon disabled={item?.status == 'ARCHIVED'} />}
              onClick={() => onEdit(item.id)}
            >
              Edit Group
            </Button>
            <Button
              onClick={() => setOpenArchiveDialog(true)}
              disabled={item?.source == 'EXTERNAL'}
              variant='outlined'
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                '& .MuiButton-startIcon': { mr: '0' }
              }}
              // startIcon={<ArchiveIcon disabled={item?.status == 'ARCHIVED'} />}
              startIcon={
                item?.status == 'ARCHIVED' ? (
                  <UnArchiveIcon disabled={item?.source == 'EXTERNAL'} />
                ) : (
                  <ArchiveIcon disabled={item?.source == 'EXTERNAL'} />
                )
              }
            ></Button>
          </div>
        </div>
        <div
          style={{
            width: '800px',
            background: '#F8F8FB',
            borderLeft: '1px solid #E7E7EE',
            padding: '32px'
          }}
          className='column'
        >
          <div
            style={{
              marginBottom: '36px'
            }}
            className='row tabs'
          >
            {tabs.map(el => (
              <div
                key={el.id}
                onClick={() => handleTabChange(el.text)}
                className={`tab ${activeTab == el.id && 'tab-active'}`}
              >
                <p>{el.text}</p>
              </div>
            ))}
          </div>

          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              {activeTab == 1 && <PhishingSimTab groupId={item?.id} />}
              {activeTab == 2 && <TrainingsTab groupId={item?.id} />}
              {activeTab == 3 && <ReportedEmailsTab groupId={item?.id} />}
            </>
          )}
        </div>
      </div>
      <ArchiveDialog
        open={openArchiveDialog}
        unarchive={item?.status == 'ARCHIVED'}
        str={item?.name || 'Users group'}
        description={
          item?.status == 'ARCHIVED'
            ? 'This group will be unarchived.'
            : 'This group will be archived, but users will continue to receive and participate in training. This action can be reversed'
        }
        onAction={handleArchive}
        onClose={() => setOpenArchiveDialog(false)}
      />

      <MembersList
        open={showUsersDialog}
        onClose={() => setShowUsersDialog(false)}
        ids={currentGroup?.userIds}
        title={'Group Members'}
        description={'Employees who added to this group'}
      />
    </>
  )
}

export default ViewGroup
