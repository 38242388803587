import React, { useContext, useState } from 'react'
import { Box, Button } from '@mui/material'
import { Form } from 'react-final-form'
import TemplateNameField from './TemplateNameField'
import EditorInput from './EditorInput'
import EditorEmailRow from './EditorEmailRow'
import CustomEditor from './CustomEditor'

import HtmlUploadDialog from './HtmlUploadDialog'
import CodeIcon from '@mui/icons-material/Code'

import { ReactComponent as SendIcon } from '../../../../common/icons/airplane_icon.svg'
import { useEditorState } from 'react-simple-wysiwyg'
import {
  useAddCustomTemplateMutation,
  useSendToMeDraftMutation,
  useUpdateCustomTemplateMutation
} from '../../../../api/simulation/simulation'
import customToastWithAlert from '../../../../common/components/CustomToastWithAlert'
import { PageNumberContext } from '../PageNumberContext'
import CustomToolbar from './CustomToolbar'
import { pdfAttach, xlsAttach } from '../../../templates/components/constants'
import './editor.css'

export default function TemplateEditorForm({
  setSelectedTemplateId,
  isSystemTemplate,
  selectedTemplateId,
  templateData,
  onSubmit
}) {
  const [fileChunk, setFileChunk] = useState(null)
  const [pageAttachment, setPageAttachment] = useState(null)
  const [htmlDialogOpen, setHtmlDialogOpen] = useState(false)
  const { setPage } = useContext(PageNumberContext)
  const [sendToMe, { isSuccess, isError }] = useSendToMeDraftMutation()
  const [addTemplate, { isLoading: isCreating }] = useAddCustomTemplateMutation()
  const [updateTemplate, { isLoading: isUpdating }] = useUpdateCustomTemplateMutation()
  const [templateChanged, setTemplateChanged] = useState(false)
  const { update, htmlMode } = useEditorState()
  function handleOpenHtmlDialog() {
    setHtmlDialogOpen(true)
  }

  const generateEmailBody = value => {
    return `<html lang="en">
    <head>
    <style>p {margin: 0}</style>
    </head>
    <body>
    <table cellSpacing="0" cellPadding="0" border="0" >${fileChunk ? '<tr style="margin-bottom: 16px; display: table"><td>' + fileChunk + '</td></tr>' : ''}<tr><td class='tmplt-body'>${value}</td></tr></table></body></html>`
  }

  function removeMsoNormalClasses(html) {
    const regex = /\s*MsoNormal\w*\s*/g
    return html.replace(/class=["'][^"']*MsoNormal[^"']*["']/g, match => {
      return match.replace(regex, ' ').trim()
    })
  }

  function convertPtToPx(html, dpi = 96) {
    const ptToPxRatio = dpi / 72

    return html.replace(/style=["']([^"']*)["']/g, (match, styleContent) => {
      const updatedStyle = styleContent.replace(/(\d+(\.\d+)?)pt/g, (ptMatch, ptValue) => {
        const pxValue = parseFloat(ptValue) * ptToPxRatio
        return `${pxValue}px`
      })
      return `style="${updatedStyle}"`
    })
  }

  function handleSave(values) {
    const requestBody = {
      name: values.name,
      sender: values.sender,
      emailPrefix: values.emailPrefix,
      emailDomain: values.emailDomain,
      subject: values.subject,
      loginPage: pageAttachment,
      body: isSystemTemplate
        ? values.body
        : generateEmailBody(convertPtToPx(removeMsoNormalClasses(values.body)))
            .replaceAll('https://admin.cyberlift.io/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
            .replaceAll('https://admin.cyberlift.ai/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
            .replaceAll('/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
            .replaceAll('width="60px" alt=', 'alt=')
    }
    if (selectedTemplateId && !isSystemTemplate) {
      updateTemplate({ id: selectedTemplateId, ...requestBody })
        .unwrap()
        .then(resp => {
          onSubmit(resp)
        })
    } else {
      addTemplate(requestBody)
        .unwrap()
        .then(resp => {
          onSubmit(resp)
        })
    }
  }

  function handleReset() {
    setSelectedTemplateId(null)
    setTimeout(() => setPage('EMAIL_TEMPLATE'), 0)
  }

  async function handleSendToMe(values) {
    const requestBody = {
      name: values.name,
      sender: values.sender,
      emailPrefix: values.emailPrefix,
      emailDomain: values.emailDomain,
      subject: values.subject,
      loginPage: pageAttachment,
      body: isSystemTemplate
        ? values.body
        : generateEmailBody(convertPtToPx(removeMsoNormalClasses(values.body)))
            .replaceAll('https://admin.cyberlift.io/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
            .replaceAll('https://admin.cyberlift.ai/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
            .replaceAll('/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
            .replaceAll('width="60px" alt=', 'alt=')
    }

    try {
      await sendToMe(requestBody)
      if (isSuccess) {
        customToastWithAlert({ message: 'Email sent successfully!' })
      }
      if (isError) {
        customToastWithAlert({ message: 'Failed to send email.', type: 'error' })
      }
    } catch (error) {
      customToastWithAlert({ message: 'An unexpected error occurred.', type: 'error' })
    }
  }
  function handleOnTextModeChange() {
    update({
      htmlMode: false
    })
  }

  function generateFileChunk({ name, extension, isRemove = false }) {
    if (isRemove) {
      setFileChunk(null)
      return
    }
    setFileChunk(extension === 'pdf' ? pdfAttach(name) : xlsAttach(name))
  }

  function initialData(htmlTemplate) {
    if (htmlTemplate)
      return {
        body: parseEmailBody(htmlTemplate)
      }
    if (templateData && isSystemTemplate) {
      return templateData
    }
    if (templateData && !isSystemTemplate) {
      return {
        ...templateData,
        body: parseEmailBody(templateData?.body),
        loginPage: templateData.loginPage
      }
    }
    return {}
  }

  return (
    <>
      <Form
        key={templateData}
        onSubmit={handleSave}
        initialValues={initialData()}
        keepDirtyOnReinitialize
        render={({ handleSubmit, invalid, dirty, values, form }) => (
          <form onSubmit={handleSubmit}>
            <TemplateNameField />
            <Box display={'flex'} justifyContent={'space-between'} pt={3} pb={2}>
              <Box display={'flex'} columnGap={2}>
                <Button
                  variant={'text'}
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: !htmlMode ? 'rgba(27, 27, 38, 1)' : 'rgba(97, 35, 255, 1)',
                    borderBottom: !htmlMode ? '2px solid rgba(27, 27, 38, 1)' : 'none',
                    padding: '4px 16px',
                    fontWeight: 600,
                    fontSize: '16px',
                    borderRadius: 0,
                    textTransform: 'none'
                  }}
                  onClick={handleOnTextModeChange}
                >
                  Edit template
                </Button>
                <Button
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: 'rgba(97, 35, 255, 1)',
                    padding: '4px 16px',
                    fontWeight: 600,
                    fontSize: '16px',
                    textTransform: 'none',
                    borderRadius: 0,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px'
                  }}
                  variant={'text'}
                  onClick={handleOpenHtmlDialog}
                  startIcon={<CodeIcon />}
                >
                  Import HTML
                </Button>
              </Box>
              <Button
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: 'rgba(97, 35, 255, 1)',
                  padding: '4px 16px',
                  fontWeight: 600,
                  fontSize: '16px',
                  textTransform: 'none'
                }}
                disabled={invalid}
                variant={'text'}
                startIcon={
                  <SendIcon
                    style={{
                      stroke: invalid ? 'rgba(118, 118, 127, 1)' : 'rgba(97, 35, 255, 1)',
                      fill: invalid ? 'rgb(0 0 0 / 6%)' : 'none'
                    }}
                  />
                }
                onClick={() => handleSendToMe(values)}
              >
                Send me a preview
              </Button>
            </Box>
            <Box
              pt={2}
              pl={3}
              pr={3}
              mb={1}
              display={'flex'}
              flexDirection={'column'}
              rowGap={2}
              bgcolor={'#fff'}
              borderRadius={'14px'}
            >
              <EditorInput
                name={'sender'}
                label={'Sender Name'}
                validate={values => (!values ? 'Sender name is required' : undefined)}
              />
              <EditorEmailRow />
              <EditorInput
                name={'subject'}
                label={'Subject'}
                validate={values => (!values ? 'Subject is required' : undefined)}
              />
              <CustomEditor
                isSystemTemplate={isSystemTemplate}
                fileChunk={fileChunk}
                pageAttachment={pageAttachment}
                setPageAttachment={setPageAttachment}
                setTemplateChanged={setTemplateChanged}
                templateData={templateData}
                name={'body'}
                generateFileChunk={generateFileChunk}
              />
            </Box>
            {!htmlMode && <CustomToolbar setTemplateChanged={setTemplateChanged} />}
            <Box display={'flex'} justifyContent={'flex-end'} columnGap={2} pt={1}>
              <Button
                size={'large'}
                onClick={() => handleReset()}
                variant={'contained'}
                sx={{
                  color: '#fff',
                  backgroundColor: '#6123FF',
                  textTransform: 'none',
                  height: '42px',
                  width: 200,
                  borderRadius: '14px',
                  '&:hover': { backgroundColor: '#7843ff' }
                }}
              >
                Back
              </Button>

              <Button
                size={'large'}
                type={'submit'}
                disabled={invalid || isCreating || isUpdating || !(dirty || templateChanged)}
                variant={'contained'}
                sx={{
                  color: '#fff',
                  backgroundColor: '#6123FF',
                  textTransform: 'none',
                  width: 200,
                  height: '42px',
                  borderRadius: '14px',
                  '&:hover': { backgroundColor: '#7843ff' }
                }}
              >
                Save
              </Button>
            </Box>
            <HtmlUploadDialog form={form} open={htmlDialogOpen} onClose={() => setHtmlDialogOpen(false)} />
          </form>
        )}
      />
    </>
  )
}
export const parseEmailBody = body => {
  if (!body) return ''

  const bodyMatch = body.match(/<td[^>]*class=(?:'|")tmplt-body(?:'|")[^>]*>([\s\S]*?)<\/td>/i)

  if (bodyMatch && bodyMatch[1]) {
    return bodyMatch[1].replaceAll(
      'alt="image" src="cid:[qrCodeImage]"',
      'width="60px" alt="image" src="https://admin.cyberlift.io/images/qr-in-template.jpg"'
    )
  }

  return body
}
