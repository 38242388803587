import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export const timelineOptions = [
  {
    name: 'Custom',
    value: 'CUSTOM_DATE'
  },
  {
    name: 'Custom Days Count',
    value: 'CUSTOM_DAYS_COUNT'
  },
  {
    name: 'Immediately',
    value: 'IMMEDIATELY'
  },
  {
    name: 'Throughout the Day',
    value: 'THROUGHOUT_DAY'
  },
  {
    name: 'Throughout the Week',
    value: 'THROUGHOUT_WEEK'
  },
  {
    name: 'Throughout the Month',
    value: 'THROUGHOUT_MONTH'
  },
  {
    name: 'Throughout 3 Months',
    value: 'THROUGHOUT_3_MONTHS'
  },
  {
    name: 'Throughout 6 Months',
    value: 'THROUGHOUT_6_MONTHS'
  },
  {
    name: 'Throughout the Year',
    value: 'THROUGHOUT_YEAR'
  }
]

export const campaignTriggerOptions = [
  {
    name: 'Previous Step Ended',
    value: 'PREVIOUS_STEP_ENDED'
  },
  {
    name: 'Email Opened',
    value: 'EMAIL_OPENED'
  },
  {
    name: 'Email Not Opened in 5 days',
    value: 'EMAIL_NOT_OPENED_IN_5_DAYS'
  },
  {
    name: 'Phished',
    value: 'PHISHED'
  },
  {
    name: 'Not Phished in 5 days',
    value: 'NOT_PHISHED_IN_5_DAYS'
  },
  {
    name: 'Link Clicked',
    value: 'LINK_CLICKED'
  },
  {
    name: 'Email Replied',
    value: 'EMAIL_REPLIED'
  },
  {
    name: 'Attach Opened',
    value: 'ATTACH_OPENED'
  },
  {
    name: 'QR Code Scanned',
    value: 'QR_CODE_SCANNED'
  },
  {
    name: 'Credentials Provided',
    value: 'CREDENTIALS_PROVIDED'
  }
]

export const phoneNumbersOptions = [
  {
    name: 'UK (+44) 7411912978',
    value: '+447411912978'
  },
  {
    name: 'US (+1) 2299999776',
    value: '+12299999776'
  },
  {
    name: 'US (+1) 2566266422',
    value: '+12566266422'
  },
  {
    name: 'US (+1) 8173832767',
    value: '+18173832767'
  }
]
export const messagesType = ['SMS', 'WHATSAPP']
export const convertToUserTimezone = serverTime => {
  const userTimezone = dayjs.tz.guess()
  const parsedTime = dayjs.utc(serverTime, 'YYYY-MM-DD HH:mm')
  return parsedTime.tz(userTimezone).format('HH:mm')
}
export const convertDateToUserTimezone = serverTime => {
  const userTimezone = dayjs.tz.guess()
  const parsedDate = dayjs.utc(serverTime)
  return parsedDate.tz(userTimezone).format('DD MMMM')
}
export const convertToUserTimezoneValue = serverTime => {
  if (!serverTime) return null

  const userTimezone = dayjs.tz.guess()
  let timeObject

  if (dayjs.isDayjs(serverTime)) {
    timeObject = serverTime.isUTC() ? serverTime : dayjs.utc(serverTime)
  } else if (serverTime instanceof Date) {
    timeObject = dayjs.utc(serverTime)
  } else {
    timeObject = dayjs.utc(serverTime, 'YYYY-MM-DD HH:mm')
  }

  return timeObject.tz(userTimezone)
}
dayjs.extend(utc)
dayjs.extend(timezone)
