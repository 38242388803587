import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  TextField,
  Tabs,
  Tab
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import UploadIcon from '@mui/icons-material/Upload'
import { useForm } from 'react-final-form'
import { parseEmailBody } from './TemplateEditorForm'

export default function HtmlUploadDialog({ open, onClose }) {
  const form = useForm()

  const [tabValue, setTabValue] = useState(0)

  const [htmlContent, setHtmlContent] = useState('')

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'text/html': ['.html', '.htm']
    },
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0]
      const reader = new FileReader()
      reader.onload = () => {
        setHtmlContent(parseEmailBody(reader.result))
      }
      reader.readAsText(file)
    }
  })

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleSubmit = () => {
    form.change('body', htmlContent)
    setHtmlContent('')
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '24px'
        }
      }}
    >
      <DialogTitle sx={{ fontFamily: 'Satoshi', fontSize: 24 }}>Import HTML</DialogTitle>
      <DialogContent>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label='Upload File' sx={{ fontFamily: 'Plus Jakarta Sans' }} />
          <Tab label='Paste HTML' sx={{ fontFamily: 'Plus Jakarta Sans' }} />
        </Tabs>

        <Box sx={{ mt: 2 }}>
          {tabValue === 0 ? (
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed #DBDBE6',
                borderRadius: '12px',
                p: 4,
                textAlign: 'center',
                cursor: 'pointer',
                bgcolor: isDragActive ? 'rgba(97, 35, 255, 0.05)' : 'transparent',
                '&:hover': { bgcolor: 'rgba(97, 35, 255, 0.05)' }
              }}
            >
              <input {...getInputProps()} />
              <UploadIcon />
              <Typography sx={{ mt: 2, fontFamily: 'Plus Jakarta Sans' }}>
                {isDragActive ? 'Drop the HTML file here' : 'Drag and drop your HTML file here, or click to browse'}
              </Typography>
              {htmlContent && (
                <Typography sx={{ mt: 2, color: 'green', fontFamily: 'Plus Jakarta Sans' }}>
                  File loaded successfully
                </Typography>
              )}
            </Box>
          ) : (
            <TextField
              multiline
              rows={10}
              fullWidth
              value={htmlContent}
              onChange={e => setHtmlContent(e.target.value)}
              placeholder='Paste your HTML code here'
              sx={{
                fontFamily: 'monospace',
                '& .MuiInputBase-root': { fontFamily: 'Plus Jakarta Sans' }
              }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          paddingRight: '24px',
          paddingBottom: '16px'
        }}
      >
        <Button onClick={onClose} sx={{ fontFamily: 'Plus Jakarta Sans', textTransform: 'none', color: '#6123FF' }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          disabled={!htmlContent}
          sx={{
            fontFamily: 'Plus Jakarta Sans',
            textTransform: 'none',
            bgcolor: '#6123FF'
          }}
        >
          Import HTML
        </Button>
      </DialogActions>
    </Dialog>
  )
}
