/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import { Box, IconButton, Tooltip, TextField, Typography } from '@mui/material'
import GenericSelect from '../../../common/components/GenericSelect'
import EmojiPicker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import Divider from '@mui/material/Divider'
import { ReactComponent as Slack } from '../../../common/icons/slackColorful.svg'

import {
  formatActions,
  getCurrentListFormat,
  parseSlackMessage,
  SlackFrame,
  SlackHeader,
  variablesOptions
} from './slackUtils'
import { Letter } from 'react-letter'
import EditIcon from '../../../common/icons/editIcon'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Spacer from '../../../common/components/Spacer'

export default function SlackMessageField({ input }) {
  const [fieldValue, setFieldValue] = useState(input.value || '')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const textFieldRef = useRef(null)
  const [viewMode, setViewMode] = useState('editor')

  const toggleViewMode = () => {
    setViewMode(viewMode === 'editor' ? 'preview' : 'editor')
  }
  useEffect(() => {
    if (fieldValue !== input.value) {
      input.onChange(fieldValue)
    }
  }, [fieldValue, input])

  const updateTextWithCursor = (
    beforeCursor,
    betweenCursor,
    afterCursor,
    selectionStart,
    selectionEnd,
    useSelectedText = true
  ) => {
    const textField = textFieldRef.current
    if (!textField) return

    const scrollTop = textField.scrollTop

    const selectedText = useSelectedText ? fieldValue.substring(selectionStart, selectionEnd) : ''

    const newText =
      fieldValue.substring(0, selectionStart) +
      beforeCursor +
      (useSelectedText ? selectedText : '') +
      betweenCursor +
      afterCursor +
      fieldValue.substring(selectionEnd)

    setFieldValue(newText)

    const newPosition =
      selectionStart + beforeCursor.length + (useSelectedText ? selectedText.length : 0) + betweenCursor.length

    setTimeout(() => {
      textField.focus()
      textField.scrollTop = scrollTop
      textField.setSelectionRange(newPosition, newPosition)
    }, 0)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      const textField = textFieldRef.current
      if (!textField) return

      const scrollTop = textField.scrollTop

      const { selectionStart } = textField
      const currentText = fieldValue

      const listFormat = getCurrentListFormat(currentText, selectionStart)
      const lastNewLine = currentText.lastIndexOf('\n', selectionStart - 1)
      const lineStart = lastNewLine === -1 ? 0 : lastNewLine + 1
      const currentLine = currentText.substring(lineStart, selectionStart).trim()

      if (!currentLine && listFormat) {
        const textBefore = currentText.substring(0, lineStart)
        const textAfter = currentText.substring(selectionStart)
        setFieldValue(textBefore + '\n' + textAfter)

        setTimeout(() => {
          const newPosition = lineStart + 1
          textField.focus()

          textField.scrollTop = scrollTop
          textField.setSelectionRange(newPosition, newPosition)
        }, 0)
      } else {
        const insertion = listFormat ? '\n' + listFormat.marker : '\n'
        updateTextWithCursor(insertion, '', '', selectionStart, selectionStart)
      }
    }
  }
  const handleFormat = action => {
    const textField = textFieldRef.current
    if (!textField) return

    const { selectionStart, selectionEnd } = textField
    const selectedText = fieldValue.substring(selectionStart, selectionEnd)

    switch (action.type) {
      case 'inline':
        updateTextWithCursor(action.marker, '', action.marker, selectionStart, selectionEnd)
        break

      case 'block': {
        const lines = selectedText ? selectedText.split('\n') : ['']

        const isAlreadyQuoted = lines.every(line => line.trim().startsWith('> '))

        if (isAlreadyQuoted) {
          const cleanText = lines.map(line => line.trim().replace(/^>\s*/, '')).join('\n')

          updateTextWithCursor('', cleanText, '', selectionStart, selectionEnd, false)
        } else {
          const formattedText = lines.map(line => `> ${line.trim()}`).join('\n')

          const shouldAddNewLine = selectionStart > 0 && !fieldValue.substring(0, selectionStart).endsWith('\n')

          updateTextWithCursor(shouldAddNewLine ? '\n' : '', formattedText, '', selectionStart, selectionEnd, false)
        }
        break
      }

      case 'link':
        const linkText = selectedText || 'link text'
        updateTextWithCursor('<https://', `|${linkText}>`, '', selectionStart, selectionEnd)
        setTimeout(() => {
          const newPosition = selectionStart + '<https://'.length
          textField.setSelectionRange(newPosition, newPosition)
        }, 0)
        break

      case 'list': {
        const isNumbered = action.marker === '1. '

        if (!selectedText) {
          const marker = isNumbered ? '1. ' : action.marker
          updateTextWithCursor(marker, '', '', selectionStart, selectionEnd)
          return
        }

        const lines = selectedText.split('\n')

        const isAlreadyList = lines.every(line => {
          const trimmedLine = line.trim()
          return trimmedLine.startsWith('• ') || /^\d+\.\s/.test(trimmedLine)
        })

        const currentType = isNumbered ? 'number' : 'bullet'
        const existingType = lines[0].trim().startsWith('• ')
          ? 'bullet'
          : /^\d+\.\s/.test(lines[0].trim())
            ? 'number'
            : null

        if (isAlreadyList && currentType === existingType) {
          const cleanText = lines.map(line => line.trim().replace(/^(?:•|\d+\.)\s+/, '')).join('\n')

          updateTextWithCursor('', cleanText, '', selectionStart, selectionEnd, false)
          return
        }

        let number = 1
        const formattedText = lines
          .map(line => {
            const cleanLine = line.trim().replace(/^(?:•|\d+\.)\s+/, '')
            const marker = isNumbered ? `${number++}. ` : action.marker
            return marker + cleanLine
          })
          .join('\n')

        updateTextWithCursor('', formattedText, '', selectionStart, selectionEnd, false)
        break
      }

      case 'multiline':
        const hasSelection = selectionStart !== selectionEnd
        const content = hasSelection ? selectedText : ''
        updateTextWithCursor(action.marker, content, action.closeMarker, selectionStart, selectionEnd)
        break

      default:
        break
    }
  }

  const handleVariableChange = e => {
    const variable = e.target.value
    if (!variable || !textFieldRef.current) return

    const textField = textFieldRef.current
    const { selectionStart, selectionEnd } = textField

    const textBefore = fieldValue.slice(0, selectionStart)
    const textAfter = fieldValue.slice(selectionEnd)

    const addSpaceBefore = textBefore && !textBefore.endsWith(' ') ? ' ' : ''
    const addSpaceAfter = textAfter && !textAfter.startsWith(' ') ? ' ' : ''

    updateTextWithCursor(addSpaceBefore + variable + addSpaceAfter, '', '', selectionStart, selectionEnd)
  }

  const handleEmojiSelect = emoji => {
    if (!textFieldRef.current) return

    const textField = textFieldRef.current
    const { selectionStart, selectionEnd } = textField

    updateTextWithCursor(emoji.native, '', '', selectionStart, selectionEnd)

    setShowEmojiPicker(false)
  }

  const renderEditor = () => (
    <>
      <GenericSelect value='' options={variablesOptions} onChange={handleVariableChange} label='Variables' />

      <Box
        sx={{
          border: '1px solid #ddd',
          borderRadius: '4px',
          backgroundColor: 'white',
          overflow: 'hidden'
        }}
      >
        <Box display='flex' gap={0.5} p={1} borderBottom='1px solid #ddd' bgcolor='#f8f9f9' alignItems='center'>
          {formatActions.map((action, index) =>
            action.type === 'divider' ? (
              <Divider orientation='vertical' flexItem key={index} sx={{ mx: 0.5 }} />
            ) : (
              <Tooltip key={action.title} title={action.title}>
                <IconButton size='small' onClick={() => handleFormat(action)}>
                  <action.icon />
                </IconButton>
              </Tooltip>
            )
          )}
          <Spacer />
          <Tooltip title='Show preview'>
            <IconButton size='small' onClick={toggleViewMode}>
              <VisibilityIcon sx={{ color: '#6123FF' }} />
            </IconButton>
          </Tooltip>
        </Box>

        <TextField
          inputRef={textFieldRef}
          multiline
          fullWidth
          minRows={6}
          maxRows={12}
          value={fieldValue}
          onChange={e => setFieldValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder='Type your message here...'
          sx={{
            '& .MuiOutlinedInput-root': {
              border: 'none',
              borderRadius: 0,
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '& .MuiInputBase-input': {
              p: 1.5
            }
          }}
        />
        <Box
          display='flex'
          bgcolor={'#F5F5F5'}
          justifyContent='space-between'
          alignItems='center'
          border='1px solid #ddd'
        >
          <Box position='relative'>
            <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
              <InsertEmoticonIcon />
            </IconButton>
            {showEmojiPicker && (
              <Box position='absolute' bottom='100%' left={0} zIndex={10}>
                <EmojiPicker data={data} onEmojiSelect={handleEmojiSelect} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )

  const renderPreview = () => (
    <SlackFrame>
      <SlackHeader>
        <Slack style={{ width: 20, height: 20 }} />
        <Typography variant='body2'>Preview</Typography>
        <Box sx={{ marginLeft: 'auto' }}>
          <Tooltip title='Show editor'>
            <IconButton
              size='small'
              variant='text'
              onClick={toggleViewMode}
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '14px',
                fontWeight: 'semibold',
                textTransform: 'capitalize',
                color: '#fff'
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </SlackHeader>
      <Box sx={{ padding: '16px', backgroundColor: '#ffffff' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Box
            sx={{
              width: 36,
              height: 36,
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Slack style={{ width: 36, height: 36 }} />
          </Box>
        </Box>
        <Box sx={{ pl: '46px' }}>
          <div className='slack-message'>
            <Letter html={parseSlackMessage(fieldValue)} />
          </div>
        </Box>
      </Box>
    </SlackFrame>
  )

  return (
    <Box display='flex' flexDirection='column' gap={1} className='slack-editor'>
      {viewMode === 'editor' ? renderEditor() : renderPreview()}
    </Box>
  )
}
