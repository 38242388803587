import React, { useContext } from 'react'
import { Field, useFormState } from 'react-final-form'
import { Box, Typography } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { PageNumberContext } from '../PageNumberContext'
import ChooseTypeCard from '../ChooseTypeCard'
import { convertToUserTimezoneValue } from '../utils'
const validateSendAt = value => {
  const now = new Date()
  if (!value) return 'Send date is required'
  if (new Date(value) < now) return 'Cannot schedule in the past'
  return undefined
}
export default function FirstPageForm() {
  const { values } = useFormState()

  const { setPage } = useContext(PageNumberContext)
  const isSmsTemplate = values?.metadata?.phoneNumber

  const type = values?.type

  const cardMapper = {
    PHONE: {
      type: 'PHONE',
      title: 'Phone Call',
      description: 'Automate phone call setups',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    },
    SLACK: {
      type: 'SLACK',
      title: 'Slack',
      description: 'Post messages to Slack channels',
      isAI: false,
      number: values?.metadata?.message && 1,
      onChange: () => {
        setPage('SLACK_TEMPLATE')
      }
    },
    SMS: {
      type: 'SMS',
      title: 'SMS',
      description: 'Send text messages automatically',
      isAI: false,
      number: isSmsTemplate && 1,
      onChange: () => {
        setPage('SMS_TEMPLATE')
      },
      isDisabled: false
    },
    MANUAL_EMAIL: {
      type: 'MANUAL_EMAIL',
      title: 'Custom Email',
      description: 'Select a template from the database or create your own email from scratch to suit your needs',
      isAI: false,
      onChange: () => {
        setPage('EMAIL_TEMPLATE')
      },
      isDisabled: false
    },
    AUTOMATED_EMAIL: {
      type: 'AUTOMATED_EMAIL',
      title: 'AI-Generated Email',
      description:
        'Cyberlift will automatically generate a unique and personalized template for each employee using AI',
      isAI: true,
      onChange: () => {
        setPage('EMAIL_TEMPLATE')
      },
      isDisabled: false
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={3}>
      <ChooseTypeCard {...cardMapper[type]} />
      <Typography color={'#76767F'} fontWeight={500} fontSize={16} fontFamily={'Plus Jakarta Sans'}>
        Send Template At
      </Typography>
      <Box display={'flex'} columnGap={2}>
        <Box width={350}>
          <Field
            name={'sendAt'}
            validate={validateSendAt}
            render={({ input, meta }) => (
              <>
                <DateTimePicker
                  label='Send At'
                  disablePast={true}
                  value={input?.value ? convertToUserTimezoneValue(input.value) : null}
                  onChange={newValue => {
                    input.onChange(newValue)
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: meta.error && meta.touched,
                      helperText: meta.touched && meta.error,
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: 'Plus Jakarta Sans',
                          borderRadius: '10px'
                        },
                        backgroundColor: '#fff'
                      }
                    }
                  }}
                />
              </>
            )}
          />
        </Box>
      </Box>
    </Box>
  )
}
