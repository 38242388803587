import { Box, Drawer, TextField, Typography } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../../common/icons/search.svg'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DataTableContainer from '../../../../common/components/DataTable/DataTableContainer'
import useUsersHistoryTable from './useUsersHistoryTable'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'
import { useParams } from 'react-router-dom'
import { debounce } from '../../utils'
import { CloseDrawer } from '../../../../common/icons/closeDrawer'
import CustomTemplatePreview from '../../../manual-simulation/components/templatePreview'
import TemplateView from '../../../../views/TemplateView'
import MessagePreview from './MessagePreview'
import SimpleDialog from '../../../../common/components/Dialog'
import { useSimulationCancelMutation, useSimulationExecuteMutation } from '../../../../api/simulation/simulation'
import customToastWithAlert from '../../../../common/components/CustomToastWithAlert'
import EditUserSimulation from './EditUserSimulation'
import { PageNumberContext } from '../PageNumberContext'

export default function UsersHistoryTable({ simulationData }) {
  const [filters, setFilters] = useState({ searchText: '' })
  const [searchText, setSearchText] = useState('')
  const { id, stepId } = useParams()
  const [page, setPage] = useState(1)
  const [onSent, { isSuccess, isError, error }] = useSimulationExecuteMutation()
  const [onCancel, { isSuccess: isCancelSuccess, isError: isCancelError, error: cancelError }] =
    useSimulationCancelMutation()
  const {
    statistics,
    isSentNowShowing,
    handleSentNowHide,
    handleEditHide,
    isEditShowing,
    isCancelShowing,
    handleCancelHide,
    columns,
    isViewShowing,
    handleViewClose,
    selectedRow,
    template,
    fetchData,
    totalCount,
    isLoading
  } = useUsersHistoryTable({ id, stepId, simulationData })

  const debouncedSetParams = useMemo(
    () =>
      debounce(searchText => {
        setFilters(prevParams => ({ ...prevParams, searchText }))
      }, 500),
    [setFilters]
  )
  const handleSearchChange = useCallback(
    e => {
      const value = e.target.value
      setSearchText(value)
      debouncedSetParams(value)
    },
    [debouncedSetParams]
  )
  const prevStatesRef = useRef({
    isSuccess: false,
    isError: false,
    isCancelSuccess: false,
    isCancelError: false
  })
  useEffect(() => {
    if (!prevStatesRef.current.isSuccess && isSuccess) {
      customToastWithAlert({ message: 'Step sent successfully', type: 'success' })
    } else if (!prevStatesRef.current.isError && isError) {
      customToastWithAlert({ message: `${error?.data?.description}`, type: 'error' })
    } else if (!prevStatesRef.current.isCancelSuccess && isCancelSuccess) {
      customToastWithAlert({ message: 'Step cancelled successfully', type: 'success' })
    } else if (!prevStatesRef.current.isCancelError && isCancelError) {
      customToastWithAlert({ message: `${cancelError?.data?.description}`, type: 'error' })
    }

    prevStatesRef.current = { isSuccess, isError, isCancelSuccess, isCancelError }
  }, [isError, isSuccess, error, isCancelSuccess, isCancelError, cancelError])

  if (isLoading) {
    return (
      <Box display={'flex'} height={'100vh'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    )
  }
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      rowGap={2}
      height={'calc(100vh-350px)'}
      p={'32px'}
      bgcolor={'#fff'}
      sx={{ borderRadius: '20px', border: '1px solid #1C1C270D' }}
    >
      <Box display={'flex'} columnGap={'32px'} justifyContent={'space-between'}>
        <Typography fontSize={'24px'} fontWeight={500} fontFamily={'Satoshi'}>
          Users history
        </Typography>
        <Box width='280px'>
          <TextField
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                border: '1px solid #E7E7EE',
                background: '#fff',
                width: '100%'
              },
              '& input::placeholder': {
                fontSize: '14px'
              }
            }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            onChange={handleSearchChange}
            value={searchText}
            size='small'
            placeholder='Search by User Name or Template'
          />
        </Box>
      </Box>
      <DataTableContainer
        onRequestData={fetchData}
        emptyText={'No users found'}
        filter={filters}
        config={{
          columns,
          stickyPagination: false,
          stickyHeader: true,
          maxHeight: 'calc(100vh - 340px)'
        }}
        colorBody={'#fff'}
        initialSort={{ orderBy: 'sendAt', order: 'asc' }}
        data={statistics}
        totalCount={totalCount}
      />
      {isEditShowing && (
        <PageNumberContext.Provider value={{ page, setPage }}>
          <EditUserSimulation open={isEditShowing} handleClose={handleEditHide} rowData={selectedRow} stepId={stepId} />
        </PageNumberContext.Provider>
      )}
      {isSentNowShowing && (
        <SimpleDialog
          open={open}
          onClose={handleSentNowHide}
          str={`Send simulation`}
          description={`Are you sure you want to send attack to  ${selectedRow?.userFirstName || 'unknown'} ${selectedRow?.userLastName || 'unknown'} ?`}
          actionTitle={'Send'}
          onAction={() => {
            onSent({ id, stepId, userId: selectedRow?.userId })
            handleSentNowHide()
          }}
        />
      )}
      {isCancelShowing && (
        <SimpleDialog
          open={open}
          onClose={handleCancelHide}
          str={`Cancel step`}
          description={`Are you sure you want to cancel step for  ${selectedRow?.userFirstName || 'unknown'} ${selectedRow?.userLastName || 'unknown'} ?`}
          actionTitle={'Submit'}
          onAction={() => {
            onCancel({ id, stepId, userId: selectedRow?.userId })
            handleCancelHide()
          }}
        />
      )}
      {isViewShowing && (
        <Drawer anchor='right' open={isViewShowing} onClose={handleViewClose}>
          <div
            style={{
              position: 'fixed',
              top: '24px',
              right: '1182px',
              cursor: 'pointer'
            }}
            onClick={handleViewClose}
          >
            <CloseDrawer />
          </div>

          {template?.type === 'CUSTOM' && <CustomTemplatePreview id={template.templateId} />}
          {template?.type === 'INTERNAL' && <TemplateView id={template.templateId} />}
          {template?.type !== 'INTERNAL' && template?.type !== 'CUSTOM' && (
            <MessagePreview type={template.type} metadata={template.metadata} />
          )}
        </Drawer>
      )}
    </Box>
  )
}
