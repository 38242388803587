import React, { useContext, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'

import CustomizedDialogs from '../../../common/components/CustomDialog'
import FirstPageForm from './FirstPageForm'
import { PageNumberContext } from './PageNumberContext'
import SecondPageForm from './SecondPageForm'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useAddStepSimulationMutation, useUpdateStepSimulationMutation } from '../../../api/simulation/simulation'
import SecondPageForOtherStepsForm from './SecondPageForOtherStepsForm'
import SmsWhatsappForm from './SmsWhatsappForm'
import ChooseTemplate from './TemplatesForm/ChooseTemplate'
import NewTemplateModal from './TemplatesForm/NewTemplateModal'
import { QueryParamsTemplateContext } from './TemplatesForm/QueryParamsTemplateContext'
import customToastWithAlert from '../../../common/components/CustomToastWithAlert'
import ExitConfirmationDialog from '../../../common/components/ExitConfirmationDialog'

export default function StepFormModal({ handleClose, open, step, rowData = null }) {
  const { id } = useParams()
  const isEdit = !!rowData
  const { page, setPage } = useContext(PageNumberContext)
  const [handleSubmit, { isError, isSuccess, isLoading: isCreating }] = useAddStepSimulationMutation()
  const [handleUpdate, { isError: isUpdateError, isLoading: isUpdating, isSuccess: isUpdateSuccess }] =
    useUpdateStepSimulationMutation()

  const [showExitConfirmation, setShowExitConfirmation] = useState(false)
  const [params, setParams] = useState({
    searchText: '',
    services: [],
    types: [],
    myTemplates: false,
    update: false,
    deletedId: null
  })
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [isSystemTemplate, setIsSystemTemplate] = useState(false)
  function paramsReset() {
    setParams({
      searchText: '',
      services: [],
      types: [],
      myTemplates: false,
      update: false,
      deletedId: null
    })
  }

  const onSubmit = ({ values, form }) => {
    if (isEdit) {
      handleUpdate({
        simulationId: id,
        sequenceNumber: step,
        ...values
      })
    } else {
      handleSubmit({
        id,
        sequenceNumber: step,
        ...values
      })
    }

    setParams({
      searchText: '',
      services: [],
      types: [],
      myTemplates: false
    })
    form.reset()
  }

  const handleCloseClick = () => {
    setShowExitConfirmation(true)
  }

  const handleCancelClose = () => {
    setShowExitConfirmation(false)
  }

  const handleConfirmClose = (values, form) => {
    setShowExitConfirmation(false)
    handleClose(values)
    setPage(1)
    setParams({
      searchText: '',
      services: [],
      types: [],
      myTemplates: false
    })
    form.reset()
  }
  useEffect(() => {
    isEdit && setPage(2)
  }, [isEdit])

  useEffect(() => {
    if (isUpdateSuccess || isSuccess) {
      setPage(1)
      customToastWithAlert({
        type: 'success',
        message: isUpdateSuccess ? 'Step updated successfully' : 'Step added successfully'
      })
      handleClose()
    }
  }, [isSuccess, isUpdateSuccess])

  useEffect(() => {
    if (isError || isUpdateError) {
      setPage(1)
      customToastWithAlert({
        type: 'error',
        message: isError ? 'Failed to create campaign' : 'Failed to update campaign'
      })
      handleClose()
    }
  }, [isError, isUpdateError])
  return (
    <QueryParamsTemplateContext.Provider
      value={{ params, setParams, selectedTemplateId, setSelectedTemplateId, isSystemTemplate, setIsSystemTemplate }}
    >
      <Form
        onSubmit={(values, form) => onSubmit({ values, form })}
        initialValues={isEdit ? rowData : {}}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values, form }) => (
          <>
            <CustomizedDialogs
              isLoading={isCreating || isUpdating}
              headerColor={page === 'EMAIL_TEMPLATE' && '#F3F3F7'}
              denseContent={page === 'EMAIL_TEMPLATE'}
              actionDescription={
                page === 'EMAIL_TEMPLATE' && (
                  <Box display={'flex'} alignItems={'center'} columnGap={'8px'}>
                    <Typography fontWeight={500} fontSize={'24px'} fontFamily={'Satoshi'}>
                      {(values?.internalTemplateIds?.length || 0) + (values?.customTemplateIds?.length || 0)}
                    </Typography>
                    <Typography fontWeight={400} fontSize={'16px'} fontFamily={'Plus Jakarta Sans'}>
                      Templates Selected
                    </Typography>
                  </Box>
                )
              }
              actions={[
                {
                  variant: 'contained',
                  handler: () => (isNaN(page) ? setPage(2) : setPage(page - 1)),
                  text: 'Back',
                  isHidden: page === 1 || page === 'NEW_EMAIL_TEMPLATE' || page === 'EMAIL_TEMPLATE'
                },
                {
                  variant: 'contained',
                  handler: () => {
                    paramsReset()
                    setPage(2)
                  },
                  text: 'Back',
                  isHidden: page !== 'EMAIL_TEMPLATE'
                },
                {
                  disabled:
                    (step === 1 && !values.startDate) ||
                    !values.timeline ||
                    (step > 1 && !values.timeline && !values?.triggerAction) ||
                    isCreating ||
                    isUpdating ||
                    (values.type === 'MANUAL_EMAIL' &&
                      !values.internalTemplateIds?.length &&
                      !values.customTemplateIds?.length) ||
                    ((values.type === 'SMS' || values.type === 'WHATSAPP') && !values.metadata?.phoneNumber) ||
                    (values.type === 'SLACK' && !values.metadata?.message),
                  variant: 'contained',
                  handler: handleSubmit,
                  text: 'Save',
                  isHidden:
                    page === 1 ||
                    page === 'SMS_TEMPLATE' ||
                    page === 'SLACK_TEMPLATE' ||
                    page === 'EMAIL_TEMPLATE' ||
                    page === 'NEW_EMAIL_TEMPLATE'
                },

                {
                  disabled: !values?.metadata?.phoneNumber || !values?.metadata?.message,
                  variant: 'contained',
                  handler: () => setPage(2),
                  text: 'Save',
                  isHidden: page !== 'SMS_TEMPLATE'
                },
                {
                  disabled: !values?.metadata?.message,
                  variant: 'contained',
                  handler: () => setPage(2),
                  text: 'Save',
                  isHidden: page !== 'SLACK_TEMPLATE'
                },
                {
                  disabled: !values?.internalTemplateIds?.length && !values?.customTemplateIds?.length,
                  variant: 'contained',
                  handler: () => setPage(2),
                  text: 'Continue',
                  isHidden: page !== 'EMAIL_TEMPLATE'
                }
              ]}
              open={open}
              handleClose={() => handleCloseClick()}
              modalWidth={page === 'EMAIL_TEMPLATE' ? '95vw' : '880px'}
              title={pageTitleMapper[page]}
            >
              {page === 1 && <Field name='type' component={FirstPageForm} />}
              {page === 2 && step === 1 && <SecondPageForm />}
              {page === 2 && step > 1 && <SecondPageForOtherStepsForm />}
              {page === 'SMS_TEMPLATE' && <SmsWhatsappForm />}
              {page === 'SLACK_TEMPLATE' && <SmsWhatsappForm isSlack={true} />}
              {page === 'EMAIL_TEMPLATE' && <ChooseTemplate />}
              {page === 'NEW_EMAIL_TEMPLATE' && <NewTemplateModal />}
            </CustomizedDialogs>
            {showExitConfirmation && (
              <ExitConfirmationDialog
                open={showExitConfirmation}
                onCancel={handleCancelClose}
                onConfirm={handleConfirmClose}
                values={values}
                form={form}
              />
            )}
          </>
        )}
      />
    </QueryParamsTemplateContext.Provider>
  )
}

const pageTitleMapper = {
  1: 'Add steps for automatic simulation setup',
  2: 'When to start this step',
  EMAIL_TEMPLATE: 'Choose Template',
  SMS_TEMPLATE: 'Create Template',
  SLACK_TEMPLATE: 'Create Template',
  NEW_EMAIL_TEMPLATE: 'Create New Template'
}
